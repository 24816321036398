import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportInspectedProductsByRangeType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportInspectedProductsByRangeType;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportInspectedProductsByRange: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = Object.keys(data.details).length > 0;

  const showValue = (value?: number) => {
    return <td className="numbers-column">{value || 0}</td>;
  };
  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={21}>{reportName}</th>
            </tr>
            <tr>
              <th rowSpan={2}>Organ</th>
              <th colSpan={4}>
                Kontrola prawidłowości oznakowania i informacji{' '}
              </th>
              <th colSpan={4}>Kontrola dwu/kdwu</th>
              <th colSpan={4}>Kontrola dokumentacji technicznej </th>
              <th colSpan={4}>Kontrola instrukcji</th>
              <th colSpan={4}>lzo</th>
            </tr>
            <tr>
              <th rowSpan={1}>O</th>
              <th rowSpan={1}>N</th>
              <th rowSpan={1}>CPR</th>
              <th rowSpan={1}>NCPR</th>
              <th rowSpan={1}>O</th>
              <th rowSpan={1}>N</th>
              <th rowSpan={1}>CPR</th>
              <th rowSpan={1}>NCPR</th>
              <th rowSpan={1}>O</th>
              <th rowSpan={1}>N</th>
              <th rowSpan={1}>CPR</th>
              <th rowSpan={1}>NCPR</th>
              <th rowSpan={1}>O</th>
              <th rowSpan={1}>N</th>
              <th rowSpan={1}>CPR</th>
              <th rowSpan={1}>NCPR</th>
              <th rowSpan={1}>O</th>
              <th rowSpan={1}>N</th>
              <th rowSpan={1}>CPR</th>
              <th rowSpan={1}>NCPR</th>
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    {showValue(data.details[key].checkMarking.o)}
                    {showValue(data.details[key].checkMarking.n)}
                    {showValue(data.details[key].checkMarking.cpr)}
                    {showValue(data.details[key].checkMarking?.ncpr)}
                    {showValue(data.details[key].checkDeclaration.o)}
                    {showValue(data.details[key].checkDeclaration.n)}
                    {showValue(data.details[key].checkDeclaration.cpr)}
                    {showValue(data.details[key].checkDeclaration.ncpr)}
                    {showValue(data.details[key].checkDocumentation?.o)}
                    {showValue(data.details[key].checkDocumentation.n)}
                    {showValue(data.details[key].checkDocumentation.cpr)}
                    {showValue(data.details[key].checkDocumentation.ncpr)}
                    {showValue(data.details[key].checkManual.o)}
                    {showValue(data.details[key].checkManual.n)}
                    {showValue(data.details[key].checkManual.cpr)}
                    {showValue(data.details[key].checkManual.ncpr)}
                    {showValue(
                      data.details[key].checkVolatileOrganicCompounds.o,
                    )}
                    {showValue(
                      data.details[key].checkVolatileOrganicCompounds.n,
                    )}
                    {showValue(
                      data.details[key].checkVolatileOrganicCompounds.cpr,
                    )}
                    {showValue(
                      data.details[key].checkVolatileOrganicCompounds.ncpr,
                    )}
                  </tr>
                ))}
              <tr>
                <td>Suma:</td>
                {showValue(data.sum?.checkMarking.o)}
                {showValue(data.sum?.checkMarking.n)}
                {showValue(data.sum?.checkMarking.cpr)}
                {showValue(data.sum?.checkMarking.ncpr)}
                {showValue(data.sum?.checkDeclaration.o)}
                {showValue(data.sum?.checkDeclaration.n)}
                {showValue(data.sum?.checkDeclaration.cpr)}
                {showValue(data.sum?.checkDeclaration.ncpr)}
                {showValue(data.sum?.checkDocumentation.o)}
                {showValue(data.sum?.checkDocumentation.n)}
                {showValue(data.sum?.checkDocumentation.cpr)}
                {showValue(data.sum?.checkDocumentation.ncpr)}
                {showValue(data.sum?.checkManual.o)}
                {showValue(data.sum?.checkManual.n)}
                {showValue(data.sum?.checkManual.cpr)}
                {showValue(data.sum?.checkManual.ncpr)}
                {showValue(data.sum?.checkVolatileOrganicCompounds.o)}
                {showValue(data.sum?.checkVolatileOrganicCompounds.n)}
                {showValue(data.sum?.checkVolatileOrganicCompounds.cpr)}
                {showValue(data.sum?.checkVolatileOrganicCompounds.ncpr)}
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};
