import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import _ from 'lodash';

import {
  GetInspectionDto,
  CreateUpdateInspectionDto,
  CreateUpdateInspectionDtoFromJSON,
  ValidationStatus,
  GetInspectorDto,
  CreateUpdateInspectorDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as InspectionContext from '../../../common/snrwbCore/contexts/InspectionContext';
import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import ValidationAlert from '../../../app/components/ValidationAlert';
import ItemPresentation from '../../../app/components/ItemPresentation';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { Inspectors } from '../Inspectors/Inspectors';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import Cautions from '../Cautions/Cautions';

const InspectionSigningNoEdit: React.FC<{
  editAuthorized: boolean;
  inspection: GetInspectionDto;
  inspectors: AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>;
  onApproveRollback: () => void;
}> = props => {
  const presentation = InspectionContext.signingPresent(props.inspection);

  const variant = props.inspection.approved ? 'success' : 'danger';
  const heading = props.inspection.approved
    ? 'Protokół kontroli jest już podpisany.'
    : 'Protokół kontroli nie jest podpisany.';

  return (
    <>
      <Alert variant={variant}>
        <Alert.Heading>{heading}</Alert.Heading>
        <hr />
        {props.editAuthorized && (
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-success"
              roleAnyOf={[Role.InspectionsRevoke]}
              onOK={props.onApproveRollback}
              confirmation="Czy na pewno cofnąć podpisanie protokołu?"
            >
              Cofnij
            </ConfirmationButton>
          </div>
        )}
      </Alert>
      <FormRow controlId="controllers" label="Kontrolerzy">
        <Inspectors inspectors={props.inspectors} readonly={true} />
      </FormRow>
      <ItemPresentation item={presentation} className="mt-2" />
    </>
  );
};

export const InspectionSigning: React.FC<{
  editAuthorized: boolean;
  inspection: GetInspectionDto;
  inspectors: AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>;
  onChange: (id: string, inspection: CreateUpdateInspectionDto) => void;
  onProtocol: () => void;
  readyToSign: () => Promise<ValidationStatus>;
  sign: () => void;
  revertSign: () => void;
}> = props => {
  const [inspection, setInspection] = useState(
    CreateUpdateInspectionDtoFromJSON(props.inspection),
  );
  const [presentedInspection, setPresentedInspection] = useState(inspection);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  useEffect(() => {
    setInspection(CreateUpdateInspectionDtoFromJSON(props.inspection));
  }, [props.inspection]);
  useEffect(() => {
    setPresentedInspection(inspection);
  }, [inspection]);

  const propertyChange = (obj: Partial<CreateUpdateInspectionDto>) => {
    setPresentedInspection({ ...presentedInspection, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateInspectionDto) => {
    if (_.isEqual(inspection, obj)) {
      return;
    }
    props.onChange(props.inspection.id, obj);
  };
  const inspectionChange = (obj: Partial<CreateUpdateInspectionDto>) => {
    const dto = { ...inspection, ...obj };
    setInspection(dto);
    saveIfChanged(dto);
  };
  const presentedToInspection = () => {
    setInspection(presentedInspection);
    saveIfChanged(presentedInspection);
  };

  const validate = async () => {
    const result = await props.readyToSign();
    if (result.state === 'ok') {
      return true;
    }

    setShowSaveErrors(true);
    setSaveErrors(result.errors);
    return false;
  };

  if (props.inspection.approved || !props.editAuthorized) {
    return (
      <InspectionSigningNoEdit
        editAuthorized={props.editAuthorized}
        inspection={props.inspection}
        inspectors={props.inspectors}
        onApproveRollback={props.revertSign}
      />
    );
  }

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="controllers" label="Kontrolerzy">
          <Inspectors
            inspectors={props.inspectors}
            readonly={props.inspection.approved}
          />
        </FormRow>
        <FormRow controlId="witnesses" label="W obecności">
          <DynamicTextarea
            value={presentedInspection.witnesses || ''}
            onChange={e => propertyChange({ witnesses: e.target.value })}
            onBlur={presentedToInspection}
          />
        </FormRow>
        <FormRow controlId="notes" label="Uwagi kontrolowanego">
          <DynamicTextarea
            value={presentedInspection.notes || ''}
            onChange={e => propertyChange({ notes: e.target.value })}
            onBlur={presentedToInspection}
          />
        </FormRow>
        <FormRow controlId="endDate" label="Data zakończenia">
          <DatePicker
            value={presentedInspection.endDate}
            onChange={date => inspectionChange({ endDate: date })}
          />
        </FormRow>
        <FormRow controlId="signatureDate" label="Data podpisania protokołu">
          <DatePicker
            value={presentedInspection.signatureDate}
            onChange={date => inspectionChange({ signatureDate: date })}
          />
        </FormRow>
        <FormRow controlId="placeOfSignature" label="Miejsce podpisania">
          <Form.Control
            type="text"
            value={presentedInspection.placeOfSignature || ''}
            onChange={e => propertyChange({ placeOfSignature: e.target.value })}
            onBlur={presentedToInspection}
          />
        </FormRow>
        <FormRow controlId="refusalReason" label="Powód odmowy podpisania">
          <Form.Control
            type="text"
            value={presentedInspection.refusalReason || ''}
            onChange={e => propertyChange({ refusalReason: e.target.value })}
            onBlur={presentedToInspection}
          />
        </FormRow>
        <FormRow controlId="repairMethod" label="Omówienie poprawek">
          <DynamicTextarea
            value={presentedInspection.repairMethod || ''}
            onChange={e => propertyChange({ repairMethod: e.target.value })}
            onBlur={presentedToInspection}
          />
        </FormRow>
        <Cautions
          editAuthorized={props.editAuthorized}
          value={props.inspection}
          onChange={value => inspectionChange(value)}
        />
      </Form>
      <ValidationAlert
        show={showSaveErrors}
        errors={saveErrors}
        modal={true}
        onHide={() => {
          setShowSaveErrors(false);
          setPresentedInspection(inspection);
        }}
        className="mt-3"
      />
      <div className="d-flex justify-content-end mt-3">
        <Button
          className="me-1"
          variant="outline-secondary"
          onClick={props.onProtocol}
        >
          Pobierz protokół
        </Button>
        <ConfirmationButton
          variant="outline-primary"
          confirmation="Czy na pewno zakończyć obsługę tej kontroli?"
          onValidation={validate}
          onOK={props.sign}
        >
          Podpisz
        </ConfirmationButton>
      </div>
    </>
  );
};
