import { Buffer } from 'buffer';

import * as mime from 'mime-types';
import { saveAs } from 'file-saver';

import { GetAttachmentDto } from '../autogenerated/snrwbApiClient/models/GetAttachmentDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  AttachmentFileContent,
  CreateUpdateAttachmentDto,
  CreateUpdateAttachmentDtoEnObjectTypeEnum,
  CreateUpdateAttachmentDtoFromJSON,
} from '../autogenerated/snrwbApiClient';
import {
  validateAgainst,
  ValidationResultOK,
} from '../validation/validateAgainst';
import { AttachmentSchema } from '../validation/schemas';
import { AssociateDocumentsWithFilter } from '../../../snrwb/components/AssociatedDocuments/AssociateDocumentsTypes';
import { SnrwbCoreContextType } from '..';
import { NotificationsProviderType } from '../../notifications';

export interface AttachmentContextInterface {
  getById: (id: string) => Promise<GetAttachmentDto>;
  getAllForObject: (
    objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
    objectId: string,
  ) => Promise<GetAttachmentDto[]>;
  getAllForCollection: (
    objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
    getDtos: { id: string }[],
  ) => Promise<GetAttachmentDto[]>;
  getFile: (id: string) => Promise<AttachmentFileContent>;
  saveFileInBrowser: (
    dto: GetAttachmentDto,
    method: 'download' | 'open' | string,
  ) => void;
  create: (attachment: CreateUpdateAttachmentDto) => Promise<GetAttachmentDto>;
  createVoid: (attachment: CreateUpdateAttachmentDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export type Attachment = GetAttachmentDto | CreateUpdateAttachmentDto;

export const newAttachment = () => {
  return CreateUpdateAttachmentDtoFromJSON({});
};

export const validate = (attachment: CreateUpdateAttachmentDto) =>
  validateAgainst(AttachmentSchema, attachment);

export const AttachmentContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.attachmentControllerGet(id),
    getAllForObject: (
      objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
      objectId: string,
    ) => api.attachmentControllerGetAllForObject(objectType, objectId),
    getAllForCollection: async (
      objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
      getDtos: { id: string }[],
    ) => {
      const attachments = [];
      for (const dto of getDtos) {
        attachments.push(
          ...(await api.attachmentControllerGetAllForObject(
            objectType,
            dto.id,
          )),
        );
      }
      return attachments;
    },
    getFile: (id: string) => api.attachmentControllerGetFileDownload(id),
    create: (attachment: CreateUpdateAttachmentDto) =>
      api.attachmentControllerCreate(attachment),
    createVoid: async (attachment: CreateUpdateAttachmentDto) => {
      await api.attachmentControllerCreate(attachment);
    },
    delete: (id: string) => api.attachmentControllerDelete(id),
    saveFileInBrowser: (
      dto: GetAttachmentDto,
      method: 'download' | 'open' | string,
    ) => {
      api.attachmentControllerGetFileDownload(dto.id).then(fileContent => {
        const blob = new Blob(
          [Buffer.from(fileContent.buffer as ArrayBuffer)],
          {
            type: mime.lookup(dto.name) || undefined,
          },
        );
        if (method === 'open') {
          window.open(URL.createObjectURL(blob));
        } else {
          saveAs(blob, dto.name);
        }
      });
    },
  };
};

export const forAssociatedDocuments = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  attachments: GetAttachmentDto[],
  action?: () => void,
) => {
  const label = (attachment: GetAttachmentDto) =>
    `${attachment.type.value}: ${attachment.description || attachment.name}`;

  const addMethod = (attachment: CreateUpdateAttachmentDto) =>
    notifications.onPromise(snrwb.attachments.createVoid(attachment), action);

  const deleteMethod = (id: string) =>
    notifications.onPromise(snrwb.attachments.delete(id), action);

  const getMethod = (attachment: GetAttachmentDto, method: string) => {
    snrwb.attachments.saveFileInBrowser(attachment, method);
  };

  return AssociateDocumentsWithFilter({
    documents: attachments,
    labelGenerator: label,
    new: newAttachment,
    validate: validate,
    add: addMethod,
    delete: deleteMethod,
    get: getMethod,
  });
};

export type MemoizedAttachment = {
  id: string;
  existing?: GetAttachmentDto;
  type?: string;
  getInjected?: (dto: GetAttachmentDto, method: string) => void;
  newOne?: CreateUpdateAttachmentDto;
};

export const forModal = (
  attachments: MemoizedAttachment[],
  creator: () => CreateUpdateAttachmentDto,
) => {
  const label = (attachment: MemoizedAttachment) => {
    if (attachment.existing) {
      return `${attachment.existing.type.value}: ${
        attachment.existing.description || attachment.existing.name
      }`;
    }
    if (attachment.newOne && attachment.type) {
      return `${attachment.type}: ${
        attachment.newOne.description || attachment.newOne.name
      }`;
    }
    throw new Error("You didn't fill memorized attachments correct!");
  };

  const newMethod = () => {
    return {
      id: 'completely-irrelevant',
      newOne: creator(),
    };
  };

  const validateMethod = async (attachment: MemoizedAttachment) => {
    if (attachment.newOne) {
      return await validate(attachment.newOne);
    }
    return ValidationResultOK;
  };

  const addMethod = (attachment: MemoizedAttachment) =>
    attachments.push(attachment);

  const deleteMethod = (id: string) => {
    const index = attachments.findIndex(ma => ma.id === id);
    if (index !== -1) {
      attachments.splice(index, 1);
    }
  };

  const getMethod = (attachment: MemoizedAttachment, method: string) => {
    if (attachment.newOne) {
      const buffer = attachment.newOne.file;
      saveAs(
        new Blob([Buffer.from(buffer as ArrayBuffer)]),
        attachment.newOne.name,
      );
    }
    if (attachment.existing && attachment.getInjected) {
      attachment.getInjected(attachment.existing, method);
    }
  };

  return AssociateDocumentsWithFilter({
    documents: attachments,
    labelGenerator: label,
    new: newMethod,
    validate: validateMethod,
    add: addMethod,
    delete: deleteMethod,
    get: getMethod,
  });
};

export class ObjectType {
  static InspectedProduct: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.KontrolowanyWyrob;
  static ProtocolDuringInspection: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.ProtokolWTrakcieKontroli;
  static SampleCollect: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.PobranieProbek;
  static Sample: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.Probka;
  static Kwz: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.Kwz;
  static ExamResult: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.WynikBadania;
  static Judgment: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.Orzeczenie;
  static Proceeding: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.Postepowanie;
}
