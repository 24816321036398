/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetInspectionDto,
    GetInspectionDtoFromJSON,
    GetInspectionDtoFromJSONTyped,
    GetInspectionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetInspectionWithDetailsDto
 */
export interface GetInspectionWithDetailsDto {
    /**
     * 
     * @type {GetInspectionDto}
     * @memberof GetInspectionWithDetailsDto
     */
    inspection: GetInspectionDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    products: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    proceedings: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    attachments: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    misstatements: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    correctiveActions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    judgments: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    protocols: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    protocolsAttachments: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    inspectors: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionWithDetailsDto
     */
    paks: Array<string>;
}

export function GetInspectionWithDetailsDtoFromJSON(json: any): GetInspectionWithDetailsDto {
    return GetInspectionWithDetailsDtoFromJSONTyped(json, false);
}

export function GetInspectionWithDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInspectionWithDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspection': GetInspectionDtoFromJSON(json['inspection']),
        'products': json['products'],
        'proceedings': json['proceedings'],
        'attachments': json['attachments'],
        'misstatements': json['misstatements'],
        'correctiveActions': json['correctiveActions'],
        'judgments': json['judgments'],
        'protocols': json['protocols'],
        'protocolsAttachments': json['protocolsAttachments'],
        'inspectors': json['inspectors'],
        'paks': json['paks'],
    };
}

export function GetInspectionWithDetailsDtoToJSON(value?: GetInspectionWithDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspection': GetInspectionDtoToJSON(value.inspection),
        'products': value.products,
        'proceedings': value.proceedings,
        'attachments': value.attachments,
        'misstatements': value.misstatements,
        'correctiveActions': value.correctiveActions,
        'judgments': value.judgments,
        'protocols': value.protocols,
        'protocolsAttachments': value.protocolsAttachments,
        'inspectors': value.inspectors,
        'paks': value.paks,
    };
}


