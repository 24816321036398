import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import MultiColumnValue from '../../../app/components/MultiColumnValue';
import {
  CreateUpdateSampleDto,
  GetOrganizationalUnitDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow, { FormRow66 } from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import TextOrControl from '../../../app/components/TextOrControl';
import momencik from '../../../common/momencik';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import OrganizationalUnitSelector from '../Selects/OrganizationalUnitSelector';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import RichTextSingleLine from '../../../app/components/RichTextSingleLine';

export default ({
  presentedSample,
  editAuthorized,
  setProperty,
  presentedToSelected,
  changeAndBlur,
  sampleChange,
  operateOrganizationalUnit,
}: {
  presentedSample: CreateUpdateSampleDto;
  editAuthorized: boolean;
  setProperty: (obj: Partial<CreateUpdateSampleDto>) => void;
  presentedToSelected: () => void;
  changeAndBlur: (obj: Partial<CreateUpdateSampleDto>) => void;
  sampleChange: (obj: Partial<CreateUpdateSampleDto>) => void;
  operateOrganizationalUnit: GetOrganizationalUnitDto;
}) => (
  <>
    <Form className="d-grid gap-3">
      <FormRow controlId="quantity" label="Pobrana ilość">
        <TextOrControl
          readonly={!editAuthorized}
          value={presentedSample.quantity}
        >
          <RichTextSingleLine
            value={presentedSample.quantity || ''}
            onChange={v => changeAndBlur({ quantity: v })}
            onlyIndexes={true}
          />
        </TextOrControl>
      </FormRow>
      <FormRow controlId="batchNumber" label="Numer partii">
        <TextOrControl
          readonly={!editAuthorized}
          value={presentedSample.batchNumber}
        >
          <Form.Control
            type="text"
            value={presentedSample.batchNumber || ''}
            onChange={e => setProperty({ batchNumber: e.target.value })}
            onBlur={presentedToSelected}
          />
        </TextOrControl>
      </FormRow>
      <FormRow controlId="collectionMethod" label="Opis sposobu pobrania">
        <TextOrControl
          readonly={!editAuthorized}
          value={presentedSample.collectionMethod}
        >
          <DynamicTextarea
            value={presentedSample.collectionMethod || ''}
            onChange={e => setProperty({ collectionMethod: e.target.value })}
            onBlur={presentedToSelected}
          />
        </TextOrControl>
      </FormRow>
      <Row>
        <Col md={6}>
          <FormRow66
            controlId="productionDate"
            label="Data produkcji"
            static={!editAuthorized}
          >
            <TextOrControl
              readonly={!editAuthorized}
              value={momencik(presentedSample.productionDate)}
            >
              <MultiColumnValue
                variant="outline-secondary"
                onChange={sampleChange}
                columns={[
                  {
                    label: 'Data',
                    type: 'Date',
                    key: 'productionDate',
                    value: presentedSample.productionDate || null,
                  },
                  {
                    label: 'Opis',
                    type: 'string',
                    key: 'productionDateText',
                    value: presentedSample.productionDateText || null,
                  },
                ]}
              />
            </TextOrControl>
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66
            controlId="operateOrganizationalUnit"
            label="Organ obsługujący"
            static={!editAuthorized}
          >
            {!editAuthorized ? (
              <OrganizationalUnitBadge
                organizationalUnit={operateOrganizationalUnit}
              />
            ) : (
              <OrganizationalUnitSelector
                option={OrganizationalUnitsGetOptions.notNotified}
                value={presentedSample.operateOrganizationalUnitId}
                onChange={option => {
                  sampleChange({ operateOrganizationalUnitId: option.value });
                }}
              />
            )}
          </FormRow66>
        </Col>
      </Row>
      <FormRow controlId="comments" label="Uwagi">
        <TextOrControl
          readonly={!editAuthorized}
          value={presentedSample.comments}
        >
          <DynamicTextarea
            value={presentedSample.comments || ''}
            onChange={e => setProperty({ comments: e.target.value })}
            onBlur={presentedToSelected}
          />
        </TextOrControl>
      </FormRow>
    </Form>
  </>
);
