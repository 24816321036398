/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateEssentialFeatureDto
 */
export interface CreateUpdateEssentialFeatureDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    feature: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    functionalProperty: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    publish?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    examMethod: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    expectedResult: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    result: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    fullfilled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    controlResult: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    controlFullfilled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEssentialFeatureDto
     */
    sampleExamId: string;
}

export function CreateUpdateEssentialFeatureDtoFromJSON(json: any): CreateUpdateEssentialFeatureDto {
    return CreateUpdateEssentialFeatureDtoFromJSONTyped(json, false);
}

export function CreateUpdateEssentialFeatureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateEssentialFeatureDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feature': json['feature'],
        'functionalProperty': json['functionalProperty'],
        'publish': !exists(json, 'publish') ? undefined : json['publish'],
        'examMethod': json['examMethod'],
        'expectedResult': json['expectedResult'],
        'result': json['result'],
        'fullfilled': !exists(json, 'fullfilled') ? undefined : json['fullfilled'],
        'controlResult': json['controlResult'],
        'controlFullfilled': !exists(json, 'controlFullfilled') ? undefined : json['controlFullfilled'],
        'sampleExamId': json['sampleExamId'],
    };
}

export function CreateUpdateEssentialFeatureDtoToJSON(value?: CreateUpdateEssentialFeatureDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feature': value.feature,
        'functionalProperty': value.functionalProperty,
        'publish': value.publish,
        'examMethod': value.examMethod,
        'expectedResult': value.expectedResult,
        'result': value.result,
        'fullfilled': value.fullfilled,
        'controlResult': value.controlResult,
        'controlFullfilled': value.controlFullfilled,
        'sampleExamId': value.sampleExamId,
    };
}


