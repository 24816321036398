import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import { Alert, Badge, Button, Col, Form, Row, Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import Tabs from '../../../app/components/Tabs';
import { GetAttachmentDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { JudgmentNoEdit } from '../Judgments/JudgmentNoEdit';
import ItemPresentation from '../../../app/components/ItemPresentation';
import * as ProductTypeContext from '../../../common/snrwbCore/contexts/ProductTypeContext';
import FormRow from '../../../app/components/FormRow';
import DictionarySelector from '../Selects/DictionarySelector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { CreateUpdateKwzDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/CreateUpdateKwzDto';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import {
  calculateMomencikRemoveDate,
  KwzApi,
} from '../../../common/snrwbCore/contexts/KwzContext';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import momencik from '../../../common/momencik';
import { CreateUpdateAttachmentDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/CreateUpdateAttachmentDto';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { GetKwzWithPartiesDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetKwzWithPartiesDto';
import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  DataChangeScope,
  DataKey,
} from '../../../common/snrwbCore/sockets/socket.datatypes';
import { ConcurrencySignaler } from '../../../app/components/ConcurrencySignaler';
import { SubjectMemos } from '../Memos/SubjectMemos';

import { KwzRequestWithdrawal } from './KwzRequestWithdrawal';

interface KwzDetailsProps {
  api: KwzApi;
  relatedProceeding: () => void;
  onApprove: () => void;
  onApproveValidation: () => Promise<boolean>;
  onClose: () => void;
  validationErrors: string[];
  editAuthorized: boolean;
  publishAuthorized: boolean;
  kwz: GetKwzWithPartiesDto | undefined;
  kwzCU: CreateUpdateKwzDto | undefined;
  attachment: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  propertyChange: (obj: Partial<CreateUpdateKwzDto>, save?: boolean) => void;
}

export const KwzDetails = (props: KwzDetailsProps) => {
  const [key, setKey] = useState<DataKey>();
  const [description, setDescription] = useState<string>();

  useEffect(() => {
    if (!props.kwz?.id || !props.kwz?.publicationNumber) {
      return;
    }
    setKey({ scope: DataChangeScope.kwz, id: props.kwz.id });
    setDescription('KWZ nr ' + props.kwz.publicationNumber);
  }, [props.kwz?.id, props.kwz?.publicationNumber]);

  return (
    <Card>
      <Card.Body>
        {props.kwz && props.kwzCU ? (
          props.kwz.approved || !props.editAuthorized ? (
            <KwzDetailsNoEdit
              validationErrors={props.validationErrors}
              api={props.api}
              onClose={props.onClose}
              relatedProceeding={props.relatedProceeding}
              onApprove={props.onApprove}
              onApproveValidation={props.onApproveValidation}
              editAuthorized={props.editAuthorized}
              publishAuthorized={props.publishAuthorized}
              kwz={props.kwz}
              kwzCU={props.kwzCU}
              attachment={props.attachment}
              propertyChange={props.propertyChange}
            />
          ) : (
            <KwzDetailsEdit
              validationErrors={props.validationErrors}
              api={props.api}
              onClose={props.onClose}
              onApprove={props.onApprove}
              onApproveValidation={props.onApproveValidation}
              editAuthorized={props.editAuthorized}
              publishAuthorized={props.publishAuthorized}
              relatedProceeding={props.relatedProceeding}
              kwz={props.kwz}
              kwzCU={props.kwzCU}
              attachment={props.attachment}
              propertyChange={props.propertyChange}
            />
          )
        ) : (
          <Skeleton count={7} />
        )}
        {key && description && (
          <ConcurrencySignaler
            dataKey={key}
            objectDescription={description}
            editAllowed={props.editAuthorized}
            refreshAction={props.api.refresh}
          />
        )}
      </Card.Body>
    </Card>
  );
};
interface KwzDetailsEditProps {
  api: KwzApi;
  relatedProceeding: () => void;
  validationErrors: string[];
  onApprove: () => void;
  onApproveValidation: () => Promise<boolean>;
  onClose: () => void;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  kwz: GetKwzWithPartiesDto;
  kwzCU: CreateUpdateKwzDto;
  attachment: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  propertyChange: (obj: Partial<CreateUpdateKwzDto>, save?: boolean) => void;
}
interface KwzOtherTabProps {
  api?: KwzApi;
  activeNextTab?: () => void;
  readonly?: boolean;
  kwz: GetKwzWithPartiesDto;
  kwzCU: CreateUpdateKwzDto;
  propertyChange: (obj: Partial<CreateUpdateKwzDto>, save?: boolean) => void;
}

const PrintButton = (props: KwzDetailsEditProps) => {
  return (
    <>
      {props.kwz.approved ? (
        <Button
          onClick={() => props.api.kwzPrintCard(props.kwz.id)}
          variant="outline-primary"
          className="mx-1"
        >
          Wydruk informacji szczegółowych KWZ
        </Button>
      ) : (
        <ConfirmationButton
          roleAnyOf={[Role.KwzView]}
          onOK={() => props.api.kwzPrintCard(props.kwz.id)}
          variant="outline-primary"
          confirmation="Karta wyrobu zakwestionowanego nie jest jeszcze zatwierdzona, czy na pewno generować wydruk?"
          className="mx-1"
        >
          Wydruk informacji szczegółowych KWZ
        </ConfirmationButton>
      )}
    </>
  );
};
interface ReletedProceedingProps {
  relatedProceeding: () => void;
}

const ProceedingButton = (props: ReletedProceedingProps) => {
  return (
    <Button
      variant="outline-dark"
      className="mx-1"
      onClick={props.relatedProceeding}
    >
      Powiązane postępowanie
    </Button>
  );
};

export const KwzDetailsEdit = (props: KwzDetailsEditProps) => {
  const detailsTab = 'details';
  const decisionTab = 'decision';
  const productTypeMoreTab = 'productTypeMore';

  const [key, setKey] = useState(detailsTab);

  const whereToGo = (errors: string[]) => {
    for (const error of errors) {
      if (error.includes('Decyzja')) {
        return decisionTab;
      }
      if (error.includes('Dane dodatkowe')) {
        return productTypeMoreTab;
      }
    }
    return detailsTab;
  };

  return (
    <>
      <Alert variant="danger" className="mt-3">
        <Alert.Heading className="d-flex justify-content-between">
          <span>Karta wyrobu zakwestionowanego nie jest zatwierdzona</span>
          <div>
            <SubjectMemos subject={props.kwz} />
          </div>
        </Alert.Heading>
        <hr />
        <div className="d-flex justify-content-end">
          <ConfirmationButton
            roleAnyOf={[Role.KwzRevoke]}
            className="me-1"
            variant="outline-danger"
            onOK={() => props.api.deleteKwz(props.kwz.judgment.id)}
            confirmation="Czy na pewno usunąć KWZ?"
          >
            Usuń
          </ConfirmationButton>
          <ConfirmationButton
            roleAnyOf={[Role.KwzEdit]}
            variant="outline-primary"
            onValidation={props.onApproveValidation}
            onOK={props.onApprove}
            confirmation="Czy na pewno zatwierdzić KWZ?"
          >
            Zatwierdź
          </ConfirmationButton>
        </div>
      </Alert>
      <ValidationAlert
        show={props.validationErrors.length > 0}
        modal={true}
        onHide={() => {
          props.onClose();
          setKey(whereToGo(props.validationErrors));
        }}
        errors={props.validationErrors}
        className="mt-3"
      />
      <Card>
        <Tabs
          activeKey={key}
          onSelect={k => setKey(k ? k : detailsTab)}
          className="mb-3"
          id="kwzDetails"
        >
          <Tab eventKey={detailsTab} title="Karta" className="m-3">
            <KwzDetailsCard
              api={props.api}
              kwz={props.kwz}
              kwzCU={props.kwzCU}
              propertyChange={props.propertyChange}
            />
          </Tab>
          <Tab eventKey={decisionTab} title="Decyzja" className="m-3">
            <KwzDetailsDecision
              kwz={props.kwz}
              kwzCU={props.kwzCU}
              propertyChange={props.propertyChange}
            />
          </Tab>
          <Tab
            eventKey="productTypeExtended"
            title="Typ wyrobu"
            className="m-3"
          >
            <KwzProductType readonly={false} {...props} />
          </Tab>
          <Tab
            eventKey={productTypeMoreTab}
            title="Dodatkowe dane"
            className="m-3"
          >
            <KwzProductTypeMore readonly={false} {...props} />
          </Tab>
        </Tabs>
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <ProceedingButton relatedProceeding={props.relatedProceeding} />
        <PrintButton {...props} />
      </div>
    </>
  );
};

export const KwzDetailsNoEdit = (props: KwzDetailsEditProps) => {
  const detailsTab = 'details';
  const requestWithdrawalTab = 'requestWithdrawal';
  const [key, setKey] = useState(detailsTab);
  return (
    <>
      <KwzHeadInfo {...props}></KwzHeadInfo>
      {!props.editAuthorized && !props.publishAuthorized && (
        <Alert variant="warning">
          Brak uprawnień do edycji, cofnięcia zatwierdzenia oraz publikowania
          KWZ
        </Alert>
      )}
      {!props.editAuthorized && props.publishAuthorized && (
        <Alert variant="warning">
          Uprawnienia tylko do cofania zatwierdzenia i publikowania KWZ
        </Alert>
      )}
      {props.editAuthorized && !props.publishAuthorized && (
        <Alert variant="warning">Brak uprawnień do publikowania KWZ</Alert>
      )}
      <ValidationAlert
        show={true}
        errors={props.validationErrors}
        className="mt-3"
      />
      <Card>
        <Tabs
          activeKey={key}
          onSelect={k => setKey(k ? k : detailsTab)}
          className="mb-3"
          id="kwzDetails"
        >
          <Tab eventKey={detailsTab} title="Karta" className="m-3">
            <KwzDetailsCard
              readonly={true}
              activeNextTab={() => setKey(requestWithdrawalTab)}
              {...props}
            />
          </Tab>
          <Tab eventKey="decision" title="Decyzja" className="m-3">
            <KwzDetailsDecision readonly={true} {...props} />
          </Tab>
          <Tab
            eventKey="productTypeExtended"
            title="Typ wyrobu"
            className="m-3"
          >
            <KwzProductType readonly={true} {...props} />
          </Tab>
          <Tab
            eventKey="productTypeMore"
            title="Dodatkowe dane"
            className="m-3"
          >
            <KwzProductTypeMore readonly={true} {...props} />
          </Tab>
          {props.kwz.approved && props.kwz.request && (
            <Tab
              eventKey={requestWithdrawalTab}
              title="Wniosek o usunięcie wpisu"
              className="m-3"
            >
              <KwzRequestWithdrawal
                kwz={props.kwz}
                kwzCU={props.kwzCU}
                propertyChange={props.propertyChange}
                api={props.api}
                editAuthorized={props.editAuthorized}
                publishAuthorized={props.publishAuthorized}
                attachment={props.attachment}
                activeMainTab={() => setKey(detailsTab)}
              />
            </Tab>
          )}
        </Tabs>
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <ProceedingButton relatedProceeding={props.relatedProceeding} />
        <PrintButton {...props} />
      </div>
    </>
  );
};

// eslint-disable-next-line complexity
const KwzHeadInfo = (props: KwzDetailsEditProps) => {
  const variant = props.kwz.approved ? 'success' : 'danger';
  const heading = props.kwz.approved
    ? props.kwz.publicationDate
      ? `Karta wyrobu zakwestionowanego (numer: ${
          props.kwz.publicationNumber
        }) została opublikowana ${momencik(props.kwz.publicationDate)}`
      : `Karta wyrobu zakwestionowanego została zatwierdzona ${momencik(
          props.kwz.approvedDate,
        )}`
    : 'Karta wyrobu zakwestionowanego nie jest zatwierdzona';
  const removeHeading = props.kwz.removePublishDate
    ? `Publikacja została usunięta na wniosek ${momencik(
        props.kwz.removePublishDate,
      )}`
    : '';

  const canPublish = props.publishAuthorized;
  const canRevert = props.editAuthorized || props.publishAuthorized;

  return (
    <Alert variant={variant}>
      <Alert.Heading className="d-flex justify-content-between">
        <span>{heading}</span>
        <div>
          <SubjectMemos subject={props.kwz} />
        </div>
      </Alert.Heading>
      {props.kwz.removePublishDate && (
        <>
          <hr />
          <Alert.Heading>{removeHeading}</Alert.Heading>
        </>
      )}
      <hr />
      {props.kwz.approved &&
        (!props.kwz.publicationDate ? (
          <div className="d-flex justify-content-end">
            {canRevert && (
              <ConfirmationButton
                className="me-1"
                variant="outline-success"
                roleAnyOf={[Role.KwzRevoke]}
                onOK={() => props.api.revertApproveKwz(props.kwz.id)}
                confirmation="Czy na pewno cofnąć zatwierdzenie KWZ?"
              >
                Cofnij
              </ConfirmationButton>
            )}
            {canPublish && (
              <ConfirmationButton
                variant="outline-primary"
                roleAnyOf={[Role.KwzPublishing]}
                onOK={() => props.api.publish(props.kwz.id)}
                confirmation="Czy na pewno opublikować KWZ?"
              >
                Publikuj
              </ConfirmationButton>
            )}
          </div>
        ) : !props.kwz.requestApproved && canPublish ? (
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-danger"
              roleAnyOf={[Role.KwzPublishing]}
              onOK={() => props.api.revertPublish(props.kwz.id)}
              confirmation="Czy na pewno cofnąć opublikację KWZ?"
            >
              Cofnij publikację
            </ConfirmationButton>
          </div>
        ) : !props.kwz.removePublishDate && canPublish ? (
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-danger"
              roleAnyOf={[Role.KwzPublishing]}
              onOK={() => props.api.removePublish(props.kwz.id)}
              confirmation="Czy na pewno usunąć opublikowany wpis KWZ?"
            >
              Usuń na wniosek opublikowany wpis z poz. nr{' '}
              {props.kwz.publicationNumber}
            </ConfirmationButton>
          </div>
        ) : (
          canPublish && (
            <div className="d-flex justify-content-end">
              <ConfirmationButton
                variant="outline-danger"
                roleAnyOf={[Role.KwzPublishing]}
                onOK={() => props.api.revertRemovePublish(props.kwz.id)}
                confirmation="Czy na pewno przywrócić usunięty na wniosek opublikowany wpis KWZ?"
              >
                Przywróć usunięty opublikowany wpis z poz. nr{' '}
                {props.kwz.publicationNumber}
              </ConfirmationButton>
            </div>
          )
        ))}
    </Alert>
  );
};

export const KwzDetailsDecision: React.FC<KwzOtherTabProps> = props => {
  return (
    <>
      <JudgmentNoEdit readonly={true} value={props.kwz.judgment} />
      {props.readonly ? (
        <div>
          <dl className="row" key="decisionSymbol">
            <dt className="col-sm-3">Symbol kodu decyzji</dt>
            <dd className="col-sm-9">
              <Badge bg="info">{props.kwz.decisionSign?.shortname}</Badge>{' '}
              {props.kwz.decisionSign?.value}
            </dd>
          </dl>
          <dl className="row" key="decisionIndex">
            <dt className="col-sm-3">Indeks kodu decyzji</dt>
            <dd className="col-sm-9">
              <Badge bg="warning">{props.kwz.decisionIndex?.shortname}</Badge>{' '}
              {props.kwz.decisionIndex?.value}
            </dd>
          </dl>
        </div>
      ) : (
        <Form className="d-grid gap-3">
          <FormRow controlId="decisionSign" label="Symbol kodu decyzji">
            <DictionarySelector
              badgeBg="info"
              dictionary="kwz - symbol decyzji"
              value={props.kwzCU.decisionSignId}
              placeholder="Wskaż symbol kodu decyzji..."
              onChange={e => props.propertyChange({ decisionSignId: e.value })}
            />
          </FormRow>

          <FormRow controlId="decisionIndex" label="Indeks kodu decyzji">
            <DictionarySelector
              dictionary="kwz - indeks decyzji"
              placeholder="Wskaż indeks kodu decyzji..."
              value={props.kwzCU.decisionIndexId}
              onChange={e => props.propertyChange({ decisionIndexId: e.value })}
              clearable={true}
            />
          </FormRow>
        </Form>
      )}
    </>
  );
};

export const KwzDetailsCard = (props: KwzOtherTabProps) => {
  const withButton = props.kwz.publicationDate ? !props.kwz.request : false;
  const numberCardPresentation = props.kwz
    ? [
        { name: 'Numer karty', value: props.kwz.publicationNumber },
        {
          name: 'Data możliwego usunięcia wpisu',
          value: (
            <RemoveDatePresentation
              withButton={withButton}
              onAddRequest={() => {
                props.activeNextTab && props.activeNextTab();
                props.api?.addRequestForWithdrawal(props.kwz.id);
              }}
              finalDate={props.kwz.judgment.finalDate}
            />
          ),
        },
        {
          name: 'Organ',
          value: (
            <Badge bg="warning" text="dark">
              {props.kwz.organizationalUnit?.shortname}
            </Badge>
          ),
        },
      ]
    : [];
  const otherCardPresentation = props.kwz
    ? [
        {
          name: 'Rodzaj i zakres niezgodności',
          value: props.kwz.incompatibility,
        },
        {
          name: 'Zagrożenia, jakie może spowodować',
          value: props.kwz.danger,
        },
        {
          name: 'Informacja o nakazie odkupienia',
          value: props.kwz.buyBackOrder,
        },
        {
          name: 'Informacja o nakazie zniszczenia',
          value: props.kwz.destructionOrder,
        },
        {
          name: 'Inne informacje niezbędne do identyfikacji',
          value: props.kwz.other,
        },
      ]
    : [];

  return (
    <>
      <ItemPresentation item={numberCardPresentation} />
      {props.readonly ? (
        <ItemPresentation item={otherCardPresentation} />
      ) : (
        <Form className="d-grid gap-3">
          <FormRow
            controlId="incompatibitity"
            label="Rodzaj i zakres niezgodności"
          >
            <DynamicTextarea
              value={props.kwzCU.incompatibility || ''}
              onChange={e =>
                props.propertyChange({ incompatibility: e.target.value }, false)
              }
              onBlur={e =>
                props.propertyChange({ incompatibility: e.target.value })
              }
            />
          </FormRow>
          <FormRow controlId="danger" label="Zagrożenia, jakie może spowodować">
            <DynamicTextarea
              value={props.kwzCU.danger || ''}
              onChange={e =>
                props.propertyChange({ danger: e.target.value }, false)
              }
              onBlur={e => props.propertyChange({ danger: e.target.value })}
            />
          </FormRow>
          <FormRow
            controlId="buyBackOrder"
            label="Informacja o nakazie odkupienia"
          >
            <DynamicTextarea
              value={props.kwzCU.buyBackOrder || ''}
              onChange={e =>
                props.propertyChange({ buyBackOrder: e.target.value }, false)
              }
              onBlur={e =>
                props.propertyChange({ buyBackOrder: e.target.value })
              }
            />
          </FormRow>
          <FormRow
            controlId="desctructionOrder"
            label="Informacja o nakazie zniszczenia"
          >
            <DynamicTextarea
              value={props.kwzCU.destructionOrder || ''}
              onChange={e =>
                props.propertyChange(
                  { destructionOrder: e.target.value },
                  false,
                )
              }
              onBlur={e =>
                props.propertyChange({ destructionOrder: e.target.value })
              }
            />
          </FormRow>
          <FormRow
            controlId="other"
            label="Inne informacje niezbędne do identyfikacji"
          >
            <DynamicTextarea
              value={props.kwzCU.other || ''}
              onChange={e =>
                props.propertyChange({ other: e.target.value }, false)
              }
              onBlur={e => props.propertyChange({ other: e.target.value })}
            />
          </FormRow>
        </Form>
      )}
    </>
  );
};

const RemoveDatePresentation = (props: {
  withButton: boolean;
  onAddRequest?: () => void;
  finalDate: Date | undefined;
}) => {
  const removeDate = calculateMomencikRemoveDate(props.finalDate);

  return (
    <Row>
      <Col md={8}>{removeDate}</Col>
      {props.withButton && props.onAddRequest && (
        <Col md={4} className="d-flex justify-content-end">
          <ConfirmationButton
            variant="outline-success"
            roleAnyOf={[Role.KwzEdit]}
            onOK={props.onAddRequest}
            confirmation="Czy utworzyć wniosek o usunięcie wpisu KWZ?"
          >
            Złóż wniosek o usunięcie wpisu
          </ConfirmationButton>
        </Col>
      )}
    </Row>
  );
};

const KwzProductType = (props: KwzOtherTabProps) => {
  const variant = props.kwz.judgment.proceeding.inspectedProduct.productType
    .approved
    ? 'success'
    : 'danger';
  const heading = props.kwz.judgment.proceeding.inspectedProduct.productType
    .approved
    ? 'Zatwierdzony typ wyrobu'
    : 'Niezatwierdzony typ wyrobu';
  const productTypePresentation = ProductTypeContext.ProductTypeToPresent(
    props.kwz.judgment.proceeding.inspectedProduct.productType,
  );
  return (
    <>
      <Alert variant={variant}>
        <Alert.Heading>{heading}</Alert.Heading>
        <hr />
      </Alert>
      <ItemPresentation item={productTypePresentation} />
    </>
  );
};

const KwzPartiesInfo = (props: KwzOtherTabProps) => {
  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between">
        <span className="align-self-center">Strony</span>
      </Card.Header>
      <Card.Body>
        {props.kwz.parties.length === 0 ? (
          <>
            <Alert variant="warning">
              W postępowaniu aktualnie nie ma żadnej strony.
            </Alert>
          </>
        ) : (
          <Table striped>
            <tbody>
              {props.kwz.parties.map(party => (
                <tr key={party.id} className="align-middle">
                  <td className="col-5 col-form-label">
                    {party.organizationType.value}
                  </td>
                  <td className="col-5">{party.organization.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
};

const KwzProductTypeMore = (props: KwzOtherTabProps) => {
  return (
    <>
      {props.readonly ? (
        <>
          <KwzPartiesInfo {...props} />
          <ItemPresentation
            item={[
              {
                name: 'Numer partii wyrobu',
                value: props.kwz.productBatchNumber,
              },
              { name: 'Kod taryfy celnej', value: props.kwz.tariffCode },
              {
                name: 'Kraj wytworzenia wyrobu budowlanego',
                value: props.kwz.productionCountry,
              },
            ]}
          />
        </>
      ) : (
        <>
          <KwzPartiesInfo {...props} />
          <Form className="d-grid gap-3">
            <FormRow controlId="productBatchNumber" label="Numer partii wyrobu">
              <Form.Control
                type="text"
                value={props.kwzCU.productBatchNumber || ''}
                onChange={e =>
                  props.propertyChange(
                    {
                      productBatchNumber: e.target.value,
                    },
                    false,
                  )
                }
                onBlur={e =>
                  props.propertyChange({
                    productBatchNumber: e.target.value,
                  })
                }
              />
            </FormRow>
            <FormRow controlId="tariffCode" label="Kod taryfy celnej">
              <Form.Control
                type="text"
                value={props.kwzCU.tariffCode || ''}
                onChange={e =>
                  props.propertyChange(
                    {
                      tariffCode: e.target.value,
                    },
                    false,
                  )
                }
                onBlur={e =>
                  props.propertyChange({
                    tariffCode: e.target.value,
                  })
                }
              />
            </FormRow>
            <FormRow
              controlId="productionCountry"
              label="Kraj wytworzenia wyrobu budowlanego"
            >
              <Form.Control
                type="text"
                value={props.kwzCU.productionCountry || ''}
                onChange={e =>
                  props.propertyChange(
                    {
                      productionCountry: e.target.value,
                    },
                    false,
                  )
                }
                onBlur={e =>
                  props.propertyChange({
                    productionCountry: e.target.value,
                  })
                }
              />
            </FormRow>
          </Form>
        </>
      )}
    </>
  );
};
