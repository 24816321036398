/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationStatus
 */
export interface ValidationStatus {
    /**
     * 
     * @type {string}
     * @memberof ValidationStatus
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationStatus
     */
    message: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationStatus
     */
    errors: Array<string>;
}

export function ValidationStatusFromJSON(json: any): ValidationStatus {
    return ValidationStatusFromJSONTyped(json, false);
}

export function ValidationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'message': json['message'],
        'errors': json['errors'],
    };
}

export function ValidationStatusToJSON(value?: ValidationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'message': value.message,
        'errors': value.errors,
    };
}


