/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateInspectionMetricDto
 */
export interface CreateUpdateInspectionMetricDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionMetricDto
     */
    inspectionTypeId: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateInspectionMetricDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionMetricDto
     */
    fileNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionMetricDto
     */
    organizationTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionMetricDto
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionMetricDto
     */
    inspectionPlace: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectionMetricDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionMetricDto
     */
    organizationalUnitId: string;
}

export function CreateUpdateInspectionMetricDtoFromJSON(json: any): CreateUpdateInspectionMetricDto {
    return CreateUpdateInspectionMetricDtoFromJSONTyped(json, false);
}

export function CreateUpdateInspectionMetricDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInspectionMetricDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionTypeId': json['inspectionTypeId'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'fileNumber': json['fileNumber'],
        'organizationTypeId': json['organizationTypeId'],
        'organizationId': json['organizationId'],
        'inspectionPlace': json['inspectionPlace'],
        'approved': json['approved'],
        'organizationalUnitId': json['organizationalUnitId'],
    };
}

export function CreateUpdateInspectionMetricDtoToJSON(value?: CreateUpdateInspectionMetricDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionTypeId': value.inspectionTypeId,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'fileNumber': value.fileNumber,
        'organizationTypeId': value.organizationTypeId,
        'organizationId': value.organizationId,
        'inspectionPlace': value.inspectionPlace,
        'approved': value.approved,
        'organizationalUnitId': value.organizationalUnitId,
    };
}


