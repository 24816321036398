import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import { GetInspectedProductDto } from '../autogenerated/snrwbApiClient/models/GetInspectedProductDto';
import {
  CreateUpdateInspectedProductDto,
  CreateUpdateInspectedProductDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models/CreateUpdateInspectedProductDto';
import { ValidationStatus } from '../autogenerated/snrwbApiClient/models/ValidationStatus';
import {
  CreateUpdateOrganizationDto,
  CreateUpdateProductTypeDto,
} from '../autogenerated/snrwbApiClient';

import { Organization } from './OrganizationContext';
import { ProductType } from './ProductTypeContext';

export interface InspectedProductContextInterface {
  getById: (id: string) => Promise<GetInspectedProductDto>;
  getByInspection: (inspectionId: string) => Promise<GetInspectedProductDto[]>;
  getBySampleExam: (sampleExamId: string) => Promise<GetInspectedProductDto[]>;
  validateNew: (
    inspectionId: string,
    productTypeId: string,
  ) => Promise<ValidationStatus>;
  createBasedOnProductType: (
    inspectionId: string,
    productTypeId: string,
  ) => Promise<GetInspectedProductDto>;
  createWithNewProductType: (
    inspectionId: string,
    productType: CreateUpdateProductTypeDto,
  ) => Promise<GetInspectedProductDto>;
  createWithNewProducerAndProductType: (
    inspectionId: string,
    producer: CreateUpdateOrganizationDto,
    productType: CreateUpdateProductTypeDto,
  ) => Promise<GetInspectedProductDto>;
  create: (
    inspectionId: string,
    organization: Organization,
    productType: ProductType,
  ) => Promise<GetInspectedProductDto>;
  update: (id: string, dto: CreateUpdateInspectedProductDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export const InspectedProductContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.inspectedProductControllerGet(id),

    getByInspection: async (inspectionId: string) => {
      const list = await api.inspectedProductControllerGetByInspection(
        inspectionId,
      );
      list.sort((ip1, ip2) => ip1.orderNo - ip2.orderNo);
      return list;
    },

    getBySampleExam: (sampleExamId: string) =>
      api.inspectedProductControllerGetBySampleExam(sampleExamId),

    validateNew: (inspectionId: string, productTypeId: string) =>
      api.inspectedProductControllerValidateNew({
        inspectionId,
        productTypeId,
      }),

    createBasedOnProductType: (inspectionId: string, productTypeId: string) =>
      api.inspectedProductControllerCreateBasedOnProductType({
        inspectionId: inspectionId,
        productTypeId: productTypeId,
      }),

    createWithNewProductType: (
      inspectionId: string,
      productType: CreateUpdateProductTypeDto,
    ) =>
      api.inspectedProductControllerCreateWithNewProductType({
        inspectionId: inspectionId,
        productType: productType,
      }),

    createWithNewProducerAndProductType: (
      inspectionId: string,
      producer: CreateUpdateOrganizationDto,
      productType: CreateUpdateProductTypeDto,
    ) =>
      api.inspectedProductControllerCreateWithNewProducerAndProductType({
        inspectionId: inspectionId,
        producer: producer,
        productType: productType,
      }),

    create: (
      inspectionId: string,
      organization: Organization,
      productType: ProductType,
    ) => {
      if ('id' in organization && 'id' in productType) {
        return api.inspectedProductControllerCreateBasedOnProductType({
          inspectionId: inspectionId,
          productTypeId: productType.id,
        });
      } else if ('id' in organization) {
        const createUpdateDto = productType as CreateUpdateProductTypeDto;
        createUpdateDto.organizationId = organization.id;
        return api.inspectedProductControllerCreateWithNewProductType({
          inspectionId: inspectionId,
          productType: createUpdateDto,
        });
      } else {
        return api.inspectedProductControllerCreateWithNewProducerAndProductType(
          {
            inspectionId: inspectionId,
            producer: organization,
            productType: productType as CreateUpdateProductTypeDto,
          },
        );
      }
    },

    update: (id: string, dto: CreateUpdateInspectedProductDto) =>
      api.inspectedProductControllerUpdate(id, dto),

    delete: (id: string) => api.inspectedProductControllerDelete(id),
  };
};

export const convert = (product: GetInspectedProductDto) => {
  const converted = CreateUpdateInspectedProductDtoFromJSON(product);
  return converted;
};
