import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import { useNotifications } from '../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../common/hooks/useSnrwbCore';
import versionWeb from '../../assets/version.json';
import { useCookies } from '../../common/hooks/useCookies';

export const DocumentationView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const notifications = useNotifications();
  const cookies = useCookies();
  const [content, setContent] = useState('');
  const [coreInfo, setCoreInfo] = useState('');

  const params = new URLSearchParams(window.location.search);
  const filename = cookies.organizationalUnit?.notified
    ? 'certyfikaty_jednostek.html'
    : params.get('plik');

  const getPdf = () => {
    snrwb.docs.getForFilename('snrwb.pdf').then(doc => {
      const file = new Blob([Buffer.from(doc.content, 'base64')], {
        type: 'application/pdf',
      });
      window.open(URL.createObjectURL(file));
    });
  };

  useEffect(() => {
    snrwb.docs
      .getForFilename(filename || 'index.html')
      .then(doc => {
        let content = Buffer.from(doc.content, 'base64').toString();
        content = content.replaceAll('href="ch', 'href="/pomoc?plik=ch');
        content = content.replaceAll('href="idp', 'href="/pomoc?plik=idp');
        content = content.replaceAll('href="index', 'href="/pomoc?plik=index');
        content = content.replaceAll('../../common/', '');

        setContent(content);

        if (window.location.hash) {
          setTimeout(() => {
            const elem = document.getElementById(window.location.hash.slice(1));
            elem?.scrollIntoView();
          }, 0);
        }
      })
      .catch(() => notifications.badLink());

    snrwb.docs.getVersionInfo().then(versionCore => {
      if (versionCore.date) {
        setCoreInfo(
          `DB: kompilacja z ${versionCore.buildTime} (repo: ${versionCore.date}, ${versionCore.commit})`,
        );
      }
    });
  }, [snrwb.docs, filename, notifications]);

  const webInfo = versionWeb['date']
    ? `SNRWB: kompilacja z ${versionWeb['buildTime']} (repo: ${versionWeb['date']}, ${versionWeb['commit']})`
    : '';

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      {!cookies.organizationalUnit?.notified && (
        <div className="d-flex mt-3">
          <div className="flex-grow-1 small text-secondary">
            {webInfo}
            <br />
            {coreInfo}
          </div>
          <div>
            <Button variant="link" onClick={getPdf}>
              Dokumentacja w formacie PDF
              <img src="images/pdf-file.png" alt="Dokumentacja PDF" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
