/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrgUnitInspectedProductsRanges,
    OrgUnitInspectedProductsRangesFromJSON,
    OrgUnitInspectedProductsRangesFromJSONTyped,
    OrgUnitInspectedProductsRangesToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportInspectedProductsByRange
 */
export interface ReportInspectedProductsByRange {
    /**
     * 
     * @type {object}
     * @memberof ReportInspectedProductsByRange
     */
    details: object;
    /**
     * 
     * @type {OrgUnitInspectedProductsRanges}
     * @memberof ReportInspectedProductsByRange
     */
    sum: OrgUnitInspectedProductsRanges;
}

export function ReportInspectedProductsByRangeFromJSON(json: any): ReportInspectedProductsByRange {
    return ReportInspectedProductsByRangeFromJSONTyped(json, false);
}

export function ReportInspectedProductsByRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportInspectedProductsByRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': json['details'],
        'sum': OrgUnitInspectedProductsRangesFromJSON(json['sum']),
    };
}

export function ReportInspectedProductsByRangeToJSON(value?: ReportInspectedProductsByRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details,
        'sum': OrgUnitInspectedProductsRangesToJSON(value.sum),
    };
}


