import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import * as Icon from 'react-bootstrap-icons';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { SampleExamSearch } from '../../components/SampleExams/SampleExamSearch';
import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';

export const PublishingView: React.FC = () => {
  const nav = useNavigation();
  const snrwb = useSnrwbCore();

  return (
    <Card>
      <TabsHistory activeKey="ready" id="sampleExams" className="mb-3">
        <Tab
          eventKey="ready"
          className="mx-3"
          title={
            <>
              <Icon.Search /> Gotowe wyniki badań
            </>
          }
        >
          <SampleExamSearch
            getData={snrwb.sampleExams.getReadyForPublicationByPortion}
            getCount={snrwb.sampleExams.getReadyForPublicationCount}
            emptyMessage="Obecnie nie ma badań, które czekają na publikację."
            onSelect={nav.sampleExamPublishing}
            withApprovedDate={true}
          />
        </Tab>
        <Tab
          eventKey="revision"
          className="mx-3"
          title={
            <>
              <Icon.Search /> Zwrócone do poprawy
            </>
          }
        >
          <SampleExamSearch
            getData={snrwb.sampleExams.getExamsInRevisionByPortion}
            getCount={snrwb.sampleExams.getExamsInRevisionCount}
            emptyMessage="Obecnie w systemie nie ma badań, których wyniki zwrócono do poprawy."
            onSelect={nav.sampleExamPublishing}
          />
        </Tab>
        <Tab
          eventKey="withoutApprovedResults"
          className="mx-3"
          title={
            <>
              <Icon.Search /> Nieprzekazane do publikacji
            </>
          }
        >
          <SampleExamSearch
            getData={snrwb.sampleExams.getExamsWithoutApprovedResultsByPortion}
            getCount={snrwb.sampleExams.getExamsWithoutApprovedResultsCount}
            emptyMessage="Obecnie w systemie nie ma badań, które oczekują na przekazanie do publikacji."
            onSelect={nav.sampleExamPublishing}
          />
        </Tab>
        <Tab
          eventKey="search"
          className="mx-3"
          title={
            <>
              <Icon.Search /> Opublikowane
            </>
          }
        >
          <SampleExamSearch
            withLp
            getData={snrwb.sampleExams.getPublishedByPortion}
            getCount={snrwb.sampleExams.getPublishedCount}
            emptyMessage="Brak opublikowanych wyników badań."
            onSelect={nav.sampleExam}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
