/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOrganizationDto
 */
export interface GetOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    nip: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    addressEx: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    place: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    numbers: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    flat: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetOrganizationDto
     */
    activityType: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationDto
     */
    lastName: string;
}

export function GetOrganizationDtoFromJSON(json: any): GetOrganizationDto {
    return GetOrganizationDtoFromJSONTyped(json, false);
}

export function GetOrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'nip': json['nip'],
        'addressEx': json['addressEx'],
        'postcode': json['postcode'],
        'place': json['place'],
        'street': json['street'],
        'country': json['country'],
        'numbers': json['numbers'],
        'flat': json['flat'],
        'activityType': GetDictionaryDtoFromJSON(json['activityType']),
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}

export function GetOrganizationDtoToJSON(value?: GetOrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'nip': value.nip,
        'addressEx': value.addressEx,
        'postcode': value.postcode,
        'place': value.place,
        'street': value.street,
        'country': value.country,
        'numbers': value.numbers,
        'flat': value.flat,
        'activityType': GetDictionaryDtoToJSON(value.activityType),
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}


