import { GetEmployeeDto } from '../autogenerated/snrwbApiClient/models/GetEmployeeDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateEmployeeDto,
  CreateUpdateEmployeeDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models/CreateUpdateEmployeeDto';
import { EmployeeSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface EmployeeContextInterface {
  getCurrent: () => Promise<GetEmployeeDto>;
  getActive: () => Promise<GetEmployeeDto[]>;
  getForCurrentUser: () => Promise<GetEmployeeDto[]>;
  update: (id: string, dto: CreateUpdateEmployeeDto) => Promise<void>;
  create: (dto: CreateUpdateEmployeeDto) => Promise<GetEmployeeDto>;
  get: (id: string) => Promise<GetEmployeeDto>;
  changeToInactive: (id: string) => Promise<void>;
  getLabelForDuplicates: (employee: GetEmployeeDto) => string;
}

export const EmployeeContext = (api: DefaultApi) => {
  return {
    getCurrent: () => api.employeeControllerGetCurrent(),
    getActive: () => api.employeeControllerGetActive(),
    getForCurrentUser: () => api.employeeControllerGetForCurrentUser(),
    update: (id: string, dto: CreateUpdateEmployeeDto) =>
      api.employeeControllerUpdate(id, dto),
    create: (dto: CreateUpdateEmployeeDto) => api.employeeControllerCreate(dto),
    get: (id: string) => api.employeeControllerGet(id),
    changeToInactive: (id: string) =>
      api.employeeControllerChangeToInactive(id),
    getLabelForDuplicates: (dto: GetEmployeeDto) => {
      if (dto.hasUniqueNames) {
        return '';
      }
      return ` (legit.: ${dto.cardNo})`;
    },
  };
};

export const validate = (employee: CreateUpdateEmployeeDto) =>
  validateAgainst(EmployeeSchema, employee);

export const employeeData = (
  user?: GetEmployeeDto,
): CreateUpdateEmployeeDto => {
  return CreateUpdateEmployeeDtoFromJSON({
    ...user,
    organizationalUnitId: user?.organizationalUnit?.id,
  });
};

export const empolyeeName = (dto: GetEmployeeDto) =>
  `${dto?.firstName} ${dto?.lastName}`;
