import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';

import {
  GetCertificateDto,
  GetCustomsFeedbackDto,
  GetObjectHistoryDto,
  GetOrganizationDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  Certificates,
  CertificatesVariant,
} from '../Certificates/Certificates';
import { CreateUpdateCertificate } from '../Certificates/CreateUpdateCertificate';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { CustomsFeedbacks } from '../CustomsFeedbacks/CustomsFeedbacks';
import { CreateUpdateCustomsFeedback } from '../CustomsFeedbacks/CreateUpdateCustomsFeedback';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import { ObjectHistory } from '../ObjectHistory/ObjectHistory';
import { ObjectHistoryDetails } from '../ObjectHistory/ObjectHistoryDetails';
import { ProductTypeSearch } from '../ProductTypes/ProductTypeSearch';
import { useAuth } from '../../../common/hooks/useAuth';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { TabsHistory } from '../../../app/components/TabsHistory';

import { OrganizationEditor } from './OrganizationEditor';
import { OrganizationsSearch } from './OrganizationSearch';

interface EditOrganizationProps {
  organization: GetOrganizationDto;
  certificateData: GetCertificateDto[];
  customsFeedbackData: GetCustomsFeedbackDto[];
  objectHistoryData: GetObjectHistoryDto[];
  handleRefresh: () => void;
  active?: string;
}

export const EditOrganization: React.FC<EditOrganizationProps> = props => {
  const [certificate, setCertificate] = useState<GetCertificateDto>();
  const [customsFeedback, setCustomsFeedback] =
    useState<GetCustomsFeedbackDto>();
  const [objectHistory, setObjectHistory] = useState<GetObjectHistoryDto>();
  const [showHistoryDetails, setShowHistoryDetails] = useState(false);
  const snrwb = useSnrwbCore();
  const auth = useAuth();

  const handleOnClickCertificate = (key: string) => {
    snrwb.certificates
      .getById(key)
      .then(certificate => setCertificate(certificate));
  };

  const handleOnClickCustomsFeedback = (key: string) => {
    snrwb.customsFeedbacks
      .getById(key)
      .then(customsFeedback => setCustomsFeedback(customsFeedback));
  };

  const handleOnClickObjectHistory = (key: string) => {
    snrwb.objectHistory.getById(key).then(objectHistory => {
      setObjectHistory(objectHistory);
      setShowHistoryDetails(true);
    });
  };

  const handleObjectHistoryClose = () => {
    setShowHistoryDetails(false);
  };

  const handleRefresh = () => {
    setCertificate(undefined);
    setCustomsFeedback(undefined);
    props.handleRefresh();
  };

  return (
    <Card>
      <Card.Header>
        Podmiot {props.organization.name}
        {props.organization.nip && ', NIP: ' + props.organization.nip}
      </Card.Header>
      <Card.Body>
        <Card className="mt-3">
          <TabsHistory
            activeKey={props.active ? props.active : 'organization'}
            id="kontrola"
            className="mb-3"
          >
            <Tab eventKey="organization" title="Podmiot" className="m-3">
              <ItemPresentation
                item={OrganizationContext.toPresent(props.organization)}
              />
              <OrganizationEditor
                organization={props.organization}
                onRefresh={props.handleRefresh}
              />
            </Tab>
            {auth.check(Role.ProductTypesView) && props.organization.id && (
              <Tab
                eventKey="productsTypes"
                title="Typy wyrobów"
                className="m-3"
              >
                <ProductTypeSearch
                  withoutOrganization={true}
                  getData={(
                    pageSize,
                    pageNumber,
                    orderBy,
                    orderDirection,
                    filterText,
                  ) =>
                    snrwb.productTypes.getByOrganizationByPortion(
                      pageSize,
                      pageNumber,
                      orderBy,
                      orderDirection,
                      filterText,
                      props.organization.id,
                    )
                  }
                  getCount={filterText =>
                    snrwb.productTypes.getByOrganizationCount(
                      filterText,
                      props.organization.id,
                    )
                  }
                />
              </Tab>
            )}
            {auth.check(Role.CertificatesView) && (
              <Tab eventKey="certificates" title="Certyfikaty" className="m-3">
                <Certificates
                  data={props.certificateData}
                  variant={CertificatesVariant.withoutOrganization}
                  onClick={handleOnClickCertificate}
                />
                <CreateUpdateCertificate
                  organization={props.organization}
                  certificate={certificate}
                  onAddNew={handleRefresh}
                  onChange={handleRefresh}
                  onClose={handleRefresh}
                />
              </Tab>
            )}
            {auth.check(Role.CustomsFeedbacksView) && (
              <Tab
                eventKey="customsFeedbacks"
                title="Opinie celne"
                className="m-3"
              >
                <CustomsFeedbacks
                  data={props.customsFeedbackData}
                  withOrganization={false}
                  onClick={handleOnClickCustomsFeedback}
                />
                <CreateUpdateCustomsFeedback
                  organization={props.organization}
                  customsFeedback={customsFeedback}
                  onAddNew={handleRefresh}
                  onChange={handleRefresh}
                  onClose={handleRefresh}
                />
              </Tab>
            )}
            <Tab eventKey="historyFeedbacks" title="Historia" className="m-3">
              <ObjectHistory
                data={props.objectHistoryData}
                onClick={handleOnClickObjectHistory}
              />
              <ObjectHistoryDetails
                show={showHistoryDetails}
                handleClose={handleObjectHistoryClose}
                objectHistory={objectHistory}
                detailsType={'ObjectHistoryDetailsForOrganization'}
              />
            </Tab>
            {auth.check(Role.OrganizationsMerging) && (
              <Tab
                eventKey="mergingDuplicates"
                title="Sklejanie"
                className="m-3"
              >
                <OrganizationsSearch
                  getData={snrwb.organizations.getAllByPortion}
                  getCount={snrwb.organizations.getAllCount}
                  withMerging={true}
                  organizationHeadId={props.organization.id}
                />
              </Tab>
            )}
          </TabsHistory>
        </Card>
      </Card.Body>
    </Card>
  );
};
