/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetInspectedProductDto,
    GetInspectedProductDtoFromJSON,
    GetInspectedProductDtoFromJSONTyped,
    GetInspectedProductDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
    GetSampleDto,
    GetSampleDtoFromJSON,
    GetSampleDtoFromJSONTyped,
    GetSampleDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProceedingDto
 */
export interface GetProceedingDto {
    /**
     * 
     * @type {string}
     * @memberof GetProceedingDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetProceedingDto
     */
    legalBasis: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetProceedingDto
     */
    quantity: string;
    /**
     * 
     * @type {Date}
     * @memberof GetProceedingDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetProceedingDto
     */
    endDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetProceedingDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetProceedingDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {GetSampleDto}
     * @memberof GetProceedingDto
     */
    sample: GetSampleDto;
    /**
     * 
     * @type {string}
     * @memberof GetProceedingDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof GetProceedingDto
     */
    checkingProductId: string;
    /**
     * 
     * @type {GetInspectedProductDto}
     * @memberof GetProceedingDto
     */
    inspectedProduct: GetInspectedProductDto;
}

export function GetProceedingDtoFromJSON(json: any): GetProceedingDto {
    return GetProceedingDtoFromJSONTyped(json, false);
}

export function GetProceedingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProceedingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'legalBasis': GetDictionaryDtoFromJSON(json['legalBasis']),
        'quantity': json['quantity'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'approved': json['approved'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'sample': GetSampleDtoFromJSON(json['sample']),
        'inspectedProductId': json['inspectedProductId'],
        'checkingProductId': json['checkingProductId'],
        'inspectedProduct': GetInspectedProductDtoFromJSON(json['inspectedProduct']),
    };
}

export function GetProceedingDtoToJSON(value?: GetProceedingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'legalBasis': GetDictionaryDtoToJSON(value.legalBasis),
        'quantity': value.quantity,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'approved': value.approved,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'sample': GetSampleDtoToJSON(value.sample),
        'inspectedProductId': value.inspectedProductId,
        'checkingProductId': value.checkingProductId,
        'inspectedProduct': GetInspectedProductDtoToJSON(value.inspectedProduct),
    };
}


