import React from 'react';

import { GetInspectionExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useNavigation } from '../../../common/navigation';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import momencik from '../../../common/momencik';

import { InspectionType } from './InspectionType';
import { InspectionLink } from './InspectionLink';

interface InspectionsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  hideOrganizationalUnitColumn?: boolean;
}

export const inspectionForGrid = (inspection: GetInspectionExtendedDto) => {
  return {
    key: inspection.id,
    values: {
      'organizationalUnit.shortname': (
        <OrganizationalUnitBadge
          organizationalUnit={inspection.metric.organizationalUnit}
        />
      ),
      'organization.name': inspection.metric.organization.name,
      'metric.inspectionPlace': inspection.metric.inspectionPlace,
      'metric.startDate': momencik(inspection.metric.startDate),
      type: <InspectionType inspection={inspection} />,
      'metric.fileNumber': inspection.metric.fileNumber,
      link: <InspectionLink inspectionId={inspection.id} />,
    },
    orderByValues: {
      'organizationalUnit.shortname':
        inspection.metric.organizationalUnit.shortname,
      startDate: momencik(inspection.metric.startDate, ''),
    },
  };
};

export const InspectionsSearch: React.FC<InspectionsSearchProps> = props => {
  const nav = useNavigation();
  const columns = [
    { header: '', property: 'type', noSorting: true },
    { header: 'Organ', property: 'organizationalUnit.shortname' },
    { header: 'Podmiot', property: 'organization.name' },
    { header: 'Miejsce', property: 'metric.inspectionPlace' },
    { header: 'Data rozpoczęcia', property: 'metric.startDate' },
    { header: 'Nr akt', property: 'metric.fileNumber' },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  if (props.hideOrganizationalUnitColumn) {
    columns.splice(1, 1);
  }

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      initialOrderColumn="metric.startDate"
      initialOrderDirection="DESC"
      showFilter={true}
      availablePageSizes={[10, 20, 50]}
      onRowClick={nav.inspection}
      emptyGridMessage="Brak aktualnie toczących się kontroli w tej jednostce organizacyjnej"
    />
  );
};
