/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateAttachmentDto
 */
export interface CreateUpdateAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    typeId: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateAttachmentDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof CreateUpdateAttachmentDto
     */
    file: object;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    enObjectType: CreateUpdateAttachmentDtoEnObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    objectId: string;
}

export function CreateUpdateAttachmentDtoFromJSON(json: any): CreateUpdateAttachmentDto {
    return CreateUpdateAttachmentDtoFromJSONTyped(json, false);
}

export function CreateUpdateAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateAttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeId': json['typeId'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'description': json['description'],
        'name': json['name'],
        'file': json['file'],
        'enObjectType': json['enObjectType'],
        'objectId': json['objectId'],
    };
}

export function CreateUpdateAttachmentDtoToJSON(value?: CreateUpdateAttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typeId': value.typeId,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'description': value.description,
        'name': value.name,
        'file': value.file,
        'enObjectType': value.enObjectType,
        'objectId': value.objectId,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateAttachmentDtoEnObjectTypeEnum {
    KontrolowanyWyrob = 'kontrolowany_wyrob',
    ProtokolWTrakcieKontroli = 'protokol_w_trakcie_kontroli',
    PobranieProbek = 'pobranie_probek',
    Probka = 'probka',
    Kwz = 'kwz',
    WynikBadania = 'wynik_badania',
    Orzeczenie = 'orzeczenie',
    Postepowanie = 'postepowanie'
}


