import React, { useState, useRef, useEffect, useCallback } from 'react';

import NotificationSound, { Player } from '../app/components/NotificationSound';

import { useSnrwbCore } from './hooks/useSnrwbCore';
import { useSockets } from './hooks/useSockets';
import {
  GetMemoDto,
  GetSubjectThreadStatsDto as SubjectStatsDto,
  GetThreadDto,
  GetThreadsStatsDto,
} from './snrwbCore/autogenerated/snrwbApiClient';
import {
  firstSubject,
  sameStats,
  setThreadAsRead,
  subjectMatch,
  SubjectType,
  ThreadsFilterEnum,
  unreadThread,
} from './snrwbCore/contexts/MemoContext';
import { ThreadChangedArgs } from './snrwbCore/sockets/socket.datatypes';

type MCT = 'initial' | 'more' | 'current';
const initialCount = 6;
const someMore = 3;
const evalMemosCount = (type: MCT, current: number) => {
  return type === 'initial'
    ? initialCount
    : current + (type === 'more' ? someMore : 0);
};

export interface MemosProviderType {
  threads: GetThreadDto[];
  threadsFilter: ThreadsFilterEnum;
  threadsStats: GetThreadsStatsDto | undefined;
  subjectThreadStats: SubjectStatsDto | undefined;
  threadMemos: GetMemoDto[];
  refreshThreads: () => Promise<void>;
  refreshSubjectThreadStats: (
    subject: SubjectType,
    action?: (stats: SubjectStatsDto) => void,
  ) => void;
  refreshMemos: (threadId: string, count: MCT, action?: () => void) => void;
  loadMoreThreads: () => void;
  setThreadsFilter: (value: ThreadsFilterEnum) => void;
  resetThreads: () => void;
  subscribeListening: (threadId: string) => void;
  unsubscribeListening: () => void;
}

const emptyHandler = async () => {
  // typing purpose
};

const memosProviderContext: MemosProviderType = {
  threads: [],
  threadsFilter: ThreadsFilterEnum.all,
  threadsStats: undefined,
  subjectThreadStats: undefined,
  threadMemos: [],
  refreshThreads: emptyHandler,
  refreshSubjectThreadStats: emptyHandler,
  refreshMemos: emptyHandler,
  loadMoreThreads: emptyHandler,
  setThreadsFilter: emptyHandler,
  resetThreads: emptyHandler,
  subscribeListening: emptyHandler,
  unsubscribeListening: emptyHandler,
};

export const MemosProviderContext = React.createContext(memosProviderContext);

export const MemosProvider: React.FC = ({ children }) => {
  const snrwb = useSnrwbCore();
  const sockets = useSockets();
  const [threads, setThreads] = useState<GetThreadDto[]>([]);
  const [threadsStats, setThreadsStats] = useState<GetThreadsStatsDto>();
  const [subject, setSubject] = useState<SubjectType>();
  const [subjectStats, setSubjectStats] = useState<SubjectStatsDto>();
  const [threadMemos, setThreadMemos] = useState<GetMemoDto[]>([]);
  const [threadsCount, setThreadsCount] = useState(initialCount);
  const [threadsFilter, setThreadsFilter] = useState(ThreadsFilterEnum.all);
  const mountedRef = useRef(false);
  const refreshingRef = useRef(false);
  const listeningRef = useRef('');
  const soundRef = useRef<Player>();

  const refreshSubjectThreadStats = useCallback(
    (subject: SubjectType, action?: (stats: SubjectStatsDto) => void) => {
      snrwb.memos.getSubjectThreadStats(subject).then(stats => {
        setSubject(subject);
        if (mountedRef.current && !sameStats(stats, subjectStats)) {
          setSubjectStats(stats);
          action && action(stats);
        }
      });
    },
    [snrwb.memos, subjectStats],
  );

  const refreshThreadsStats = useCallback(() => {
    snrwb.memos.getThreadsForUserStats().then(stats => {
      mountedRef.current && setThreadsStats(stats);
    });
  }, [snrwb.memos]);

  const markThreadAsRead = useCallback(
    (threadId: string) => {
      setThreads(setThreadAsRead(threads, threadId));
      refreshThreadsStats();
    },
    [threads, refreshThreadsStats],
  );

  const refreshMemos = useCallback(
    (threadId: string, count: MCT, action?: () => void, sound?: boolean) => {
      if (threadId === 'non-existing') {
        mountedRef.current && setThreadMemos([]);
        action && action();
        return;
      }
      const currentCount = Math.max(initialCount, threadMemos.length);
      const memosCount = evalMemosCount(count, currentCount);
      snrwb.memos.getThreadMemos(threadId, memosCount).then(memos => {
        if (mountedRef.current) {
          setThreadMemos(memos);
          markThreadAsRead(threadId);

          const sub = firstSubject(memos);
          if (subject && subject.id === sub?.id) {
            refreshSubjectThreadStats(subject);
          }

          const someNew = memos.some(m => m.unread && !m.sent);
          soundRef.current?.pingIf((sound || false) && someNew);

          action && action();
        }
      });
    },
    // eslint-disable-next-line prettier/prettier
    [markThreadAsRead, refreshSubjectThreadStats, snrwb.memos, subject, threadMemos.length],
  );

  const ping = (sound: boolean, then: GetThreadDto[], now: GetThreadDto[]) => {
    const potentials = now.filter(t => unreadThread(t) && t.participatedCount);
    for (const thread of potentials) {
      const prev = then.find(th => th.id === thread.id)?.newMessagesCount;
      soundRef.current?.pingIf(sound && (prev || 0) < thread.newMessagesCount);
    }
  };

  const refreshThreads = useCallback(
    async (sound?: boolean, count?: number, filter?: ThreadsFilterEnum) => {
      if (refreshingRef.current || !snrwb.organizationalUnitId) {
        return;
      }
      refreshingRef.current = true;
      refreshThreadsStats();

      const refreshed = await snrwb.memos.getThreadsForUser(
        count === undefined ? threadsCount : count,
        filter === undefined ? threadsFilter : filter,
      );
      mountedRef.current && setThreads(refreshed);
      refreshingRef.current = false;

      ping(sound || false, threads, refreshed);
    },
    // eslint-disable-next-line prettier/prettier
    [snrwb.organizationalUnitId, snrwb.memos, refreshThreadsStats, threadsCount, threadsFilter, threads],
  );

  const resetThreads = () => {
    setThreadsCount(initialCount);
    setThreadsFilter(ThreadsFilterEnum.all);
    refreshThreads(false, initialCount, ThreadsFilterEnum.all);
  };

  useEffect(() => {
    mountedRef.current = true;
    if (!threadsStats) {
      refreshThreads();
    }
    return () => {
      mountedRef.current = false;
    };
  }, [snrwb.memos, refreshThreads, threadsStats]);

  useEffect(() => {
    sockets.subscribeMemos();

    const subjectThread = (args: ThreadChangedArgs, threadId?: string) => {
      return (
        threadId === args.threadId ||
        (threadId === 'non-existing' && subject && subjectMatch(subject, args))
      );
    };

    const handler = (args: ThreadChangedArgs) => {
      const getStats = () => {
        refreshThreads(true);
        if (subject && subjectMatch(subject, args)) {
          refreshSubjectThreadStats(subject);
        }
      };

      if (subjectThread(args, listeningRef.current)) {
        refreshMemos(args.threadId, 'current', getStats, true);
      } else {
        getStats();
      }
    };
    sockets.onThreadChanged(handler);

    return () => {
      sockets.removeOnThreadChanged(handler);
      sockets.unsubscribeMemos();
    };
    // big problem with 160 lines rule here :)
    // eslint-disable-next-line prettier/prettier
  }, [sockets, subjectStats, subject, threads, refreshThreads, refreshSubjectThreadStats, refreshMemos]);

  return (
    <MemosProviderContext.Provider
      value={{
        threads,
        threadsFilter,
        threadsStats,
        subjectThreadStats: subjectStats,
        threadMemos,
        refreshThreads,
        refreshSubjectThreadStats,
        refreshMemos,
        loadMoreThreads: () => {
          setThreadsCount(threadsCount + someMore);
          refreshThreads(false, threadsCount + someMore);
        },
        setThreadsFilter: (value: ThreadsFilterEnum) => {
          setThreadsFilter(value);
          refreshThreads(false, threadsCount, value);
        },
        resetThreads,
        subscribeListening: (thId: string) => (listeningRef.current = thId),
        unsubscribeListening: () => (listeningRef.current = ''),
      }}
    >
      {children}
      <NotificationSound ref={soundRef} />
    </MemosProviderContext.Provider>
  );
};
