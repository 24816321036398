/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSampleDto
 */
export interface CreateUpdateSampleDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSampleDto
     */
    controlSample: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    quantity: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSampleDto
     */
    sampleCommissionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    sampleLeftover: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    examCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    transportCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    storageCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    examCostForInspected: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    transportCostForInspected: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    storageCostForInspected: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSampleDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    sampleExamId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    operateOrganizationalUnitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    batchNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSampleDto
     */
    productionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    productionDateText: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    collectionMethod: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleDto
     */
    comments: string;
}

export function CreateUpdateSampleDtoFromJSON(json: any): CreateUpdateSampleDto {
    return CreateUpdateSampleDtoFromJSONTyped(json, false);
}

export function CreateUpdateSampleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSampleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'controlSample': json['controlSample'],
        'quantity': json['quantity'],
        'sampleCommissionDate': !exists(json, 'sampleCommissionDate') ? undefined : (json['sampleCommissionDate'] === null ? null : new Date(json['sampleCommissionDate'])),
        'sampleLeftover': json['sampleLeftover'],
        'examCostForGoverment': json['examCostForGoverment'],
        'transportCostForGoverment': json['transportCostForGoverment'],
        'storageCostForGoverment': json['storageCostForGoverment'],
        'examCostForInspected': json['examCostForInspected'],
        'transportCostForInspected': json['transportCostForInspected'],
        'storageCostForInspected': json['storageCostForInspected'],
        'approved': json['approved'],
        'sampleExamId': json['sampleExamId'],
        'operateOrganizationalUnitId': json['operateOrganizationalUnitId'],
        'batchNumber': json['batchNumber'],
        'productionDate': !exists(json, 'productionDate') ? undefined : (json['productionDate'] === null ? null : new Date(json['productionDate'])),
        'productionDateText': json['productionDateText'],
        'collectionMethod': json['collectionMethod'],
        'comments': json['comments'],
    };
}

export function CreateUpdateSampleDtoToJSON(value?: CreateUpdateSampleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'controlSample': value.controlSample,
        'quantity': value.quantity,
        'sampleCommissionDate': value.sampleCommissionDate === undefined ? undefined : (value.sampleCommissionDate === null ? null : value.sampleCommissionDate.toISOString()),
        'sampleLeftover': value.sampleLeftover,
        'examCostForGoverment': value.examCostForGoverment,
        'transportCostForGoverment': value.transportCostForGoverment,
        'storageCostForGoverment': value.storageCostForGoverment,
        'examCostForInspected': value.examCostForInspected,
        'transportCostForInspected': value.transportCostForInspected,
        'storageCostForInspected': value.storageCostForInspected,
        'approved': value.approved,
        'sampleExamId': value.sampleExamId,
        'operateOrganizationalUnitId': value.operateOrganizationalUnitId,
        'batchNumber': value.batchNumber,
        'productionDate': value.productionDate === undefined ? undefined : (value.productionDate === null ? null : value.productionDate.toISOString()),
        'productionDateText': value.productionDateText,
        'collectionMethod': value.collectionMethod,
        'comments': value.comments,
    };
}


