/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetProductTypeDto,
    GetProductTypeDtoFromJSON,
    GetProductTypeDtoFromJSONTyped,
    GetProductTypeDtoToJSON,
    GetSampleCollectDto,
    GetSampleCollectDtoFromJSON,
    GetSampleCollectDtoFromJSONTyped,
    GetSampleCollectDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSampleExamExtendedDto
 */
export interface GetSampleExamExtendedDto {
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    id: string;
    /**
     * 
     * @type {GetSampleCollectDto}
     * @memberof GetSampleExamExtendedDto
     */
    sampleCollect: GetSampleCollectDto;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    protocolNumber: string;
    /**
     * 
     * @type {number}
     * @memberof GetSampleExamExtendedDto
     */
    sysNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    fileNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    technicalSpecification: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetSampleExamExtendedDto
     */
    organizationType: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetSampleExamExtendedDto
     */
    protocolPrintMode: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    comments: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleExamExtendedDto
     */
    approved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleExamExtendedDto
     */
    projectPOIiS: boolean;
    /**
     * 
     * @type {GetProductTypeDto}
     * @memberof GetSampleExamExtendedDto
     */
    productType: GetProductTypeDto;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    securedQuantity: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleExamExtendedDto
     */
    expirationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    expirationText: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleExamExtendedDto
     */
    expirationNA: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleExamExtendedDto
     */
    supplyDate?: Date;
    /**
     * 
     * @type {GetOrganizationDto}
     * @memberof GetSampleExamExtendedDto
     */
    supplier: GetOrganizationDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleExamExtendedDto
     */
    supplierNA: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleExamExtendedDto
     */
    supplierUnknown: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleExamExtendedDto
     */
    sampleSignatureDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    refusalReason: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    repairMethod: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    invoiceNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleExamExtendedDto
     */
    invoiceNumberNA: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    reasonNotTakingControlSample: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    regulations: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamExtendedDto
     */
    regulationIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamExtendedDto
     */
    regulationValues: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    additionalInfo: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    findings: string;
    /**
     * 
     * @type {number}
     * @memberof GetSampleExamExtendedDto
     */
    orderNo: number;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleExamExtendedDto
     */
    completionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    witnesses: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleExamExtendedDto
     */
    collectDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetSampleExamExtendedDto
     */
    notApprovedResults: number;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExamExtendedDto
     */
    publications: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleExamExtendedDto
     */
    approvedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleExamExtendedDto
     */
    needVerification?: boolean | null;
}

export function GetSampleExamExtendedDtoFromJSON(json: any): GetSampleExamExtendedDto {
    return GetSampleExamExtendedDtoFromJSONTyped(json, false);
}

export function GetSampleExamExtendedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSampleExamExtendedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sampleCollect': GetSampleCollectDtoFromJSON(json['sampleCollect']),
        'protocolNumber': json['protocolNumber'],
        'sysNumber': json['sysNumber'],
        'fileNumber': json['fileNumber'],
        'technicalSpecification': json['technicalSpecification'],
        'organizationType': GetDictionaryDtoFromJSON(json['organizationType']),
        'protocolPrintMode': GetDictionaryDtoFromJSON(json['protocolPrintMode']),
        'comments': json['comments'],
        'approved': json['approved'],
        'projectPOIiS': json['projectPOIiS'],
        'productType': GetProductTypeDtoFromJSON(json['productType']),
        'securedQuantity': json['securedQuantity'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
        'expirationText': json['expirationText'],
        'expirationNA': json['expirationNA'],
        'supplyDate': !exists(json, 'supplyDate') ? undefined : (new Date(json['supplyDate'])),
        'supplier': GetOrganizationDtoFromJSON(json['supplier']),
        'supplierNA': json['supplierNA'],
        'supplierUnknown': json['supplierUnknown'],
        'sampleSignatureDate': !exists(json, 'sampleSignatureDate') ? undefined : (new Date(json['sampleSignatureDate'])),
        'refusalReason': json['refusalReason'],
        'repairMethod': json['repairMethod'],
        'invoiceNumber': json['invoiceNumber'],
        'invoiceNumberNA': json['invoiceNumberNA'],
        'reasonNotTakingControlSample': json['reasonNotTakingControlSample'],
        'regulations': json['regulations'],
        'regulationIds': json['regulationIds'],
        'regulationValues': json['regulationValues'],
        'additionalInfo': json['additionalInfo'],
        'findings': json['findings'],
        'orderNo': json['orderNo'],
        'completionDate': !exists(json, 'completionDate') ? undefined : (new Date(json['completionDate'])),
        'witnesses': json['witnesses'],
        'collectDate': !exists(json, 'collectDate') ? undefined : (new Date(json['collectDate'])),
        'notApprovedResults': json['notApprovedResults'],
        'publications': json['publications'],
        'approvedDate': !exists(json, 'approvedDate') ? undefined : (json['approvedDate'] === null ? null : new Date(json['approvedDate'])),
        'needVerification': !exists(json, 'needVerification') ? undefined : json['needVerification'],
    };
}

export function GetSampleExamExtendedDtoToJSON(value?: GetSampleExamExtendedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sampleCollect': GetSampleCollectDtoToJSON(value.sampleCollect),
        'protocolNumber': value.protocolNumber,
        'sysNumber': value.sysNumber,
        'fileNumber': value.fileNumber,
        'technicalSpecification': value.technicalSpecification,
        'organizationType': GetDictionaryDtoToJSON(value.organizationType),
        'protocolPrintMode': GetDictionaryDtoToJSON(value.protocolPrintMode),
        'comments': value.comments,
        'approved': value.approved,
        'projectPOIiS': value.projectPOIiS,
        'productType': GetProductTypeDtoToJSON(value.productType),
        'securedQuantity': value.securedQuantity,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
        'expirationText': value.expirationText,
        'expirationNA': value.expirationNA,
        'supplyDate': value.supplyDate === undefined ? undefined : (value.supplyDate.toISOString()),
        'supplier': GetOrganizationDtoToJSON(value.supplier),
        'supplierNA': value.supplierNA,
        'supplierUnknown': value.supplierUnknown,
        'sampleSignatureDate': value.sampleSignatureDate === undefined ? undefined : (value.sampleSignatureDate.toISOString()),
        'refusalReason': value.refusalReason,
        'repairMethod': value.repairMethod,
        'invoiceNumber': value.invoiceNumber,
        'invoiceNumberNA': value.invoiceNumberNA,
        'reasonNotTakingControlSample': value.reasonNotTakingControlSample,
        'regulations': value.regulations,
        'regulationIds': value.regulationIds,
        'regulationValues': value.regulationValues,
        'additionalInfo': value.additionalInfo,
        'findings': value.findings,
        'orderNo': value.orderNo,
        'completionDate': value.completionDate === undefined ? undefined : (value.completionDate.toISOString()),
        'witnesses': value.witnesses,
        'collectDate': value.collectDate === undefined ? undefined : (value.collectDate.toISOString()),
        'notApprovedResults': value.notApprovedResults,
        'publications': value.publications,
        'approvedDate': value.approvedDate === undefined ? undefined : (value.approvedDate === null ? null : value.approvedDate.toISOString()),
        'needVerification': value.needVerification,
    };
}


