/*eslint max-lines-per-function: ["error", 180]*/
import React, { useState, useEffect, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import { GridFilter } from './GridFilter';
import { GridFooter } from './GridFooter';
import {
  GridColumn,
  GridRecord,
  GridGetterFunction,
  GridCountFunction,
} from './GridDataTypes';
import staticGetData from './GridStaticGetData';
import { nvl } from './nvl';
import { GridInnerTable } from './GridInnerTable';
import { GridLocationState } from './GridLocationState';

interface GridProps {
  columns: GridColumn[];
  availablePageSizes?: number[];
  pageSize?: number;
  showFilter?: boolean;
  data?: GridRecord[];
  summaryData?: GridRecord;
  emptyGridMessage?: string;
  hidePagination?: boolean;
  initialOrderColumn?: string;
  initialOrderDirection?: 'ASC' | 'DESC';
  additionalId?: string;
  getData?: GridGetterFunction;
  getDataCount?: GridCountFunction;
  onRowClick?: (key: string) => void;
}

export const Grid: React.FC<GridProps> = props => {
  const history = useHistory<GridLocationState & unknown>();
  const [pageSize, setPageSize] = useState(nvl(props.pageSize, 10));
  const [pageNumber, setPageNumber] = useState(
    history.location.state?.page || 1,
  );

  const [pageData, setPageData] = useState<GridRecord[]>();
  const [orderBy, setOrderBy] = useState(
    history.location.state?.sort || props.initialOrderColumn,
  );
  const [orderDirection, setOrderDirection] = useState<'ASC' | 'DESC'>(
    history.location.state?.sortDirection ||
      nvl(props.initialOrderDirection, 'ASC'),
  );
  const [totalPages, setTotalPages] = useState<number>();
  const [filterText, setFilterText] = useState<string>(
    history.location.state?.filterText || '',
  );
  const [filtering, setFiltering] = useState(false);
  const [getData, getDataCount] = [props.getData, props.getDataCount];

  useEffect(() => {
    setPageNumber(history.location.state?.page || 1);
    setFilterText(history.location.state?.filterText || '');
    setOrderBy(history.location.state?.sort || props.initialOrderColumn);
    setOrderDirection(
      history.location.state?.sortDirection ||
        nvl(props.initialOrderDirection, 'ASC'),
    );
  }, [
    history.location.state,
    props.initialOrderColumn,
    props.initialOrderDirection,
  ]);

  const filter = (text: string) => {
    setPageNumber(1);
    setFilterText(text);
    history.push(history.location.pathname, {
      ...history.location.state,
      filterText: text || '',
      page: 1,
    });
  };

  const countAllRows = useCallback(async () => {
    const pd = props.data;
    if (pd) {
      return staticGetData(pd, pd.length, 1, undefined, undefined, filterText)
        .length;
    }

    if (!getDataCount) {
      throw new Error('No count method!');
    }

    return await getDataCount(filterText);
  }, [filterText, getDataCount, props.data]);

  useEffect(() => {
    let mounted = true;
    const order = orderBy || props.initialOrderColumn;

    if (getData) {
      setFiltering(true);
      if (props.additionalId) {
        getData(
          pageSize,
          pageNumber,
          order,
          orderDirection,
          filterText,
          props.additionalId,
        ).then(rows => {
          if (mounted) {
            // getData should return pageSize + 1 records if they exists
            const reachedTheEnd = rows.length <= pageSize;
            setTotalPages(reachedTheEnd ? pageNumber : undefined);
            setPageData(rows.slice(0, pageSize));
            setFiltering(false);
          }
        });
      } else {
        getData(pageSize, pageNumber, order, orderDirection, filterText).then(
          rows => {
            if (mounted) {
              // getData should return pageSize + 1 records if they exists
              const reachedTheEnd = rows.length <= pageSize;
              setTotalPages(reachedTheEnd ? pageNumber : undefined);
              setPageData(rows.slice(0, pageSize));
              setFiltering(false);
            }
          },
        );
      }
    } else if (props.data) {
      const rows = staticGetData(
        props.data,
        pageSize,
        pageNumber,
        order,
        orderDirection,
        filterText,
      );

      setPageData(rows);
      countAllRows().then(rowsCount => {
        if (mounted) {
          setTotalPages(Math.ceil(rowsCount / pageSize));
        }
      });
    } else {
      throw new Error('Either set data or getData method!');
    }

    return () => {
      mounted = false;
    };
  }, [
    getData,
    props.data,
    props.initialOrderColumn,
    props.additionalId,
    pageNumber,
    pageSize,
    orderBy,
    orderDirection,
    filterText,
    countAllRows,
  ]);

  return (
    <>
      {props.showFilter && (
        <div className="d-flex flex-row-reverse">
          <GridFilter
            defaultText={filterText}
            filter={filter}
            indicator={filtering}
          />
        </div>
      )}
      {!pageData ? (
        <Skeleton count={10} />
      ) : (
        <>
          <GridInnerTable
            columns={props.columns}
            pageData={pageData}
            orderBy={orderBy}
            orderDirection={orderDirection}
            summaryData={props.summaryData}
            setOrderBy={setOrderBy}
            setOrderDirection={setOrderDirection}
            onRowClick={props.onRowClick}
          />
          {pageData.length === 0 && (
            <Alert variant="warning">
              {nvl(props.emptyGridMessage, 'Brak danych do pokazania')}
            </Alert>
          )}
          <GridFooter
            availablePageSizes={props.availablePageSizes}
            hidePagination={props.hidePagination}
            pageData={pageData}
            pageNumber={pageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            countAllRows={countAllRows}
            setPageSize={setPageSize}
          />
        </>
      )}
    </>
  );
};
