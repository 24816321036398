import React from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TabPane } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import {
  GetAttachmentDto,
  GetInspectedProductDto,
  GetMisstatementDto,
  GetCorrectiveActionDto,
  GetJudgmentDto,
  CreateUpdateAttachmentDto,
  CreateUpdateInspectedProductDto,
  CreateUpdateInspectedProductDtoFromJSON,
  CreateUpdateProductTypeDto,
  CreateUpdateMisstatementDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateJudgmentDto,
  ValidationStatus,
  GetProceedingDto,
  GetPakDto,
  CreateUpdateSampleCollectDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as ProtocolDuringInspectionContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import { ProductTypeEdit } from '../ProductTypes/ProductTypeEdit';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import RichTextEdit from '../../../app/components/RichTextEdit';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';
import { AddMisstatement } from '../Misstatements/AddMisstatement';
import { Misstatement } from '../Misstatements/Misstatement';
import { CorrectiveAction } from '../CorrectiveActions/CorrectiveAction';
import { Judgment } from '../Judgments/Judgment';
import { ProtocolDuringInspection } from '../Protocols/ProtocolDuringInspection';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import { InspectedProductDetails } from './InspectedProductDetails';
import { InspectedProductInExam } from './InspectedProductInExam';
import { InspectedProductInProceeding } from './InspectedProductInProceeding';

export const InspectedProduct: React.FC<{
  approvedInspection: boolean;
  checkingInspection: boolean;
  editAuthorized: boolean;
  product: GetInspectedProductDto;
  bannerId?: string;
  proceeding?: GetProceedingDto;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  misstatements: AssociateDocuments<
    GetMisstatementDto,
    CreateUpdateMisstatementDto
  >;
  correctiveActions: AssociateDocuments<
    GetCorrectiveActionDto,
    CreateUpdateCorrectiveActionDto
  >;
  judgments: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  protocols: AssociateDocuments<
    ProtocolDuringInspectionContext.ProtocolWithAttachments,
    ProtocolDuringInspectionContext.CreateUpdateProtocolWithAttachments
  >;
  pak?: GetPakDto;
  onChange: (product: CreateUpdateInspectedProductDto) => void;
  changeType: (productType: CreateUpdateProductTypeDto) => void;
  mayProductTypeBeApproved: () => Promise<ValidationStatus>;
  approveProductType: (status: ValidationStatus) => void;
  revertApproveProductType: () => void;
  createExam: (
    dto: CreateUpdateSampleCollectDto,
    inspectedProductId: string,
  ) => void;
  addProceeding: () => void;
  addPak: () => void;
  onBannerClosed: () => void;
  refresh: () => void;
}> = props => {
  const changeProduct = (obj: Partial<CreateUpdateInspectedProductDto>) => {
    const dto = CreateUpdateInspectedProductDtoFromJSON({
      ...props.product,
      ...obj,
    });
    props.onChange(dto);
  };
  const setExam = (sampleExamId: string) => {
    const dto = CreateUpdateInspectedProductDtoFromJSON({
      ...props.product,
      sampleExamId: sampleExamId,
    });
    props.onChange(dto);
  };
  const clearExam = () => {
    const dto = CreateUpdateInspectedProductDtoFromJSON({
      ...props.product,
      sampleExamId: null,
    });
    props.onChange(dto);
  };

  const readonly = !props.editAuthorized || props.approvedInspection;

  return (
    <Card>
      <Tabs defaultActiveKey="details" id="product" className="mb-3">
        <Tab eventKey="details" title="Szczegóły" className="m-3">
          <InspectedProductDetails
            readonly={readonly}
            checkingInspection={props.checkingInspection}
            product={props.product}
            onChange={props.onChange}
          />
          <Row className="mt-3">
            <Col>
              <AssociatedDocuments
                name="Działania naprawcze"
                readonly={!props.editAuthorized}
                createModalType={CorrectiveAction}
                presentModalType={CorrectiveAction}
                docs={props.correctiveActions}
                initialBannerId={props.bannerId}
                onBannerClosed={props.onBannerClosed}
                data={JSON.stringify({
                  firstLevelShortname: 'kontrola',
                  deleteRole: Role.InspectionsEdit,
                })}
              />
            </Col>
            <Col>
              <AssociatedDocuments
                name="Załączniki"
                readonly={readonly}
                createModalType={AddAttachment}
                presentModalType={Attachment}
                docs={props.attachments}
              />
            </Col>
            <Col>
              <AssociatedDocuments
                name="Orzeczenia"
                readonly={readonly}
                createModalType={Judgment}
                presentModalType={Judgment}
                docs={props.judgments}
                initialBannerId={props.bannerId}
                onBannerClosed={props.onBannerClosed}
                data={JSON.stringify({
                  firstLevelShortname: 'kontrolne',
                  showAppeal: props.editAuthorized,
                  deleteRole: Role.InspectionsEdit,
                })}
              />
            </Col>
            <Col>
              <AssociatedDocuments
                name="Protokoły"
                readonly={readonly}
                createModalType={ProtocolDuringInspection}
                presentModalType={ProtocolDuringInspection}
                docs={props.protocols}
                initialBannerId={props.bannerId}
                onBannerClosed={props.onBannerClosed}
                data={JSON.stringify({
                  quantity: props.product.quantity,
                })}
              />
            </Col>
            <Col>
              <AssociatedDocuments
                name="Nieprawidłowości"
                readonly={readonly}
                createModalType={AddMisstatement}
                presentModalType={Misstatement}
                docs={props.misstatements}
                initialBannerId={props.bannerId}
                onBannerClosed={props.onBannerClosed}
              />
            </Col>
          </Row>
        </Tab>
        <TabPane
          eventKey="type"
          title="Typ wyrobu"
          className="m-3"
          mountOnEnter={false}
          unmountOnExit={false}
        >
          <ProductTypeEdit
            readonly={readonly}
            productType={props.product.productType}
            onChange={props.changeType}
            onApprove={props.approveProductType}
            onRevertApprove={props.revertApproveProductType}
            mayBeApproved={props.mayProductTypeBeApproved}
            refresh={props.refresh}
          />
        </TabPane>
        <Tab eventKey="findings" title="Ustalenia" className="m-3">
          <RichTextEdit
            value={props.product.findings}
            readonly={readonly}
            onChange={value => changeProduct({ findings: value })}
          />
        </Tab>
        <Tab eventKey="sampleExam" title="Badanie" className="m-3">
          <InspectedProductInExam
            inspectedProduct={props.product}
            readonly={!props.editAuthorized}
            createExam={props.createExam}
            setExam={setExam}
            clearExam={clearExam}
          />
        </Tab>
        <Tab eventKey="proceeding" title="Postępowanie" className="m-3">
          <InspectedProductInProceeding
            inspectedProduct={props.product}
            pak={props.pak}
            proceeding={props.proceeding}
            readonly={!props.editAuthorized}
            addProceeding={props.addProceeding}
            addPak={props.addPak}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
