/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateInspectorDto
 */
export interface CreateUpdateInspectorDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectorDto
     */
    authorization: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateInspectorDto
     */
    authorizationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectorDto
     */
    inspectionId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectorDto
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectorDto
     */
    sampleExamId: string;
}

export function CreateUpdateInspectorDtoFromJSON(json: any): CreateUpdateInspectorDto {
    return CreateUpdateInspectorDtoFromJSONTyped(json, false);
}

export function CreateUpdateInspectorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInspectorDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorization': json['authorization'],
        'authorizationDate': !exists(json, 'authorizationDate') ? undefined : (json['authorizationDate'] === null ? null : new Date(json['authorizationDate'])),
        'inspectionId': json['inspectionId'],
        'employeeId': json['employeeId'],
        'sampleExamId': json['sampleExamId'],
    };
}

export function CreateUpdateInspectorDtoToJSON(value?: CreateUpdateInspectorDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorization': value.authorization,
        'authorizationDate': value.authorizationDate === undefined ? undefined : (value.authorizationDate === null ? null : value.authorizationDate.toISOString()),
        'inspectionId': value.inspectionId,
        'employeeId': value.employeeId,
        'sampleExamId': value.sampleExamId,
    };
}


