/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionPlanForInspectedProductDetails
 */
export interface InspectionPlanForInspectedProductDetails {
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForInspectedProductDetails
     */
    inspProdPlannedCount: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForInspectedProductDetails
     */
    inspProdPlannedCPRCount: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForInspectedProductDetails
     */
    inspPlanProductCount: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForInspectedProductDetails
     */
    inspPlanProductCPRCount: number;
}

export function InspectionPlanForInspectedProductDetailsFromJSON(json: any): InspectionPlanForInspectedProductDetails {
    return InspectionPlanForInspectedProductDetailsFromJSONTyped(json, false);
}

export function InspectionPlanForInspectedProductDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionPlanForInspectedProductDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspProdPlannedCount': json['inspProdPlannedCount'],
        'inspProdPlannedCPRCount': json['inspProdPlannedCPRCount'],
        'inspPlanProductCount': json['inspPlanProductCount'],
        'inspPlanProductCPRCount': json['inspPlanProductCPRCount'],
    };
}

export function InspectionPlanForInspectedProductDetailsToJSON(value?: InspectionPlanForInspectedProductDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspProdPlannedCount': value.inspProdPlannedCount,
        'inspProdPlannedCPRCount': value.inspProdPlannedCPRCount,
        'inspPlanProductCount': value.inspPlanProductCount,
        'inspPlanProductCPRCount': value.inspPlanProductCPRCount,
    };
}


