/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetInspectedProductDto,
    GetInspectedProductDtoFromJSON,
    GetInspectedProductDtoFromJSONTyped,
    GetInspectedProductDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetProceedingDto,
    GetProceedingDtoFromJSON,
    GetProceedingDtoFromJSONTyped,
    GetProceedingDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProceedingExtendedDto
 */
export interface GetProceedingExtendedDto {
    /**
     * 
     * @type {GetInspectedProductDto}
     * @memberof GetProceedingExtendedDto
     */
    inspectedProduct: GetInspectedProductDto;
    /**
     * 
     * @type {GetProceedingDto}
     * @memberof GetProceedingExtendedDto
     */
    proceeding: GetProceedingDto;
    /**
     * 
     * @type {GetInspectedProductDto}
     * @memberof GetProceedingExtendedDto
     */
    checkingProduct: GetInspectedProductDto;
    /**
     * 
     * @type {Array<GetOrganizationDto>}
     * @memberof GetProceedingExtendedDto
     */
    parties: Array<GetOrganizationDto>;
}

export function GetProceedingExtendedDtoFromJSON(json: any): GetProceedingExtendedDto {
    return GetProceedingExtendedDtoFromJSONTyped(json, false);
}

export function GetProceedingExtendedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProceedingExtendedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectedProduct': GetInspectedProductDtoFromJSON(json['inspectedProduct']),
        'proceeding': GetProceedingDtoFromJSON(json['proceeding']),
        'checkingProduct': GetInspectedProductDtoFromJSON(json['checkingProduct']),
        'parties': ((json['parties'] as Array<any>).map(GetOrganizationDtoFromJSON)),
    };
}

export function GetProceedingExtendedDtoToJSON(value?: GetProceedingExtendedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectedProduct': GetInspectedProductDtoToJSON(value.inspectedProduct),
        'proceeding': GetProceedingDtoToJSON(value.proceeding),
        'checkingProduct': GetInspectedProductDtoToJSON(value.checkingProduct),
        'parties': ((value.parties as Array<any>).map(GetOrganizationDtoToJSON)),
    };
}


