import React, { useState, useEffect, useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Skeleton from 'react-loading-skeleton';
import * as Icon from 'react-bootstrap-icons';

import {
  GetSampleExamDto,
  GetInspectedProductDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { useNavigation } from '../../../common/navigation';
import { AddInspection } from '../Inspections/AddInspection';
import { SampleExamApi } from '../../../common/snrwbCore/contexts/SampleExamContext';
import oneliner from '../../../common/oneliner';
import { SubjectMemos } from '../Memos/SubjectMemos';
import nkitwDesc from '../../../common/nkitwDesc';

export const SampleExamHeader = (props: {
  editAuthorized: boolean;
  sampleExam: GetSampleExamDto;
  inspectedProducts: GetInspectedProductDto[];
  api: SampleExamApi;
}) => {
  const [header, setHeader] = useState('Badanie próbek');
  const [inspectionButton, setInspectionButton] = useState('Nowa kontrola');
  const nav = useNavigation();

  const getInspectedProduct = useCallback(() => {
    if (props.inspectedProducts && props.inspectedProducts.length === 1) {
      return props.inspectedProducts[0];
    }
    return undefined;
  }, [props.inspectedProducts]);

  const goToInspection = () => {
    const product = getInspectedProduct();
    if (product) {
      nav.inspection(product.inspection.id);
      return;
    }
  };

  useEffect(() => {
    const productDescription =
      props.sampleExam &&
      props.sampleExam.productType &&
      oneliner(nkitwDesc(props.sampleExam.productType), 60);
    setHeader(
      `Badanie próbki dla ${productDescription} nr ${props.sampleExam.sysNumber}`,
    );
  }, [props.sampleExam]);

  useEffect(() => {
    const product = getInspectedProduct();
    if (product) {
      setInspectionButton(
        `Kontrola nr ${product.inspection.metric.fileNumber}`,
      );
    }
  }, [getInspectedProduct]);

  if (!props.sampleExam.id) {
    return <Skeleton className="mb-3" />;
  }

  return (
    <div className="d-flex mb-2">
      <Alert variant="primary" className="pt-2 pb-2 mb-0 me-1 flex-fill">
        <Icon.Search /> {header}
      </Alert>
      <Alert
        variant={props.sampleExam?.approved ? 'success' : 'danger'}
        className="pt-2 pb-2 mb-0 me-1"
      >
        {props.sampleExam?.approved
          ? 'Podpisany protokół'
          : 'Protokół pobrania próbek nie został jeszcze podpisany'}
      </Alert>
      <div className="me-1">
        <SubjectMemos subject={props.sampleExam} />
      </div>
      {getInspectedProduct() ? (
        <Button
          variant="outline-primary"
          onClick={goToInspection}
          className="float-end"
        >
          {inspectionButton}
        </Button>
      ) : (
        props.editAuthorized && (
          <AddInspection
            onCompleted={(organization, metric) =>
              props.api.addInspection(organization, metric, props.sampleExam.id)
            }
            inspectionPlace={props.sampleExam.sampleCollect.collectPlace}
            organization={props.sampleExam.sampleCollect.organization}
          />
        )
      )}
    </div>
  );
};
