import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import momencik from '../../../common/momencik';
import { GetObjectHistoryDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { FormRow } from '../../../app/components/FormRow';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

import { ObjectHistoryDetailsForOrganization } from './ObjectHistoryDetailsForOrganization';
import { ObjectHistoryDetailsForProductType } from './ObjectHistoryDetailsForProductType';
import { getCategoryText } from './ObjectHistoryUtils';
import { ObjectHistoryDetailsForCertificate } from './ObjectHistoryDetailsForCertificate';

interface ObjectHistoryDetailsProps {
  objectHistory?: GetObjectHistoryDto;
  show?: boolean;
  detailsType: string;
  handleClose?: () => void;
}

function getObjectHeaderText(category: string): string {
  let text = '';
  switch (category) {
    case '0':
      text = 'Dane stworzonego obiektu';
      break;
    case '1':
      text = 'Zmiany w obiekcie';
      break;
    case '2':
      text = 'Dane usuniętego obiektu';
      break;
    case '3':
      text = 'Obiekt po sklejeniu';
      break;
    default:
      text = 'Nieznane zdarzenie obiektu';
  }
  return text;
}

export const ObjectHistoryDetails: React.FC<ObjectHistoryDetailsProps> =
  props => {
    const [show, setShow] = useState(props.show);
    const [objectHistory, setObjectHistory] = useState(props.objectHistory);
    const [prevEvent, setPrevEvent] = useState<GetObjectHistoryDto>();
    const [ts, setTs] = useState<string>();
    const [user, setUser] = useState<string>();
    const [categoryText, setCategoryText] = useState<string>();
    const [objectHeaderText, setObjectHeaderText] = useState<string>();

    const snrwb = useSnrwbCore();

    useEffect(() => {
      setPrevEvent(undefined);
      setShow(props.show);
      setObjectHistory(props.objectHistory);
      const event = Object(props.objectHistory?.eventJson);
      if (event) {
        setTs(momencik(event.ts, 'LLLL'));
        setUser(event.user?.value);
        setCategoryText(getCategoryText(event.category));
        setObjectHeaderText(getObjectHeaderText(event.category));
      }
    }, [props.show, show, props.objectHistory, objectHistory?.eventJson]);

    useEffect(() => {
      if (objectHistory?.id) {
        snrwb.objectHistory.getPreviousEvent(objectHistory?.id).then(events => {
          if (events.length > 0) {
            setPrevEvent(events[0]);
          }
        });
      }
    }, [snrwb, objectHistory]);

    return (
      <>
        <Modal
          onHide={props.handleClose}
          show={show}
          size="lg"
          centered={true}
          keyboard={true}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Szczegóły zdarzenia: {categoryText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="mb-2">
              <Card.Header>Informacje o zdarzeniu</Card.Header>
              <Card.Body>
                <Form className="d-grid gap-3">
                  <FormRow controlId="ts" label="Czas">
                    <Form.Control
                      readOnly={true}
                      type="text"
                      value={ts || ''}
                    />
                  </FormRow>
                  <FormRow controlId="user" label="Użytkownik">
                    <Form.Control
                      readOnly={true}
                      type="text"
                      value={user || ''}
                    />
                  </FormRow>
                  <FormRow controlId="type" label="Typ zdarzenia">
                    <Form.Control
                      readOnly={true}
                      type="text"
                      value={categoryText || ''}
                    />
                  </FormRow>
                </Form>
              </Card.Body>
            </Card>
            <Card className="mb-2">
              <Card.Header>{objectHeaderText}</Card.Header>
              <Card.Body>
                {props.detailsType ===
                  'ObjectHistoryDetailsForOrganization' && (
                  <ObjectHistoryDetailsForOrganization
                    value={Object(objectHistory?.eventJson).entity}
                    prevValue={Object(prevEvent?.eventJson).entity}
                  />
                )}
                {props.detailsType === 'ObjectHistoryDetailsForProductType' && (
                  <ObjectHistoryDetailsForProductType
                    value={Object(objectHistory?.eventJson).entity}
                    prevValue={Object(prevEvent?.eventJson).entity}
                  />
                )}
                {props.detailsType === 'ObjectHistoryDetailsForCertificate' && (
                  <ObjectHistoryDetailsForCertificate
                    value={Object(objectHistory?.eventJson).entity}
                    prevValue={Object(prevEvent?.eventJson).entity}
                  />
                )}
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.handleClose}>
              Zamknij
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
