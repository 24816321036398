/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateCustomsFeedbackDto
 */
export interface CreateUpdateCustomsFeedbackDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    number: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    receiptDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    usage: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    constructionProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    positive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    releaseDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    customsAuthority: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    importer: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    organizationalUnitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomsFeedbackDto
     */
    producerId: string;
}

export function CreateUpdateCustomsFeedbackDtoFromJSON(json: any): CreateUpdateCustomsFeedbackDto {
    return CreateUpdateCustomsFeedbackDtoFromJSONTyped(json, false);
}

export function CreateUpdateCustomsFeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateCustomsFeedbackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'receiptDate': !exists(json, 'receiptDate') ? undefined : (json['receiptDate'] === null ? null : new Date(json['receiptDate'])),
        'name': json['name'],
        'usage': json['usage'],
        'constructionProduct': !exists(json, 'constructionProduct') ? undefined : json['constructionProduct'],
        'positive': !exists(json, 'positive') ? undefined : json['positive'],
        'releaseDate': !exists(json, 'releaseDate') ? undefined : (json['releaseDate'] === null ? null : new Date(json['releaseDate'])),
        'customsAuthority': json['customsAuthority'],
        'importer': json['importer'],
        'organizationalUnitId': json['organizationalUnitId'],
        'producerId': json['producerId'],
    };
}

export function CreateUpdateCustomsFeedbackDtoToJSON(value?: CreateUpdateCustomsFeedbackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'receiptDate': value.receiptDate === undefined ? undefined : (value.receiptDate === null ? null : value.receiptDate.toISOString()),
        'name': value.name,
        'usage': value.usage,
        'constructionProduct': value.constructionProduct,
        'positive': value.positive,
        'releaseDate': value.releaseDate === undefined ? undefined : (value.releaseDate === null ? null : value.releaseDate.toISOString()),
        'customsAuthority': value.customsAuthority,
        'importer': value.importer,
        'organizationalUnitId': value.organizationalUnitId,
        'producerId': value.producerId,
    };
}


