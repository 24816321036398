import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';

import { AssociateDocumentModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import {
  CreateUpdateMisstatementDto,
  GetDictionaryDto,
  GetMisstatementDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const Misstatement = (
  props: AssociateDocumentModalProps<
    GetMisstatementDto,
    CreateUpdateMisstatementDto
  >,
) => {
  if (!props.document) {
    return <></>;
  }

  const tree: GetDictionaryDto[] = [];
  let node = props.document.variance;
  while (node) {
    tree.unshift(node);
    node = node.parent;
  }
  const root = tree.shift();

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {root ? (
            <>
              <Badge bg="warning" text="dark">
                {root.shortname}
              </Badge>{' '}
              {root.value}
            </>
          ) : (
            'Nieprawidłowość'
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tree.map(dict => (
          <p key={dict.id}>
            <Badge bg="warning" text="dark">
              {dict.shortname}
            </Badge>{' '}
            {dict.value}
          </p>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        {!props.readonly && (
          <ConfirmationButton
            variant="outline-danger"
            roleAnyOf={[Role.InspectionsEdit]}
            onOK={() => props.onDelete(props.document.id)}
            confirmation="Czy na pewno usunąć nieprawidłowość?"
          >
            Usuń
          </ConfirmationButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};
