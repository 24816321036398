/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
    GetSampleExamDto,
    GetSampleExamDtoFromJSON,
    GetSampleExamDtoFromJSONTyped,
    GetSampleExamDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSampleDto
 */
export interface GetSampleDto {
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleDto
     */
    controlSample: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    quantity: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleDto
     */
    sampleCommissionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    sampleLeftover: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    examCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    transportCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    storageCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    examCostForInspected: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    transportCostForInspected: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    storageCostForInspected: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetSampleExamDto}
     * @memberof GetSampleDto
     */
    sampleExam: GetSampleExamDto;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetSampleDto
     */
    operateOrganizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    batchNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleDto
     */
    productionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    productionDateText: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    collectionMethod: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleDto
     */
    comments: string;
}

export function GetSampleDtoFromJSON(json: any): GetSampleDto {
    return GetSampleDtoFromJSONTyped(json, false);
}

export function GetSampleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSampleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'controlSample': json['controlSample'],
        'quantity': json['quantity'],
        'sampleCommissionDate': !exists(json, 'sampleCommissionDate') ? undefined : (new Date(json['sampleCommissionDate'])),
        'sampleLeftover': json['sampleLeftover'],
        'examCostForGoverment': json['examCostForGoverment'],
        'transportCostForGoverment': json['transportCostForGoverment'],
        'storageCostForGoverment': json['storageCostForGoverment'],
        'examCostForInspected': json['examCostForInspected'],
        'transportCostForInspected': json['transportCostForInspected'],
        'storageCostForInspected': json['storageCostForInspected'],
        'approved': json['approved'],
        'sampleExam': GetSampleExamDtoFromJSON(json['sampleExam']),
        'operateOrganizationalUnit': GetOrganizationalUnitDtoFromJSON(json['operateOrganizationalUnit']),
        'batchNumber': json['batchNumber'],
        'productionDate': !exists(json, 'productionDate') ? undefined : (new Date(json['productionDate'])),
        'productionDateText': json['productionDateText'],
        'collectionMethod': json['collectionMethod'],
        'comments': json['comments'],
    };
}

export function GetSampleDtoToJSON(value?: GetSampleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'controlSample': value.controlSample,
        'quantity': value.quantity,
        'sampleCommissionDate': value.sampleCommissionDate === undefined ? undefined : (value.sampleCommissionDate.toISOString()),
        'sampleLeftover': value.sampleLeftover,
        'examCostForGoverment': value.examCostForGoverment,
        'transportCostForGoverment': value.transportCostForGoverment,
        'storageCostForGoverment': value.storageCostForGoverment,
        'examCostForInspected': value.examCostForInspected,
        'transportCostForInspected': value.transportCostForInspected,
        'storageCostForInspected': value.storageCostForInspected,
        'approved': value.approved,
        'sampleExam': GetSampleExamDtoToJSON(value.sampleExam),
        'operateOrganizationalUnit': GetOrganizationalUnitDtoToJSON(value.operateOrganizationalUnit),
        'batchNumber': value.batchNumber,
        'productionDate': value.productionDate === undefined ? undefined : (value.productionDate.toISOString()),
        'productionDateText': value.productionDateText,
        'collectionMethod': value.collectionMethod,
        'comments': value.comments,
    };
}


