import React from 'react';
import { Table } from 'react-bootstrap';

import {
  ProceedingDetails,
  ReportProceedingType,
} from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportProceedingType;
  reportName: string;
  headerName: string[];
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportByProceeding: React.FC<Props> = ({
  data,
  reportName,
  headerName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = Object.keys(data.details).length > 0;

  const cols = ['art. 16 ust. 1a', 'art. 32a', 'KP'];

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={20}>{reportName}</th>
            </tr>
            <tr>
              <th rowSpan={3}>Organ</th>
              <th colSpan={4}>{headerName[0]}</th>
              <th colSpan={4}>{headerName[1]}</th>
              <th colSpan={4}>{headerName[2]}</th>
              <th colSpan={4}>{headerName[3]}</th>
            </tr>
            <tr>
              <th>{'>RS'}</th>
              <th colSpan={2}>RS</th>
              <th rowSpan={2}>O</th>
              <th>{'>RS'}</th>
              <th colSpan={2}>RS</th>
              <th rowSpan={2}>O</th>
              <th>{'>RS'}</th>
              <th colSpan={2}>RS</th>
              <th rowSpan={2}>O</th>
              <th>{'>RS'}</th>
              <th colSpan={2}>RS</th>
              <th rowSpan={2}>O</th>
            </tr>
            <tr>
              <th colSpan={2}>ZRS</th>
              <th>TRS</th>
              <th colSpan={2}>ZRS</th>
              <th>TRS</th>
              <th colSpan={2}>ZRS</th>
              <th>TRS</th>
              <th colSpan={2}>ZRS</th>
              <th>TRS</th>
            </tr>
          </thead>
          {hasData && (
            <tbody>
              {Object.keys(data?.details)
                .sort()
                .map((key, idx) => (
                  <tr key={'data' + idx}>
                    <td>{key}</td>
                    {cols.map((col, idx2) => (
                      <React.Fragment key={'col' + idx2}>
                        <td className="numbers-column">
                          {data?.details?.[key]?.[col]?.countStartBeforeEndIn ||
                            0}
                        </td>
                        <td className="numbers-column">
                          {data?.details?.[key]?.[col]?.countStartInEndIn || 0}
                        </td>
                        <td className="numbers-column">
                          {data?.details?.[key]?.[col]?.countStartInEndAfter ||
                            0}
                        </td>
                        <td className="numbers-column">
                          {data?.details?.[key]?.[col]?.count || 0}
                        </td>
                      </React.Fragment>
                    ))}
                    <SumOrganizationalUnit
                      data={data}
                      orgUnit={key}
                      type={'countStartBeforeEndIn'}
                      cols={cols}
                    />
                    <SumOrganizationalUnit
                      data={data}
                      orgUnit={key}
                      type={'countStartInEndIn'}
                      cols={cols}
                    />
                    <SumOrganizationalUnit
                      data={data}
                      orgUnit={key}
                      type={'countStartInEndAfter'}
                      cols={cols}
                    />
                    <SumOrganizationalUnit
                      data={data}
                      orgUnit={key}
                      type={'count'}
                      cols={cols}
                    />
                  </tr>
                ))}
              <tr>
                <td>Suma:</td>
                {cols.map((col, idx2) => (
                  <React.Fragment key={'sum' + idx2}>
                    <td className="numbers-column">
                      {data?.sum?.[col]?.countStartBeforeEndIn || 0}
                    </td>
                    <td className="numbers-column">
                      {data?.sum?.[col]?.countStartInEndIn || 0}
                    </td>
                    <td className="numbers-column">
                      {data?.sum?.[col]?.countStartInEndAfter || 0}
                    </td>
                    <td className="numbers-column">
                      {data?.sum?.[col]?.count || 0}
                    </td>
                  </React.Fragment>
                ))}
                <SumLine
                  data={data}
                  type={'countStartBeforeEndIn'}
                  cols={cols}
                />
                <SumLine data={data} type={'countStartInEndIn'} cols={cols} />
                <SumLine
                  data={data}
                  type={'countStartInEndAfter'}
                  cols={cols}
                />
                <SumLine data={data} type={'count'} cols={cols} />
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const SumOrganizationalUnit: React.FC<{
  data: ReportProceedingType;
  orgUnit: string;
  type: keyof ProceedingDetails;
  cols: string[];
}> = ({ data, orgUnit, type, cols }) => {
  if (!data || Object.keys(data?.details).length <= 0) {
    return <></>;
  }

  return (
    <td className="numbers-column">
      {(data?.details?.[orgUnit]?.[cols[0]]?.[type] || 0) +
        (data?.details?.[orgUnit]?.[cols[1]]?.[type] || 0) +
        (data?.details?.[orgUnit]?.[cols[2]]?.[type] || 0) +
        (data?.details?.[orgUnit]?.[cols[3]]?.[type] || 0)}
    </td>
  );
};

const SumLine: React.FC<{
  data: ReportProceedingType;
  type: keyof ProceedingDetails;
  cols: string[];
}> = ({ data, type, cols }) => {
  if (!data || Object.keys(data?.details).length <= 0) {
    return <></>;
  }

  return (
    <td className="numbers-column">
      {(data?.sum?.[cols[0]]?.[type] || 0) +
        (data?.sum?.[cols[1]]?.[type] || 0) +
        (data?.sum?.[cols[2]]?.[type] || 0) +
        (data?.sum?.[cols[3]]?.[type] || 0)}
    </td>
  );
};
