import { GetEssentialFeatureDto } from '../autogenerated/snrwbApiClient/models/GetEssentialFeatureDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateEssentialFeatureDto,
  CreateUpdateEssentialFeatureDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models';
import {
  Option,
  defaultSelectorLimit,
} from '../../../snrwb/components/Selects/Selector';
import { validateAgainst } from '../validation/validateAgainst';
import { EssentialFeatureSchema } from '../validation/schemas';

export interface EssentialFeatureContextInterface {
  getById: (id: string) => Promise<GetEssentialFeatureDto>;
  update: (id: string, dto: CreateUpdateEssentialFeatureDto) => Promise<void>;
  create: (
    dto: CreateUpdateEssentialFeatureDto,
  ) => Promise<GetEssentialFeatureDto>;
  delete: (id: string) => Promise<void>;
  getBySampleExam: (sampleExamId: string) => Promise<GetEssentialFeatureDto[]>;
  getFeaturesByPattern: (pattern: string) => Promise<Option[]>;
  getFunctionalPropertiesByPattern: (pattern: string) => Promise<Option[]>;
  getExamMethodsByPattern: (pattern: string) => Promise<Option[]>;
}

export const EssentialFeatureContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.essentialFeatureControllerGet(id),
    update: (id: string, dto: CreateUpdateEssentialFeatureDto) =>
      api.essentialFeatureControllerUpdate(id, dto),
    create: (dto: CreateUpdateEssentialFeatureDto) =>
      api.essentialFeatureControllerCreate(dto),
    delete: (id: string) => api.essentialFeatureControllerDelete(id),
    getBySampleExam: async (sampleExamId: string) => {
      const list = await api.essentialFeatureControllerGetBySampleExam(
        sampleExamId,
      );
      list.sort((ef1, ef2) => ef1.orderNo - ef2.orderNo);
      return list;
    },
    getFeaturesByPattern: async (pattern: string) => {
      const data = await api.essentialFeatureControllerGetFeaturesByPattern(
        pattern,
        defaultSelectorLimit,
      );
      return Array.from(data, feature => {
        return { value: feature, label: feature };
      });
    },
    getFunctionalPropertiesByPattern: async (pattern: string) => {
      const data =
        await api.essentialFeatureControllerGetFunctionalPropertiesByPattern(
          pattern,
          defaultSelectorLimit,
        );
      return Array.from(data, feature => {
        return { value: feature, label: feature };
      });
    },
    getExamMethodsByPattern: async (pattern: string) => {
      const data = await api.essentialFeatureControllerGetExamMethodsByPattern(
        pattern,
        defaultSelectorLimit,
      );
      return Array.from(data, feature => {
        return { value: feature, label: feature };
      });
    },
  };
};

export const toCuDto = (getDto: GetEssentialFeatureDto) => {
  const cuDto = CreateUpdateEssentialFeatureDtoFromJSON(getDto);
  if (getDto.sampleExam) {
    cuDto.sampleExamId = getDto.sampleExam.id;
  }
  return cuDto;
};

export const validate = (dto: CreateUpdateEssentialFeatureDto) =>
  validateAgainst(EssentialFeatureSchema, dto);
