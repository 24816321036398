/* eslint-disable  import/named */
import React, { useState, useEffect, useRef, KeyboardEvent } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import {
  StylesConfig,
  components,
  Options,
  OptionsOrGroups,
  GroupBase,
} from 'react-select';
import Badge from 'react-bootstrap/Badge';
import * as BootstrapTypes from 'react-bootstrap/types';

export interface Option {
  value: string;
  label: string;
  badge?: string;
  new?: boolean;
}

interface ReactSelectOption {
  value: string;
  label: string;
  badge?: string;
  __isNew__?: boolean;
}

type Provider = (text: string) => Promise<Option[]>;

export interface SelectProps {
  uniqueKey?: string;
  className?: string;
  readOnly?: boolean;
  creatable?: boolean;
  clearable?: boolean;
  value?: string;
  valueBadge?: string;
  placeholder?: string;
  hideBadges?: boolean;
  hideLabels?: boolean;
  badgeBg?: string;
  badgeText?: BootstrapTypes.Color;
  noOptionsMessage?: (arg: { inputValue: string }) => React.ReactNode;
  provider?: Provider;
  onChange?: (option: Option) => void;
  isValidNewOption?: (
    inputValue: string,
    value: Options<ReactSelectOption>,
    options: OptionsOrGroups<ReactSelectOption, GroupBase<ReactSelectOption>>,
  ) => boolean;
}

export const defaultSelectorLimit = 10;
type IsMulti = false;
const nvm = null as unknown as string;

const OptionBadge = (props: {
  badge: string | undefined;
  bg: string;
  text: BootstrapTypes.Color;
}) => (
  <>
    {props.badge && (
      <>
        <Badge bg={props.bg} text={props.text}>
          {props.badge}
        </Badge>{' '}
      </>
    )}
  </>
);

export const Selector: React.FC<SelectProps> = props => {
  const [value, setValue] = useState<Option>();
  const selectRef = useRef(null);

  const onChange = (choice: ReactSelectOption | null) => {
    if (!choice) {
      if (props.onChange) {
        props.onChange({ value: nvm, label: nvm, new: true });
      }
      setValue(null as unknown as Option);
      return;
    }

    const value = {
      value: choice.value,
      label: choice.label,
      badge: choice.badge,
      new: choice.__isNew__ || false,
    };

    if (props.onChange) {
      props.onChange(value);
    }
    setValue(value);
  };

  useEffect(() => {
    if (!props.value && !props.valueBadge) {
      setValue(null as unknown as Option);
      return;
    }
    setValue({
      value: props.value || '',
      label: props.value || '',
      badge: props.valueBadge,
    });
  }, [props.value, props.valueBadge]);

  const styling: StylesConfig<ReactSelectOption, IsMulti> = {
    option: (provided, state) => {
      return {
        ...provided,
        ...(state.data.__isNew__ && { color: 'blue' }),
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        ...(state.isDisabled && { backgroundColor: '#e9ecef' }),
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        ...(state.isDisabled && { color: '#212529' }),
      };
    },
    input: provided => {
      return {
        ...provided,
        overflow: 'hidden',
      };
    },
  };

  const tryToSelectNewOptionIfFocused = () => {
    if (
      !selectRef.current ||
      !selectRef.current['state'] ||
      !selectRef.current['state']['focusedOption']
    ) {
      return;
    }

    const option = selectRef.current['state'][
      'focusedOption'
    ] as ReactSelectOption;
    if (option.__isNew__) {
      onChange(option);
    }
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    const input = evt.target as HTMLInputElement;
    const len = input.value.length;

    switch (evt.key) {
      case 'Home':
        evt.preventDefault();
        if (evt.shiftKey) {
          input.selectionStart = 0;
        } else {
          input.setSelectionRange(0, 0);
        }
        break;
      case 'End':
        evt.preventDefault();
        if (evt.shiftKey) {
          input.selectionEnd = len;
        } else {
          input.setSelectionRange(len, len);
        }
        break;
    }
  };

  const bg = props.badgeBg || 'warning';
  const text = props.badgeText || 'dark';
  const showBadges = !props.hideBadges;
  const showLabels = !props.hideLabels;

  return props.creatable ? (
    <AsyncCreatableSelect
      key={props.uniqueKey}
      ref={selectRef}
      cacheOptions
      loadOptions={props.provider}
      defaultOptions
      isClearable={props.clearable}
      placeholder={props.placeholder || 'Szukaj...'}
      className={props.className}
      classNamePrefix="snrwb-react-select"
      onChange={onChange}
      value={value}
      isDisabled={props.readOnly}
      isValidNewOption={props.isValidNewOption}
      formatCreateLabel={text => text}
      styles={styling}
      onKeyDown={handleKeyDown}
      onBlur={tryToSelectNewOptionIfFocused}
    />
  ) : (
    <AsyncSelect
      key={props.uniqueKey}
      cacheOptions
      loadOptions={props.provider}
      defaultOptions
      isClearable={props.clearable}
      className={props.className}
      classNamePrefix="snrwb-react-select"
      placeholder={props.placeholder || 'Szukaj...'}
      onChange={onChange}
      value={value}
      isDisabled={props.readOnly}
      noOptionsMessage={props.noOptionsMessage || (() => 'Brak wartości')}
      styles={styling}
      onKeyDown={handleKeyDown}
      components={{
        SingleValue: props => (
          <components.SingleValue {...props}>
            {showBadges && (
              <OptionBadge badge={props.data.badge} bg={bg} text={text} />
            )}
            {showLabels && props.data.label}
          </components.SingleValue>
        ),
        Option: props => (
          <components.Option {...props} className="snrwb-react-select-option">
            {showBadges && (
              <OptionBadge badge={props.data.badge} bg={bg} text={text} />
            )}
            {showLabels && props.data.label}
          </components.Option>
        ),
      }}
    />
  );
};
