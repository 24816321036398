/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBasedOnProductTypeParams
 */
export interface CreateBasedOnProductTypeParams {
    /**
     * 
     * @type {string}
     * @memberof CreateBasedOnProductTypeParams
     */
    inspectionId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBasedOnProductTypeParams
     */
    productTypeId: string;
}

export function CreateBasedOnProductTypeParamsFromJSON(json: any): CreateBasedOnProductTypeParams {
    return CreateBasedOnProductTypeParamsFromJSONTyped(json, false);
}

export function CreateBasedOnProductTypeParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBasedOnProductTypeParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionId': json['inspectionId'],
        'productTypeId': json['productTypeId'],
    };
}

export function CreateBasedOnProductTypeParamsToJSON(value?: CreateBasedOnProductTypeParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionId': value.inspectionId,
        'productTypeId': value.productTypeId,
    };
}


