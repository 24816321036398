/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateOrganizationDto
 */
export interface CreateUpdateOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    nip: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    addressEx: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    place: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    numbers: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    flat: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    activityTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    activityTypeShort: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrganizationDto
     */
    lastName: string;
}

export function CreateUpdateOrganizationDtoFromJSON(json: any): CreateUpdateOrganizationDto {
    return CreateUpdateOrganizationDtoFromJSONTyped(json, false);
}

export function CreateUpdateOrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateOrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'nip': json['nip'],
        'addressEx': json['addressEx'],
        'postcode': json['postcode'],
        'place': json['place'],
        'street': json['street'],
        'country': json['country'],
        'numbers': json['numbers'],
        'flat': json['flat'],
        'activityTypeId': json['activityTypeId'],
        'activityTypeShort': json['activityTypeShort'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}

export function CreateUpdateOrganizationDtoToJSON(value?: CreateUpdateOrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'nip': value.nip,
        'addressEx': value.addressEx,
        'postcode': value.postcode,
        'place': value.place,
        'street': value.street,
        'country': value.country,
        'numbers': value.numbers,
        'flat': value.flat,
        'activityTypeId': value.activityTypeId,
        'activityTypeShort': value.activityTypeShort,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}


