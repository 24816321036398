import React, { useState, useEffect } from 'react';

import {
  GetInspectionDto,
  GetInspectedProductDto,
  GetAttachmentDto,
  GetMisstatementDto,
  GetCorrectiveActionDto,
  GetJudgmentDto,
  GetInspectorDto,
  CreateUpdateInspectionDto,
  CreateUpdateInspectionDtoFromJSON,
  CreateUpdateAttachmentDto,
  CreateUpdateMisstatementDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateJudgmentDto,
  CreateUpdateInspectorDto,
  GetProceedingDto,
  GetPakDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import * as ProtocolDuringInspectionContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { InspectionApi } from '../../../common/snrwbCore/contexts/InspectionContext';
import momencik from '../../../common/momencik';
import { DataChangeScope } from '../../../common/snrwbCore/sockets/socket.datatypes';
import { ConcurrencySignaler } from '../../../app/components/ConcurrencySignaler';

import { InspectionTabs } from './InspectionTabs';

interface InspectionProps {
  editAuthorized: boolean;
  validOrganizationalUnit: boolean;
  inspection: GetInspectionDto;
  banner?: { inspectedProductId: string; bannerId: string };
  products?: GetInspectedProductDto[];
  proceedings?: GetProceedingDto[];
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  misstatements: AssociateDocuments<
    GetMisstatementDto,
    CreateUpdateMisstatementDto
  >;
  correctiveActions: AssociateDocuments<
    GetCorrectiveActionDto,
    CreateUpdateCorrectiveActionDto
  >;
  judgments: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  protocols: AssociateDocuments<
    ProtocolDuringInspectionContext.ProtocolWithAttachments,
    ProtocolDuringInspectionContext.CreateUpdateProtocolWithAttachments
  >;
  inspectors: AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>;
  paks: GetPakDto[];
  activeProduct?: string;
  errors: string[];
  api: InspectionApi;
}

export const Inspection: React.FC<InspectionProps> = props => {
  const [inspectionHeader, setInspectionHeader] = useState('');
  const [banner, setBanner] = useState(props.banner);
  const [key, setKey] = useState({
    scope: DataChangeScope.inspection,
    id: props.inspection.id,
  });
  const [description, setDescription] = useState(
    'Kontrola z numerem akt ' + props.inspection.metric?.fileNumber,
  );

  const changeRichText = (obj: Partial<CreateUpdateInspectionDto>) => {
    const dto = CreateUpdateInspectionDtoFromJSON({
      ...props.inspection,
      ...obj,
    });

    if (dto.attachments) {
      dto.attachments = dto.attachments.replaceAll('>\n', '>');
    }

    if (dto.protocols) {
      dto.protocols = dto.protocols.replaceAll('>\n', '>');
    }
    if (dto.findingsBeginning) {
      dto.findingsBeginning = dto.findingsBeginning.replaceAll('>\n', '>');
    }
    if (dto.findingsEnd) {
      dto.findingsEnd = dto.findingsEnd.replaceAll('>\n', '>');
    }

    props.api.editInspection(props.inspection.id, dto);
  };

  const readonly = props.inspection.approved || !props.editAuthorized;

  useEffect(() => {
    if (props.inspection) {
      if (props.inspection.metric) {
        setInspectionHeader(
          `z numerem akt ${
            props.inspection.metric.fileNumber
          } rozpoczęta ${momencik(props.inspection.metric.startDate)} w ${
            props.inspection.metric.inspectionPlace
          }`,
        );
      }
    }
  }, [props.inspection]);

  useEffect(() => setBanner(props.banner), [props.banner]);

  useEffect(() => {
    setKey({ scope: DataChangeScope.inspection, id: props.inspection.id });
    setDescription(
      'Kontrola z numerem akt ' + props.inspection.metric?.fileNumber,
    );
  }, [props.inspection.id, props.inspection.metric?.fileNumber]);

  return (
    <>
      <InspectionTabs
        {...props}
        readonly={readonly}
        inspectionHeader={inspectionHeader}
        banner={banner}
        setBanner={setBanner}
        changeRichText={changeRichText}
      />
      <ConcurrencySignaler
        dataKey={key}
        objectDescription={description}
        editAllowed={props.editAuthorized}
        refreshAction={props.api.refresh}
      />
    </>
  );
};
