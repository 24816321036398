/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EssentialFutureType,
    EssentialFutureTypeFromJSON,
    EssentialFutureTypeFromJSONTyped,
    EssentialFutureTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportPublicationsType
 */
export interface ReportPublicationsType {
    /**
     * 
     * @type {boolean}
     * @memberof ReportPublicationsType
     */
    controlSample: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    publicationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    operateOrgUnitShortname: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    examOrgUnitShortname: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportPublicationsType
     */
    collectDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    collectPlace: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    groupShortname: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    organization: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    nkitw: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    collectBasis: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    subGroupShortname: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    examCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    transportCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    storageCostForGoverment: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    examCostForInspected: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    transportCostForInspected: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    storageCostForInspected: string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationsType
     */
    technicalSpecification: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportPublicationsType
     */
    negative: boolean;
    /**
     * 
     * @type {Array<EssentialFutureType>}
     * @memberof ReportPublicationsType
     */
    essentialFuture: Array<EssentialFutureType>;
}

export function ReportPublicationsTypeFromJSON(json: any): ReportPublicationsType {
    return ReportPublicationsTypeFromJSONTyped(json, false);
}

export function ReportPublicationsTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportPublicationsType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'controlSample': json['controlSample'],
        'publicationNumber': json['publicationNumber'],
        'operateOrgUnitShortname': json['operateOrgUnitShortname'],
        'examOrgUnitShortname': json['examOrgUnitShortname'],
        'collectDate': (new Date(json['collectDate'])),
        'collectPlace': json['collectPlace'],
        'groupShortname': json['groupShortname'],
        'organization': json['organization'],
        'nkitw': json['nkitw'],
        'description': json['description'],
        'collectBasis': json['collectBasis'],
        'subGroupShortname': json['subGroupShortname'],
        'examCostForGoverment': json['examCostForGoverment'],
        'transportCostForGoverment': json['transportCostForGoverment'],
        'storageCostForGoverment': json['storageCostForGoverment'],
        'examCostForInspected': json['examCostForInspected'],
        'transportCostForInspected': json['transportCostForInspected'],
        'storageCostForInspected': json['storageCostForInspected'],
        'technicalSpecification': json['technicalSpecification'],
        'negative': json['negative'],
        'essentialFuture': ((json['essentialFuture'] as Array<any>).map(EssentialFutureTypeFromJSON)),
    };
}

export function ReportPublicationsTypeToJSON(value?: ReportPublicationsType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'controlSample': value.controlSample,
        'publicationNumber': value.publicationNumber,
        'operateOrgUnitShortname': value.operateOrgUnitShortname,
        'examOrgUnitShortname': value.examOrgUnitShortname,
        'collectDate': (value.collectDate.toISOString()),
        'collectPlace': value.collectPlace,
        'groupShortname': value.groupShortname,
        'organization': value.organization,
        'nkitw': value.nkitw,
        'description': value.description,
        'collectBasis': value.collectBasis,
        'subGroupShortname': value.subGroupShortname,
        'examCostForGoverment': value.examCostForGoverment,
        'transportCostForGoverment': value.transportCostForGoverment,
        'storageCostForGoverment': value.storageCostForGoverment,
        'examCostForInspected': value.examCostForInspected,
        'transportCostForInspected': value.transportCostForInspected,
        'storageCostForInspected': value.storageCostForInspected,
        'technicalSpecification': value.technicalSpecification,
        'negative': value.negative,
        'essentialFuture': ((value.essentialFuture as Array<any>).map(EssentialFutureTypeToJSON)),
    };
}


