/*eslint max-lines-per-function: ["error", 200]*/
import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateAttachmentDto,
  CreateUpdateExamResultDto,
  GetAttachmentDto,
  GetExamResultDto,
  ValidationStatus,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as ExamResultContext from '../../../common/snrwbCore/contexts/ExamResultContext';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';
import momencik from '../../../common/momencik';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import ValidationAlert from '../../../app/components/ValidationAlert';

const ResultForm = (props: {
  value: GetExamResultDto;
  publishAuthorized: boolean;
  attachment: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  changeDescription: (description?: string) => void;
  changeVerification: () => void;
}) => (
  <Form className="d-grid">
    <FormRow
      controlId="description"
      label={props.value.description ? 'Informacja dla WINB' : ''}
      static
    >
      <Row>
        <Col>{props.value.description}</Col>
        {props.value.approved && (
          <Col md={5} className="float-end">
            {props.value.approved && (
              <AssociatedDocuments
                name="Załącznik"
                align="end"
                oneDocumentOnly={true}
                readonly={
                  !props.publishAuthorized ||
                  props.value.publicationDate !== undefined
                }
                createModalType={AddAttachment}
                presentModalType={Attachment}
                docs={props.attachment}
                className="float-end"
              />
            )}
            {!props.value.publicationDate &&
              (props.value.verified ? (
                <ConfirmationButton
                  confirmation="Czy na pewno cofnąć weryfikację?"
                  onOK={props.changeVerification}
                  rolesAllOf={[Role.ExamsPublishing, Role.ExamsEdit]}
                  variant="outline-secondary"
                  className="float-end me-1"
                >
                  Cofnij weryfikację
                </ConfirmationButton>
              ) : (
                <ConfirmationButton
                  confirmation="Czy na pewno próbka została zweryfikowana?"
                  onOK={props.changeVerification}
                  rolesAllOf={[Role.ExamsPublishing, Role.ExamsEdit]}
                  variant="outline-secondary"
                  className="float-end me-1"
                >
                  Zweryfikuj
                </ConfirmationButton>
              ))}
            {props.value.description && (
              <ConfirmationButton
                confirmation="Czy na pewno zmienić informację?"
                onOK={props.changeDescription}
                rolesAllOf={[Role.ExamsPublishing, Role.ExamsEdit]}
                askForAReason
                reasonText="Informacja dla WINB"
                reason={props.value.description}
                variant="outline-secondary"
                className="float-end me-1"
              >
                Zmień
              </ConfirmationButton>
            )}
          </Col>
        )}
      </Row>
    </FormRow>
  </Form>
);

const PublishButtons = (props: {
  published: boolean;
  mayBePublished: () => Promise<boolean>;
  onPublish: () => void;
  onRevertPublish: () => void;
  onPdf: () => void;
}) => (
  <>
    <Button onClick={props.onPdf} variant="outline-secondary" className="me-1">
      Podgląd PDF
    </Button>
    {!props.published ? (
      <ConfirmationButton
        roleAnyOf={[Role.ExamsPublishing]}
        onValidation={props.mayBePublished}
        confirmation="Czy na pewno publikować wynik?"
        onOK={props.onPublish}
        variant="outline-success"
      >
        Publikuj
      </ConfirmationButton>
    ) : (
      <ConfirmationButton
        confirmation="Czy cofnąć publikację wyniku?"
        roleAnyOf={[Role.ExamsPublishing]}
        onOK={props.onRevertPublish}
        variant="outline-danger"
      >
        Cofnij publikację
      </ConfirmationButton>
    )}
  </>
);

export default (props: {
  value: GetExamResultDto;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  attachment: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  className?: string;
  onUpdate: (dto: CreateUpdateExamResultDto) => void;
  onApprove: () => void;
  onRevertApprove: (reason?: string) => void;
  mayBeApproved: () => Promise<ValidationStatus>;
  mayBePublished: () => Promise<ValidationStatus>;
  onDelete: () => void;
  onPublish: () => void;
  onRevertPublish: () => void;
  onPdf: () => void;
}) => {
  const [header, setHeader] = useState<string>();
  const [headerMin, setHeaderMin] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const approved = props.value.approved;
  const negative = props.value.negative;
  const published =
    props.value.publicationDate !== undefined &&
    props.value.publicationDate !== null;

  const setNegative = () => {
    const dto = ExamResultContext.toCuDto(props.value);
    dto.negative = !dto.negative;
    props.onUpdate(dto);
  };

  const changeDescription = (description?: string) => {
    const dto = ExamResultContext.toCuDto(props.value);
    if (dto.description !== description) {
      dto.description = description || '';
      props.onUpdate(dto);
    }
  };

  const changeVerification = () => {
    const dto = ExamResultContext.toCuDto(props.value);
    dto.verified = !dto.verified;
    props.onUpdate(dto);
  };

  const validate = async (method: () => Promise<ValidationStatus>) => {
    const status = await method();
    if (status.state === 'ok') {
      return true;
    } else {
      setShowAlert(true);
      setErrors(status.errors);
      return false;
    }
  };

  const checkAttachment = async () => {
    if (props.attachment.documents.length > 0) {
      setShowAlert(true);
      setErrors([
        'Przed cofnięciem przekazania do publikacji należy usunąć załącznik.',
      ]);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (props.value.publicationDate) {
      setHeader(
        `Wynik ${
          props.value.negative ? 'negatywny' : 'pozytywny'
        } opublikowany ${momencik(props.value.publicationDate)} (numer: ${
          props.value.yearOfCommission + '/' + props.value.yearOrderNo
        })`,
      );
    } else {
      setHeader(
        `${
          props.value.approved ? 'Przekazany' : 'Nieprzekazany'
        } do publikacji (wynik ${
          props.value.negative ? 'negatywny' : 'pozytywny'
        })`,
      );
    }
    props.value.approvedDate &&
      setHeaderMin(
        `Pierwsze przekazanie do publikacji ${momencik(
          props.value.approvedDate,
        )}`,
      );
  }, [props.value]);

  return (
    <Alert
      variant={negative ? 'danger' : 'success'}
      className={props.className}
    >
      <Alert.Heading className="d-flex">
        <div className="flex-grow-1 align-self-center">{header}</div>

        {(props.editAuthorized || props.publishAuthorized) && (
          <div>
            {approved ? (
              <>
                {!published && (
                  <ConfirmationButton
                    confirmation="Czy na pewno cofnąć przekazanie wyniku do publikacji?"
                    onOK={props.onRevertApprove}
                    rolesAllOf={[Role.ExamsPublishing, Role.ExamsRevoke]}
                    askForAReason
                    reasonNotEmpty
                    reasonText="Informacja dla WINB"
                    reason={props.value.description}
                    variant="outline-danger"
                    className="mx-1"
                    onValidation={checkAttachment}
                  >
                    Cofnij przekazanie do publikacji
                  </ConfirmationButton>
                )}
                <PublishButtons
                  published={published}
                  mayBePublished={() => validate(props.mayBePublished)}
                  onPublish={props.onPublish}
                  onRevertPublish={props.onRevertPublish}
                  onPdf={props.onPdf}
                />
              </>
            ) : (
              <>
                <ConfirmationButton
                  confirmation="Czy na pewno usunąć wynik?"
                  roleAnyOf={[Role.ExamsEdit]}
                  onOK={props.onDelete}
                  variant="outline-danger"
                >
                  Usuń
                </ConfirmationButton>
                <Button
                  variant="outline-secondary"
                  className="mx-1"
                  onClick={setNegative}
                >
                  {negative ? 'Pozytywny' : 'Negatywny'}
                </Button>
                <ConfirmationButton
                  confirmation="Czy na pewno przekazać wynik badania próbki do publikacji?"
                  onOK={props.onApprove}
                  variant="outline-primary"
                  onValidation={() => validate(props.mayBeApproved)}
                >
                  Przekaż do publikacji
                </ConfirmationButton>
              </>
            )}
            <ValidationAlert
              show={showAlert}
              modal
              onHide={() => setShowAlert(false)}
              errors={errors}
              buttonText="Zamknij"
            />
          </div>
        )}
      </Alert.Heading>
      <div className="flex-grow-1 align-self-center">{headerMin}</div>
      <hr />
      <ResultForm
        value={props.value}
        publishAuthorized={props.publishAuthorized}
        attachment={props.attachment}
        changeDescription={changeDescription}
        changeVerification={changeVerification}
      />
    </Alert>
  );
};
