/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCorrectiveActionsDetails
 */
export interface ReportCorrectiveActionsDetails {
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countAfterProceeding: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countInInspection: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countBetweenInspProc: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countArt3011: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countArt3012: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countAfterProceedingCpr: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countInInspectionCpr: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countBetweenInspProcCpr: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countArt3011Cpr: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countArt3012Cpr: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCorrectiveActionsDetails
     */
    countCpr: number;
}

export function ReportCorrectiveActionsDetailsFromJSON(json: any): ReportCorrectiveActionsDetails {
    return ReportCorrectiveActionsDetailsFromJSONTyped(json, false);
}

export function ReportCorrectiveActionsDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCorrectiveActionsDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countAfterProceeding': json['countAfterProceeding'],
        'countInInspection': json['countInInspection'],
        'countBetweenInspProc': json['countBetweenInspProc'],
        'countArt3011': json['countArt3011'],
        'countArt3012': json['countArt3012'],
        'count': json['count'],
        'countAfterProceedingCpr': json['countAfterProceedingCpr'],
        'countInInspectionCpr': json['countInInspectionCpr'],
        'countBetweenInspProcCpr': json['countBetweenInspProcCpr'],
        'countArt3011Cpr': json['countArt3011Cpr'],
        'countArt3012Cpr': json['countArt3012Cpr'],
        'countCpr': json['countCpr'],
    };
}

export function ReportCorrectiveActionsDetailsToJSON(value?: ReportCorrectiveActionsDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countAfterProceeding': value.countAfterProceeding,
        'countInInspection': value.countInInspection,
        'countBetweenInspProc': value.countBetweenInspProc,
        'countArt3011': value.countArt3011,
        'countArt3012': value.countArt3012,
        'count': value.count,
        'countAfterProceedingCpr': value.countAfterProceedingCpr,
        'countInInspectionCpr': value.countInInspectionCpr,
        'countBetweenInspProcCpr': value.countBetweenInspProcCpr,
        'countArt3011Cpr': value.countArt3011Cpr,
        'countArt3012Cpr': value.countArt3012Cpr,
        'countCpr': value.countCpr,
    };
}


