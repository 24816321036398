import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import momencik from '../../../common/momencik';
import {
  CreateUpdateSampleDto,
  GetOrganizationalUnitDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow, {
  FormRow66,
  FormRow66R,
  FormRow84R,
} from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import OrganizationalUnitSelector from '../Selects/OrganizationalUnitSelector';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';

import CostsTable from './SampleCostsTable';

export default ({
  readonly,
  readonlyCosts,
  presentedSample,
  setProperty,
  presentedToSelected,
  sampleChange,
  operateOrganizationalUnit,
}: {
  readonly: boolean;
  readonlyCosts: boolean;
  presentedSample: CreateUpdateSampleDto;
  setProperty: (obj: Partial<CreateUpdateSampleDto>) => void;
  presentedToSelected: () => void;
  sampleChange: (obj: Partial<CreateUpdateSampleDto>) => void;
  operateOrganizationalUnit: GetOrganizationalUnitDto;
}) => (
  <>
    <Form className="d-grid gap-3">
      <Row>
        <Col md={4}>
          <FormRow66 controlId="batchNumber" label="Numer partii" static={true}>
            {presentedSample.batchNumber}
          </FormRow66>
        </Col>
        <Col md={4}>
          <FormRow66R controlId="quantity" label="Pobrana ilość" static={true}>
            {presentedSample.quantity}
          </FormRow66R>
        </Col>
        <Col md={4}>
          <FormRow66R controlId="quantity" label="Data produkcji" static={true}>
            {presentedSample.productionDateText ||
              momencik(presentedSample.productionDate)}
          </FormRow66R>
        </Col>
      </Row>
      {presentedSample.collectionMethod && (
        <FormRow
          controlId="collectionMethod"
          label="Opis sposobu pobrania"
          static={true}
        >
          {presentedSample.collectionMethod}
        </FormRow>
      )}
      {presentedSample.comments && (
        <FormRow controlId="comments" label="Uwagi" static={true}>
          {presentedSample.comments}
        </FormRow>
      )}
      <Row>
        <Col md={6}>
          <FormRow66
            controlId="operateOrganizationalUnit"
            label="Organ obsługujący"
            static={readonly}
          >
            {readonly ? (
              <OrganizationalUnitBadge
                organizationalUnit={operateOrganizationalUnit}
              />
            ) : (
              <OrganizationalUnitSelector
                option={OrganizationalUnitsGetOptions.notNotified}
                value={presentedSample.operateOrganizationalUnitId}
                onChange={option => {
                  sampleChange({ operateOrganizationalUnitId: option.value });
                }}
              />
            )}
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow84R
            controlId="sampleCommissionDate"
            label="Data zlecenia"
            static={readonly}
          >
            {readonly ? (
              momencik(presentedSample.sampleCommissionDate)
            ) : (
              <DatePicker
                value={presentedSample.sampleCommissionDate}
                onChange={date => sampleChange({ sampleCommissionDate: date })}
              />
            )}
          </FormRow84R>
        </Col>
      </Row>
      <FormRow
        controlId="sampleLeftover"
        label="Pozostałości po próbce"
        static={readonly}
      >
        {readonly ? (
          presentedSample.sampleLeftover
        ) : (
          <DynamicTextarea
            value={presentedSample.sampleLeftover || ''}
            onChange={e => setProperty({ sampleLeftover: e.target.value })}
            onBlur={presentedToSelected}
          />
        )}
      </FormRow>
      <CostsTable
        readonly={readonlyCosts}
        presentedSample={presentedSample}
        setProperty={setProperty}
        presentedToSelected={presentedToSelected}
      />
    </Form>
  </>
);
