/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateCertificateDto
 */
export interface CreateUpdateCertificateDto {
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateCertificateDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCertificateDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCertificateDto
     */
    note: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCertificateDto
     */
    producerId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCertificateDto
     */
    organizationalUnitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCertificateDto
     */
    informationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCertificateDto
     */
    groupProductTypeId: string;
}

export function CreateUpdateCertificateDtoFromJSON(json: any): CreateUpdateCertificateDto {
    return CreateUpdateCertificateDtoFromJSONTyped(json, false);
}

export function CreateUpdateCertificateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateCertificateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'number': json['number'],
        'note': json['note'],
        'producerId': json['producerId'],
        'organizationalUnitId': json['organizationalUnitId'],
        'informationId': json['informationId'],
        'groupProductTypeId': json['groupProductTypeId'],
    };
}

export function CreateUpdateCertificateDtoToJSON(value?: CreateUpdateCertificateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'number': value.number,
        'note': value.note,
        'producerId': value.producerId,
        'organizationalUnitId': value.organizationalUnitId,
        'informationId': value.informationId,
        'groupProductTypeId': value.groupProductTypeId,
    };
}


