import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import { TabPane } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import {
  GetPakDto,
  GetProceedingDto,
  CreateUpdateJudgmentDto,
  GetJudgmentDto,
  GetPartyToProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { PakViewApi } from '../../../common/snrwbCore/contexts/PakContext';
import { ProductTypeEdit } from '../ProductTypes/ProductTypeEdit';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { PartiesToProceeding } from '../PartyToProceedings/PartiesToProceeding';
import oneliner from '../../../common/oneliner';
import { DataChangeScope } from '../../../common/snrwbCore/sockets/socket.datatypes';
import { ConcurrencySignaler } from '../../../app/components/ConcurrencySignaler';
import nkitwDesc from '../../../common/nkitwDesc';

import { PakApprovement } from './PakApprovement';
import { PakDetails } from './PakDetails';
import { PakAssociates } from './PakAssociates';
import { PakButtons } from './PakButtons';

export const Pak = (props: {
  editAuthorized: boolean;
  validOrganizationalUnit: boolean;
  value: GetPakDto;
  bannerId?: string;
  parties: GetPartyToProceedingDto[];
  proceeding?: GetProceedingDto;
  penalties: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  codex: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  api: PakViewApi;
}) => {
  const [bannerId, setBannerId] = useState(props.bannerId);
  const [key, setKey] = useState({
    scope: DataChangeScope.pak,
    id: props.value.id,
  });
  const [description, setDescription] = useState(
    'PAK w sprawie ' + nkitwDesc(props.value.productType),
  );

  const name = oneliner(
    `Postępowanie administracyjne w sprawie nałożenia kary: ${nkitwDesc(
      props.value.productType,
    )}`,
    120,
  );

  useEffect(() => {
    setBannerId(props.bannerId);
  }, [props.bannerId]);

  useEffect(() => {
    setKey({ scope: DataChangeScope.pak, id: props.value.id });
    setDescription('PAK w sprawie ' + nkitwDesc(props.value.productType));
  }, [props.value.id, props.value.productType]);

  return (
    <>
      <Card className="mb-3">
        <Tabs id="pak" className="mb-3">
          <Tab eventKey="details" title={name} className="m-3">
            <PakApprovement
              value={props.value}
              validOrganizationalUnit={props.validOrganizationalUnit}
              editAuthorized={props.editAuthorized}
              onApprove={props.api.approvePak}
              onRevertApprove={props.api.revertApprovePak}
              mayBeApproved={props.api.mayPakBeApproved}
            />
            <PartiesToProceeding
              readonly={props.value.approved || !props.editAuthorized}
              parties={props.parties}
              pak={props.value}
              addParty={props.api.addParty}
              addPartyWithOrganization={props.api.addPartyWithOrganization}
              validateNewParty={props.api.validateNewParty}
              deleteParty={props.api.deleteParty}
            />
            <PakDetails
              value={props.value}
              editAuthorized={props.editAuthorized}
              onChange={props.api.changePak}
            />
            <PakAssociates
              {...props}
              bannerId={bannerId}
              onBannerClosed={() => setBannerId(undefined)}
            />
          </Tab>
          <TabPane
            eventKey="product"
            title="Typ wyrobu"
            className="m-3"
            mountOnEnter={false}
            unmountOnExit={false}
          >
            <ProductTypeEdit
              readonly={props.value.approved || !props.editAuthorized}
              productType={props.value.productType}
              onChange={props.api.changeProductType}
              onApprove={props.api.approveProductType}
              onRevertApprove={props.api.revertApproveProductType}
              mayBeApproved={() =>
                props.api.mayProductTypeBeApproved(props.value.productType.id)
              }
              refresh={props.api.refresh}
            />
          </TabPane>
        </Tabs>
      </Card>
      <PakButtons {...props} />
      <ConcurrencySignaler
        dataKey={key}
        objectDescription={description}
        editAllowed={props.editAuthorized}
        refreshAction={props.api.refresh}
      />
    </>
  );
};
