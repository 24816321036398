/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateJudgmentDto
 */
export interface CreateUpdateJudgmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    kindId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    information: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    kindOfSettlement: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateJudgmentDto
     */
    publishDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    key: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateJudgmentDto
     */
    finalDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJudgmentDto
     */
    appeal: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    resultOfAppeal: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJudgmentDto
     */
    approved: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateJudgmentDto
     */
    additionalOrders: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    organizationalUnitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    pakId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    proceedingId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentDto
     */
    sampleId: string;
}

export function CreateUpdateJudgmentDtoFromJSON(json: any): CreateUpdateJudgmentDto {
    return CreateUpdateJudgmentDtoFromJSONTyped(json, false);
}

export function CreateUpdateJudgmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateJudgmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kindId': json['kindId'],
        'information': json['information'],
        'kindOfSettlement': json['kindOfSettlement'],
        'publishDate': !exists(json, 'publishDate') ? undefined : (json['publishDate'] === null ? null : new Date(json['publishDate'])),
        'key': json['key'],
        'finalDate': !exists(json, 'finalDate') ? undefined : (json['finalDate'] === null ? null : new Date(json['finalDate'])),
        'appeal': json['appeal'],
        'resultOfAppeal': json['resultOfAppeal'],
        'approved': json['approved'],
        'additionalOrders': json['additionalOrders'],
        'organizationalUnitId': json['organizationalUnitId'],
        'inspectedProductId': json['inspectedProductId'],
        'pakId': json['pakId'],
        'proceedingId': json['proceedingId'],
        'sampleId': json['sampleId'],
    };
}

export function CreateUpdateJudgmentDtoToJSON(value?: CreateUpdateJudgmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kindId': value.kindId,
        'information': value.information,
        'kindOfSettlement': value.kindOfSettlement,
        'publishDate': value.publishDate === undefined ? undefined : (value.publishDate === null ? null : value.publishDate.toISOString()),
        'key': value.key,
        'finalDate': value.finalDate === undefined ? undefined : (value.finalDate === null ? null : value.finalDate.toISOString()),
        'appeal': value.appeal,
        'resultOfAppeal': value.resultOfAppeal,
        'approved': value.approved,
        'additionalOrders': value.additionalOrders,
        'organizationalUnitId': value.organizationalUnitId,
        'inspectedProductId': value.inspectedProductId,
        'pakId': value.pakId,
        'proceedingId': value.proceedingId,
        'sampleId': value.sampleId,
    };
}


