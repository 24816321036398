import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { ReportInspectedOrganizations } from '../../../../common/types/ReportTypes';
import { ReportByOrganizationType } from '../../../components/ReportByOrganizationType/ReportByOrganizationType';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';

export const ReportInspectedOrganizationByProductsWithMisstatementView: React.FC =
  () => {
    const snrwbApi = useSnrwbCore();
    const [filterApplied, setFilterApplied] = useState(false);
    const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
      {},
    );
    const [data, setData] = useState<ReportInspectedOrganizations>({
      details: {},
      sum: {},
    });

    const onSearch = (filter?: FilterReportData) => {
      setFilterApplied(true);
      setUsedFilter(filter);
      snrwbApi.reports
        .reportInspectedOrganizationsByProductsWithMisstatement(false, filter)
        .then(dataReports => {
          setData(dataReports);
        });
    };

    return (
      <>
        <Filter
          onSearch={onSearch}
          reportName="Skontrolowane niezgodne wyroby u podmiotów"
        />
        <ReportByOrganizationType
          data={data}
          reportName1="wyroby niezgodne ogółem (suma poszczególnych)"
          reportName2="liczba wyrobów niezgodnych skontrolowanych u producentów"
          reportName3="liczba wyrobów niezgodnych skontrolowanych u importerów"
          reportName4="liczba wyrobów niezgodnych skontrolowanych u sprzedawców"
          reportName5="liczba wyrobów niezgodnych skontrolowanych u upoważnionych przedstawicieli producentów"
          filterApplied={filterApplied}
          usedFilter={usedFilter}
          reportName="Skontrolowane niezgodne wyroby u podmiotów"
        />
      </>
    );
  };
