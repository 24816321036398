/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionPlanForOrganizationDetails
 */
export interface InspectionPlanForOrganizationDetails {
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForOrganizationDetails
     */
    inspectionPlannedCount: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForOrganizationDetails
     */
    inspectionPlannedCprCount: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForOrganizationDetails
     */
    inspPlanProductCount: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForOrganizationDetails
     */
    inspPlanProductCPRCount: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForOrganizationDetails
     */
    inspectionAdHocCount: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionPlanForOrganizationDetails
     */
    inspectionAllCount: number;
}

export function InspectionPlanForOrganizationDetailsFromJSON(json: any): InspectionPlanForOrganizationDetails {
    return InspectionPlanForOrganizationDetailsFromJSONTyped(json, false);
}

export function InspectionPlanForOrganizationDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionPlanForOrganizationDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionPlannedCount': json['inspectionPlannedCount'],
        'inspectionPlannedCprCount': json['inspectionPlannedCprCount'],
        'inspPlanProductCount': json['inspPlanProductCount'],
        'inspPlanProductCPRCount': json['inspPlanProductCPRCount'],
        'inspectionAdHocCount': json['inspectionAdHocCount'],
        'inspectionAllCount': json['inspectionAllCount'],
    };
}

export function InspectionPlanForOrganizationDetailsToJSON(value?: InspectionPlanForOrganizationDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionPlannedCount': value.inspectionPlannedCount,
        'inspectionPlannedCprCount': value.inspectionPlannedCprCount,
        'inspPlanProductCount': value.inspPlanProductCount,
        'inspPlanProductCPRCount': value.inspPlanProductCPRCount,
        'inspectionAdHocCount': value.inspectionAdHocCount,
        'inspectionAllCount': value.inspectionAllCount,
    };
}


