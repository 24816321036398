/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePartyToProceedingDto
 */
export interface CreateUpdatePartyToProceedingDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePartyToProceedingDto
     */
    organizationTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePartyToProceedingDto
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePartyToProceedingDto
     */
    proceedingId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePartyToProceedingDto
     */
    pakId: string;
}

export function CreateUpdatePartyToProceedingDtoFromJSON(json: any): CreateUpdatePartyToProceedingDto {
    return CreateUpdatePartyToProceedingDtoFromJSONTyped(json, false);
}

export function CreateUpdatePartyToProceedingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePartyToProceedingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationTypeId': json['organizationTypeId'],
        'organizationId': json['organizationId'],
        'proceedingId': json['proceedingId'],
        'pakId': json['pakId'],
    };
}

export function CreateUpdatePartyToProceedingDtoToJSON(value?: CreateUpdatePartyToProceedingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationTypeId': value.organizationTypeId,
        'organizationId': value.organizationId,
        'proceedingId': value.proceedingId,
        'pakId': value.pakId,
    };
}


