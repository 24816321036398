/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCertificateDto
 */
export interface GetCertificateDto {
    /**
     * 
     * @type {string}
     * @memberof GetCertificateDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetCertificateDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetCertificateDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof GetCertificateDto
     */
    note: string;
    /**
     * 
     * @type {GetOrganizationDto}
     * @memberof GetCertificateDto
     */
    producer: GetOrganizationDto;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetCertificateDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetCertificateDto
     */
    information: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetCertificateDto
     */
    groupProductType: GetDictionaryDto;
}

export function GetCertificateDtoFromJSON(json: any): GetCertificateDto {
    return GetCertificateDtoFromJSONTyped(json, false);
}

export function GetCertificateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCertificateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'number': json['number'],
        'note': json['note'],
        'producer': GetOrganizationDtoFromJSON(json['producer']),
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'information': GetDictionaryDtoFromJSON(json['information']),
        'groupProductType': GetDictionaryDtoFromJSON(json['groupProductType']),
    };
}

export function GetCertificateDtoToJSON(value?: GetCertificateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString()),
        'number': value.number,
        'note': value.note,
        'producer': GetOrganizationDtoToJSON(value.producer),
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'information': GetDictionaryDtoToJSON(value.information),
        'groupProductType': GetDictionaryDtoToJSON(value.groupProductType),
    };
}


