import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import * as Icon from 'react-bootstrap-icons';

import {
  GetInspectedProductDto,
  GetProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { useNavigation } from '../../../common/navigation';
import { ProceedingViewApi } from '../../../common/snrwbCore/contexts/ProceedingContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import ValidationAlert from '../../../app/components/ValidationAlert';

export const ProceedingSampleExams = (props: {
  value: GetProceedingDto;
  editAuthorized: boolean;
  inspectedProduct: GetInspectedProductDto;
  api: ProceedingViewApi;
}) => {
  const nav = useNavigation();
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  const checkControlSampleExamination = async () => {
    const status = await props.api.mayExamControlSample();
    if (status.state === 'ok') {
      return true;
    }
    setErrors([status.message]);
    setShowErrors(true);
    return false;
  };

  return (
    <Alert variant="secondary">
      <Alert.Heading>
        Wyrób {props.inspectedProduct.sampleExam ? '' : 'nie'} podlega badaniu
      </Alert.Heading>
      {props.inspectedProduct.sampleExam && (
        <>
          <hr />
          <div className="d-flex justify-content-between">
            <div>
              {!props.value.sample ? (
                props.editAuthorized && (
                  <ConfirmationButton
                    variant="outline-primary"
                    confirmation="Czy na pewno zlecić badanie próbki kontrolnej?"
                    className="mx-1"
                    onValidation={checkControlSampleExamination}
                    onOK={props.api.controlSampleExam}
                  >
                    Zleć badanie próbki kontrolnej
                  </ConfirmationButton>
                )
              ) : (
                <>W postępowaniu zlecono badanie próbki kontrolnej.</>
              )}
            </div>
            <div>
              <Button
                variant="outline-primary"
                onClick={() =>
                  nav.sampleExam(props.inspectedProduct.sampleExam.id)
                }
              >
                <Icon.Search /> Pokaż badanie nr{' '}
                {props.inspectedProduct.sampleExam.sysNumber}
              </Button>
            </div>
          </div>
        </>
      )}
      <ValidationAlert
        modal={true}
        errors={errors}
        show={showErrors}
        onHide={() => setShowErrors(false)}
      />
    </Alert>
  );
};
