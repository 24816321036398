import React, { useEffect, useState } from 'react';
import { Alert, Button, Accordion } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import {
  CreateUpdateInspectorDto,
  GetInspectorDto,
  GetSampleCollectDto,
  GetSampleExamDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { SampleCollectApi } from '../../../common/snrwbCore/contexts/SampleCollectContext';
import { SelectProductTypeModal } from '../ProductTypes/SelectProductTypeModal';
import { useNavigation } from '../../../common/navigation';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import { ProductType } from '../../../common/snrwbCore/contexts/ProductTypeContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import ProductTypeNotApproved from '../ProductTypes/ProductTypeNotApproved';
import { SampleExamForProtocol } from '../SampleExams/SampleExamForProtocol';
import { GetProductTypeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import oneliner from '../../../common/oneliner';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import nkitwDesc from '../../../common/nkitwDesc';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

interface SampleCollectExamsProps {
  editAuthorized: boolean;
  validOrganizationalUnit: boolean;
  sampleCollect: GetSampleCollectDto;
  sampleExams: GetSampleExamDto[];
  inspectors: Map<
    string,
    AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>
  >;
  api: SampleCollectApi;
}

export const SampleCollectExams = (props: SampleCollectExamsProps) => {
  const nav = useNavigation();
  const snrwb = useSnrwbCore();
  const [errors, setErrors] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<string>();
  const [addInspectedProduct, setAddInspectedProduct] = useState(true);

  useEffect(() => {
    snrwb.sampleCollects.setDuringInspectionDto().then(() => {
      setAddInspectedProduct(
        !props.sampleExams.every(o => o.completionDate) ||
          props.sampleCollect?.collectBasis?.id !==
            SampleCollectContext.duringInspectionBasisId() ||
          props.sampleExams.length === 0,
      );
    });
  }, [
    props.sampleCollect?.collectBasis,
    props.sampleExams,
    snrwb.sampleCollects,
  ]);

  const NoAuthorizationForEdit = () => (
    <>
      {!props.editAuthorized && (
        <Alert variant="warning">
          Brak uprawnień do edycji
          {!props.validOrganizationalUnit && ' (niewłaściwy organ)'}
        </Alert>
      )}
    </>
  );

  const addSampleExam = async (
    organization: Organization,
    productType: ProductType,
  ) => {
    const createMethod = () => {
      if ('id' in productType) {
        return props.api.addExam(productType.id);
      }
      if ('id' in organization) {
        productType.organizationId = organization.id;
        return props.api.addExamWithNewProductType(productType);
      }
      return props.api.addExamWithNewOrganizationAndProductType(
        organization,
        productType,
      );
    };

    try {
      createMethod();
      return true;
    } catch (response) {
      setErrors(await responseErrors(response as Response));
    }

    return false;
  };

  const productName = (product: GetProductTypeDto) => {
    return oneliner(nkitwDesc(product), 100);
  };
  const examDescription = (exam: GetSampleExamDto) => {
    return ` (badanie nr ${exam.sysNumber} ${
      exam.approved ? 'podpisane' : 'niepodpisane'
    }${exam.completionDate ? ', obsługa zakończona' : ''})`;
  };

  return (
    <>
      <NoAuthorizationForEdit />
      {!props.sampleExams?.length ? (
        <Alert variant="warning">
          W pobraniu nie ma jeszcze żadnych wyrobów.
        </Alert>
      ) : (
        <Accordion className="mb-3" activeKey={activeItem}>
          {props.sampleExams.map(sampleExam => (
            <div key={sampleExam.id} className="d-flex mb-1">
              <Accordion.Item eventKey={sampleExam.id} className="flex-fill">
                <Accordion.Header
                  onClick={() =>
                    setActiveItem(
                      sampleExam.id === activeItem ? undefined : sampleExam.id,
                    )
                  }
                >
                  {!sampleExam.productType.approved && (
                    <ProductTypeNotApproved
                      className="me-2"
                      tooltip="Niezatwierdzony typ wyrobu"
                    />
                  )}
                  {productName(sampleExam.productType)}
                  {examDescription(sampleExam)}
                </Accordion.Header>
                <Accordion.Body>
                  <SampleExamForProtocol
                    editAuthorized={
                      props.editAuthorized && !props.sampleCollect.approved
                    }
                    value={sampleExam}
                    inspectors={props.inspectors.get(sampleExam.id)}
                    onChange={dto =>
                      props.api.editSampleExam(sampleExam.id, dto)
                    }
                  />
                  <div className="mt-3 d-flex justify-content-end">
                    <Button
                      variant="outline-primary"
                      onClick={() => nav.sampleExam(sampleExam.id)}
                    >
                      <Icon.Search /> Badanie {sampleExam.sysNumber}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {sampleExam.id !== activeItem && (
                <Button
                  className="ms-1"
                  variant="outline-primary"
                  onClick={() => nav.sampleExam(sampleExam.id)}
                >
                  <Icon.Search /> Badanie {sampleExam.sysNumber}
                </Button>
              )}
            </div>
          ))}
        </Accordion>
      )}
      {!props.sampleCollect.approved &&
        props.editAuthorized &&
        addInspectedProduct && (
          <SelectProductTypeModal
            title="Pobranie próbek wyrobu"
            buttonText="Dodaj wyrób"
            errors={errors}
            role={Role.ExamsEdit}
            add={addSampleExam}
          />
        )}
    </>
  );
};
