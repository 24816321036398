/*eslint max-lines-per-function: ["error", 220]*/
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';

import {
  GetSampleExamDto,
  CreateUpdateSampleExamDto,
  CreateUpdateOrganizationDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow, {
  FormRow48,
  FormRow66,
  FormRow66R,
} from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import DatePicker from '../../../app/components/DatePicker';
import MultiColumnValue, {
  Column,
  ValueType,
} from '../../../app/components/MultiColumnValue';
import * as SampleExamContext from '../../../common/snrwbCore/contexts/SampleExamContext';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import DictionarySelector from '../Selects/DictionarySelector';
import BooleanDropdown from '../../../app/components/BooleanDropdown';
import RichTextSingleLine from '../../../app/components/RichTextSingleLine';

const nullIfEmpty = (value: ValueType): ValueType => value || null;

const columnsForExpirationDate: (exam: CreateUpdateSampleExamDto) => Column[] =
  (exam: CreateUpdateSampleExamDto) => [
    {
      label: 'Data',
      type: 'Date',
      key: 'expirationDate',
      value: exam.expirationDate || null,
    },
    {
      label: 'Opis',
      type: 'string',
      key: 'expirationText',
      value: nullIfEmpty(exam.expirationText),
    },
    {
      label: 'Nie dotyczy',
      type: 'n/a',
      key: 'expirationNA',
      value: nullIfEmpty(exam.expirationNA),
    },
  ];

const columnsForSupplier: (
  organization: OrganizationContext.Organization,
  exam: CreateUpdateSampleExamDto,
) => Column[] = (
  organization: OrganizationContext.Organization,
  exam: CreateUpdateSampleExamDto,
) => [
  {
    label: 'Nie ustalono',
    type: 'n/a',
    key: 'supplierUnknown',
    value: nullIfEmpty(exam.supplierUnknown),
  },
  {
    label: 'Podmiot',
    type: 'supplier',
    key: 'supplierId',
    value: nullIfEmpty(organization),
  },
  {
    label: 'Nie dotyczy',
    type: 'n/a',
    key: 'supplierNA',
    value: nullIfEmpty(exam.supplierNA),
  },
];

const columnsForInvoiceNumber: (exam: CreateUpdateSampleExamDto) => Column[] = (
  exam: CreateUpdateSampleExamDto,
) => [
  {
    label: 'Numer',
    type: 'string',
    key: 'invoiceNumber',
    value: nullIfEmpty(exam.invoiceNumber),
  },
  {
    label: 'Nie dotyczy',
    type: 'n/a',
    key: 'invoiceNumberNA',
    value: nullIfEmpty(exam.invoiceNumberNA),
  },
];

export const SampleExamDetails: React.FC<{
  editAuthorized: boolean;
  value: GetSampleExamDto;
  onChange: (inspection: CreateUpdateSampleExamDto) => void;
  onNewSupplier: (organization: CreateUpdateOrganizationDto) => void;
}> = props => {
  const [sampleExam, setSampleExam] = useState(
    SampleExamContext.toCuDto(props.value),
  );
  const [presented, setPresented] = useState(sampleExam);

  useEffect(
    () => setSampleExam(SampleExamContext.toCuDto(props.value)),
    [props.value],
  );
  useEffect(() => setPresented(sampleExam), [sampleExam]);

  if (props.value.approved || !props.editAuthorized) {
    const presentation = SampleExamContext.detailsPresent(props.value);
    return <ItemPresentation item={presentation} className="pt-3" />;
  }

  const setProperty = (obj: Partial<GetSampleExamDto>) => {
    setPresented({ ...presented, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateSampleExamDto) => {
    if (_.isEqual(sampleExam, obj)) {
      return;
    }
    props.onChange(obj);
  };
  const examChange = (obj: Partial<CreateUpdateSampleExamDto>) => {
    const dto = { ...sampleExam, ...obj };
    setSampleExam(dto);
    saveIfChanged(dto);
  };
  const presentedToExam = () => {
    setSampleExam(presented);
    saveIfChanged(presented);
  };

  const supplierSelected = (info: Record<string, ValueType>) => {
    if (!info.supplierId) {
      examChange(info);
      return;
    }
    const organization = info.supplierId as OrganizationContext.Organization;
    if ('id' in organization) {
      examChange(info);
    } else {
      props.onNewSupplier(organization);
    }
  };

  const changeAndBlur = (obj: Partial<GetSampleExamDto>) => {
    setPresented({ ...presented, ...obj });
    setSampleExam({ ...presented, ...obj });
    saveIfChanged({ ...presented, ...obj });
  };

  const emptyIfNull = (value: string) => value || '';

  return (
    <Form className="d-grid gap-3">
      <Row>
        <Col md={6}>
          <FormRow66 controlId="fileNumber" label="Numer akt badania">
            <Form.Control
              type="text"
              value={presented.fileNumber || ''}
              onChange={e => setProperty({ fileNumber: e.target.value })}
              onBlur={presentedToExam}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R
            controlId="collectDate"
            label="Miejsce pobrania"
            alignControlLeft
          >
            <DictionarySelector
              dictionary="badanie próbek - miejsce pobrania"
              value={presented.organizationTypeId}
              onChange={o => examChange({ organizationTypeId: o.value })}
            />
          </FormRow66R>
        </Col>
      </Row>
      <FormRow controlId="witnesses" label="W obecności">
        <DynamicTextarea
          value={emptyIfNull(presented.witnesses)}
          onChange={e => setProperty({ witnesses: e.target.value })}
          onBlur={presentedToExam}
        />
      </FormRow>
      <Row>
        <Col md={9}>
          <FormRow48 controlId="expirationDate" label="Termin ważności">
            <MultiColumnValue
              variant="outline-secondary"
              onChange={examChange}
              columns={columnsForExpirationDate(presented)}
            />
          </FormRow48>
        </Col>
        <Col md={3}>
          <FormRow66R controlId="collectDate" label="Data pobrania">
            <DatePicker
              value={presented.collectDate}
              onChange={date => examChange({ collectDate: date })}
            />
          </FormRow66R>
        </Col>
      </Row>
      <FormRow controlId="description" label="Specyfikacja">
        <DynamicTextarea
          value={presented.technicalSpecification || ''}
          onChange={e =>
            setProperty({ technicalSpecification: e.target.value })
          }
          onBlur={presentedToExam}
        />
      </FormRow>
      <FormRow controlId="supplier" label="Dostawca">
        <MultiColumnValue
          variant="outline-primary"
          onChange={supplierSelected}
          columns={columnsForSupplier(props.value?.supplier, presented)}
        />
      </FormRow>
      <Row>
        <Col md={6}>
          <FormRow66 controlId="supplyDate" label="Data dostawy">
            <DatePicker
              value={presented.supplyDate}
              onChange={date => examChange({ supplyDate: date })}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R controlId="projectPOIiS" label="Pobrano w ramach POIiŚ">
            <BooleanDropdown
              canBeUndefined
              value={presented.projectPOIiS}
              onChange={value =>
                examChange({
                  projectPOIiS: (value === undefined
                    ? null
                    : value) as unknown as boolean,
                })
              }
            />
          </FormRow66R>
        </Col>
      </Row>
      <FormRow controlId="invoiceNumber" label="Rachunek dostawy">
        <MultiColumnValue
          variant="outline-secondary"
          onChange={examChange}
          columns={columnsForInvoiceNumber(presented)}
        />
      </FormRow>
      <FormRow
        controlId="securedQuantity"
        label="Wielkość partii, z której pobrano próbkę"
      >
        <RichTextSingleLine
          value={presented.securedQuantity || ''}
          onChange={v => changeAndBlur({ securedQuantity: v })}
          onlyIndexes={true}
        />
      </FormRow>
      <FormRow controlId="additionalInfo" label="Informacje dodatkowe">
        <DynamicTextarea
          value={presented.additionalInfo || ''}
          onChange={e => setProperty({ additionalInfo: e.target.value })}
          onBlur={presentedToExam}
        />
      </FormRow>
      <FormRow controlId="comments" label="Uwagi">
        <DynamicTextarea
          value={presented.comments || ''}
          onChange={e => setProperty({ comments: e.target.value })}
          onBlur={presentedToExam}
        />
      </FormRow>
    </Form>
  );
};
