import { SnrwbCoreContextType } from '..';
import UnorderedList from '../../../app/components/UnorderedList';
import { AssociateDocuments } from '../../../snrwb/components/AssociatedDocuments/AssociateDocumentsTypes';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import OrganizationalUnitBadge from '../../../snrwb/components/OrganizationalUnit/OrganizationalUnitBadge';
import momencik from '../../momencik';
import { NotificationsProviderType } from '../../notifications';
import {
  CreateUpdateInspectorDto,
  CreateUpdateOrganizationDto,
  CreateUpdateProductTypeDto,
  CreateUpdateSampleCollectDto,
  CreateUpdateSampleCollectDtoFromJSON,
  CreateUpdateSampleExamDto,
  DefaultApi,
  GetAttachmentDto,
  GetDictionaryDtoFromJSON,
  GetInspectorDto,
  GetSampleCollectDto,
  GetSampleCollectExtendedDto,
  GetSampleDto,
  GetSampleExamDto,
  ValidationStatus,
} from '../autogenerated/snrwbApiClient';
import { SampleCollectSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

import * as AttachmentContext from './AttachmentContext';
import * as InspectorContext from './InspectorContext';

const duringInspectionBasis = GetDictionaryDtoFromJSON({});

export interface SampleCollectContextInterface {
  getById: (id: string) => Promise<GetSampleCollectDto>;
  getWithExams: (id: string) => Promise<{
    sampleCollect: GetSampleCollectDto;
    sampleExams: GetSampleExamDto[];
    samples: GetSampleDto[];
    inspectors: GetInspectorDto[];
    files: GetAttachmentDto[];
  }>;
  getPendingForUser: () => Promise<GetSampleCollectExtendedDto[]>;
  update: (id: string, dto: CreateUpdateSampleCollectDto) => Promise<void>;
  updateWithNewOrganization: (
    id: string,
    organization: CreateUpdateOrganizationDto,
  ) => Promise<void>;
  createNew: (
    dto: CreateUpdateSampleCollectDto,
  ) => Promise<GetSampleCollectDto>;
  createWithNewOrganization: (
    dto: CreateUpdateSampleCollectDto,
    organization: CreateUpdateOrganizationDto,
  ) => Promise<GetSampleCollectDto>;
  sign: (id: string) => Promise<void>;
  revertSign: (id: string) => Promise<void>;
  readyToSign: (id: string) => Promise<ValidationStatus>;
  mayDelete: (id: string) => Promise<ValidationStatus>;
  delete: (id: string) => Promise<void>;
  getPlacesForOrganization: (id: string) => Promise<string[]>;
  setDuringInspectionDto: () => Promise<void>;
}

export const SampleCollectContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.sampleCollectControllerGet(id),
    getWithExams: async (id: string) => {
      const model = await api.sampleCollectControllerGetWithExams(id);
      return {
        sampleCollect: model.sampleCollect,
        sampleExams: model.sampleExams as unknown as GetSampleExamDto[],
        samples: model.samples as unknown as GetSampleDto[],
        inspectors: model.inspectors as unknown as GetInspectorDto[],
        files: model.files,
      };
    },
    getPendingForUser: () => api.sampleCollectControllerGetPendingForUser(),
    update: (id: string, dto: CreateUpdateSampleCollectDto) =>
      api.sampleCollectControllerUpdate(id, dto),
    updateWithNewOrganization: (
      id: string,
      organization: CreateUpdateOrganizationDto,
    ) => api.sampleCollectControllerUpdateWithNewOrganization(id, organization),
    createNew: (dto: CreateUpdateSampleCollectDto) =>
      api.sampleCollectControllerCreateNew(dto),
    createWithNewOrganization: (
      sampleCollectDto: CreateUpdateSampleCollectDto,
      organizationDto: CreateUpdateOrganizationDto,
    ) =>
      api.sampleCollectControllerCreateWithNewOrganization({
        sampleCollectDto,
        organizationDto,
      }),
    sign: (id: string) => api.sampleCollectControllerSign(id),
    revertSign: (id: string) => api.sampleCollectControllerRevertSign(id),
    readyToSign: (id: string) => api.sampleCollectControllerReadyToSign(id),
    mayDelete: (id: string) => api.sampleCollectControllerMayDelete(id),
    delete: (id: string) => api.sampleCollectControllerDelete(id),
    getPlacesForOrganization: (id: string) =>
      api.sampleCollectControllerGetPlacesForOrganization(id),

    setDuringInspectionDto: async () => {
      if (duringInspectionBasis.id) {
        return;
      }
      return api
        .dictionaryControllerGetByType('badanie próbek - podstawa pobrania')
        .then(values => {
          const during = values.find(d => d.shortname === '25.1');
          if (during) {
            duringInspectionBasis.id = during.id;
          }
        });
    },
  };
};

export const forSampleCollectView = async (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  id: string,
  action: () => void,
) => {
  const model = await snrwb.sampleCollects.getWithExams(id);
  if (!model) {
    throw new Error('No sample collect ' + id);
  }

  const attFAD = AttachmentContext.forAssociatedDocuments;
  const insFAD = InspectorContext.forAssociatedDocuments;

  const examAttsModel = attFAD(snrwb, notifications, model.files, action);

  examAttsModel.new = () => {
    const attachment = AttachmentContext.newAttachment();
    attachment.enObjectType = AttachmentContext.ObjectType.SampleCollect;
    attachment.objectId = id;
    return attachment;
  };

  const inspectorsMap = new Map<
    string,
    AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>
  >();

  for (const exam of model.sampleExams) {
    const creator = InspectorContext.creatorForSampleExam(exam.id);
    const inspectors = model.inspectors.filter(
      ins => ins.sampleExam.id === exam.id,
    );
    inspectorsMap.set(
      exam.id,
      insFAD(snrwb, notifications, inspectors, creator, action),
    );
  }

  return {
    sampleCollect: model.sampleCollect,
    sampleExams: model.sampleExams,
    samples: model.samples,
    inspectors: inspectorsMap,
    attachments: examAttsModel,
  };
};

export const toCuDto = (getDto: GetSampleCollectDto) => {
  const cuDto = CreateUpdateSampleCollectDtoFromJSON(getDto);
  if (getDto.organization) {
    cuDto.organizationId = getDto.organization.id;
  }
  if (getDto.collectBasis) {
    cuDto.collectBasisId = getDto.collectBasis.id;
  }
  if (getDto.examOrganizationalUnit) {
    cuDto.examOrganizationalUnitId = getDto.examOrganizationalUnit.id;
  }

  if (getDto.collectBasis.id === duringInspectionBasis.id) {
    cuDto.collectBasisShort = '25.1';
  } else {
    cuDto.collectBasisShort = '';
  }

  return cuDto;
};

export interface SampleCollectApi {
  edit: (dto: CreateUpdateSampleCollectDto) => void;
  sign: () => void;
  revertSign: () => void;
  readyToSign: (id: string) => Promise<ValidationStatus>;
  mayDelete: (id: string) => Promise<ValidationStatus>;
  delete: () => void;
  addOrganization: (dto: CreateUpdateOrganizationDto) => void;
  editSampleExam: (
    sampleExamId: string,
    dto: CreateUpdateSampleExamDto,
  ) => void;
  generateProtocol: () => void;
  addExam: (productTypeId: string) => void;
  addExamWithNewProductType: (dto: CreateUpdateProductTypeDto) => void;
  addExamWithNewOrganizationAndProductType: (
    organizationDto: CreateUpdateOrganizationDto,
    productTypeDto: CreateUpdateProductTypeDto,
  ) => void;
  refresh: () => void;
}

export const handlersForSampleCollectView = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  sampleCollect: GetSampleCollectDto,
  refreshAction: () => void,
  navigationAction: () => void,
) => {
  return {
    edit: (dto: CreateUpdateSampleCollectDto) =>
      notifications.onPromise(
        snrwb.sampleCollects.update(sampleCollect.id, dto),
        () => refreshAction(),
      ),

    sign: () =>
      notifications.onPromise(snrwb.sampleCollects.sign(sampleCollect.id), () =>
        refreshAction(),
      ),

    revertSign: () =>
      notifications.onPromise(
        snrwb.sampleCollects.revertSign(sampleCollect.id),
        () => refreshAction(),
      ),

    readyToSign: (id: string) => snrwb.sampleCollects.readyToSign(id),
    mayDelete: (id: string) => snrwb.sampleCollects.mayDelete(id),

    delete: () =>
      notifications.onPromise(
        snrwb.sampleCollects.delete(sampleCollect.id),
        navigationAction,
      ),

    addOrganization: (dto: CreateUpdateOrganizationDto) => {
      notifications.onPromise(
        snrwb.sampleCollects.updateWithNewOrganization(sampleCollect.id, dto),
        () => refreshAction(),
      );
    },

    editSampleExam: (sampleExamId: string, dto: CreateUpdateSampleExamDto) =>
      notifications.onPromise(snrwb.sampleExams.update(sampleExamId, dto), () =>
        refreshAction(),
      ),

    generateProtocol: () =>
      notifications.onPromise(
        snrwb.printouts.examActivityProtocol(sampleCollect.id),
      ),

    addExam: (productTypeId: string) =>
      notifications.onPromise(
        (async () => {
          snrwb.sampleExams.createBasedOnProductType(
            sampleCollect.id,
            productTypeId,
          );
        })(),
        () => refreshAction(),
      ),
    addExamWithNewProductType: (dto: CreateUpdateProductTypeDto) =>
      notifications.onPromise(
        (async () => {
          snrwb.sampleExams.createWithNewProductType(sampleCollect.id, dto);
        })(),
        () => refreshAction(),
      ),
    addExamWithNewOrganizationAndProductType: (
      organizationDto: CreateUpdateOrganizationDto,
      productTypeDto: CreateUpdateProductTypeDto,
    ) =>
      notifications.onPromise(
        (async () => {
          snrwb.sampleExams.createWithNewProductTypeAndOrganization(
            sampleCollect.id,
            organizationDto,
            productTypeDto,
          );
        })(),
        () => refreshAction(),
      ),
    refresh: () => refreshAction(),
  };
};

const add = (
  form: { name: string; value: React.ReactNode }[],
  object: unknown,
  label: string,
  value: () => React.ReactNode,
) => {
  if (object) {
    form.push({ name: label, value: value() });
  }
};

export const detailsPresent = (sampleCollect: GetSampleCollectDto) => {
  const form: { name: string; value: string | JSX.Element }[] = [];

  add(
    form,
    sampleCollect.fileNumber,
    'Numer akt pobrania',
    () => sampleCollect.fileNumber,
  );
  add(
    form,
    sampleCollect.organization,
    'Podmiot',
    () => sampleCollect.organization.name,
  );
  add(
    form,
    sampleCollect.collectPlace,
    'Miejsce pobrania',
    () => sampleCollect.collectPlace,
  );
  add(form, sampleCollect.collectBasis, 'Podstawa pobrania', () =>
    DictionaryStatic({ value: sampleCollect.collectBasis }),
  );
  add(form, sampleCollect.examOrganizationalUnit, 'Organ', () =>
    OrganizationalUnitBadge({
      organizationalUnit: sampleCollect.examOrganizationalUnit,
    }),
  );
  if (duringInspectionBasis.id !== sampleCollect.collectBasis.id) {
    add(form, sampleCollect.actionDate, 'Data rozpoczęcia czynności', () =>
      momencik(sampleCollect.actionDate),
    );
  }

  return form;
};

export const signingPresent = (sampleCollect: GetSampleCollectDto) => {
  const form: { name: string; value: string }[] = [];

  add(
    form,
    sampleCollect.placeOfSignature,
    'Miejsce podpisania',
    () => sampleCollect.placeOfSignature,
  );
  add(
    form,
    sampleCollect.signatureDate,
    'Data podpisania protokołu z czynności',
    () => momencik(sampleCollect.signatureDate),
  );
  add(
    form,
    sampleCollect.protocols,
    'Protokoły',
    () => sampleCollect.protocols,
  );
  add(
    form,
    sampleCollect.witness,
    'Osoba, w obecności której podpisano protokół',
    () => sampleCollect.witness,
  );
  add(
    form,
    sampleCollect.refusalReason,
    'Powód odmowy',
    () => sampleCollect.refusalReason,
  );

  add(
    form,
    sampleCollect.cautionValues?.length || sampleCollect.cautionText,
    'Pouczenie',
    () =>
      UnorderedList({
        values: sampleCollect.cautionValues,
        freeValue: sampleCollect.cautionText,
      }),
  );

  return form;
};

export const newSampleCollect = () => {
  return CreateUpdateSampleCollectDtoFromJSON({});
};

export const validate = (dto: CreateUpdateSampleCollectDto) =>
  validateAgainst(SampleCollectSchema, dto);

export const duringInspectionBasisId = () => duringInspectionBasis.id;
