/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetInspectedProductDto,
    GetInspectedProductDtoFromJSON,
    GetInspectedProductDtoFromJSONTyped,
    GetInspectedProductDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
    GetProductTypeDto,
    GetProductTypeDtoFromJSON,
    GetProductTypeDtoFromJSONTyped,
    GetProductTypeDtoToJSON,
    GetSampleDto,
    GetSampleDtoFromJSON,
    GetSampleDtoFromJSONTyped,
    GetSampleDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPakDto
 */
export interface GetPakDto {
    /**
     * 
     * @type {string}
     * @memberof GetPakDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetPakDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetPakDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetPakDto
     */
    penaltyAmount: string;
    /**
     * 
     * @type {string}
     * @memberof GetPakDto
     */
    quantity: string;
    /**
     * 
     * @type {string}
     * @memberof GetPakDto
     */
    paidAmount: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPakDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetPakDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {string}
     * @memberof GetPakDto
     */
    proceedingId: string;
    /**
     * 
     * @type {GetSampleDto}
     * @memberof GetPakDto
     */
    sample: GetSampleDto;
    /**
     * 
     * @type {GetInspectedProductDto}
     * @memberof GetPakDto
     */
    inspectedProduct: GetInspectedProductDto;
    /**
     * 
     * @type {GetProductTypeDto}
     * @memberof GetPakDto
     */
    productType: GetProductTypeDto;
}

export function GetPakDtoFromJSON(json: any): GetPakDto {
    return GetPakDtoFromJSONTyped(json, false);
}

export function GetPakDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPakDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'penaltyAmount': json['penaltyAmount'],
        'quantity': json['quantity'],
        'paidAmount': json['paidAmount'],
        'approved': json['approved'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'proceedingId': json['proceedingId'],
        'sample': GetSampleDtoFromJSON(json['sample']),
        'inspectedProduct': GetInspectedProductDtoFromJSON(json['inspectedProduct']),
        'productType': GetProductTypeDtoFromJSON(json['productType']),
    };
}

export function GetPakDtoToJSON(value?: GetPakDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'penaltyAmount': value.penaltyAmount,
        'quantity': value.quantity,
        'paidAmount': value.paidAmount,
        'approved': value.approved,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'proceedingId': value.proceedingId,
        'sample': GetSampleDtoToJSON(value.sample),
        'inspectedProduct': GetInspectedProductDtoToJSON(value.inspectedProduct),
        'productType': GetProductTypeDtoToJSON(value.productType),
    };
}


