import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateMisstatementDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import { AddAssociateModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import FormRow from '../../../app/components/FormRow';

export const AddMisstatement = (
  props: AddAssociateModalProps<CreateUpdateMisstatementDto>,
) => {
  const [misstatement, setMisstatement] = useState(props.newAssociate());
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const setVariance = (variance: string) => {
    setMisstatement({ ...misstatement, varianceId: variance });
  };

  const onAdd = () => {
    props.validate(misstatement).then(result => {
      if (result.valid) {
        props.onCreate(misstatement);
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  useEffect(() => {
    setMisstatement(props.newAssociate());
    setSaveErrors([]);
    setShowSaveErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      size="xl"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Nowa nieprawidłowość</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="type" label="Typ">
            <DictionarySelector
              dictionary="nieprawidłowość"
              value={misstatement.varianceId}
              onChange={option => setVariance(option.value)}
            />
          </FormRow>
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        <Button variant="outline-primary" onClick={onAdd}>
          Dodaj
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
