/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSampleCollectExtendedDto
 */
export interface GetSampleCollectExtendedDto {
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    fileNumber: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetSampleCollectExtendedDto
     */
    collectBasis: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    collectPlace: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleCollectExtendedDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetOrganizationDto}
     * @memberof GetSampleCollectExtendedDto
     */
    organization: GetOrganizationDto;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    cautionText: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleCollectExtendedDto
     */
    cautionIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleCollectExtendedDto
     */
    cautionValues: Array<string>;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetSampleCollectExtendedDto
     */
    examOrganizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleCollectExtendedDto
     */
    signatureDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    placeOfSignature: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    refusalReason: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    attachments: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    protocols: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSampleCollectExtendedDto
     */
    actionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    witness: string;
    /**
     * 
     * @type {number}
     * @memberof GetSampleCollectExtendedDto
     */
    examsCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetSampleCollectExtendedDto
     */
    notApprovedResults: number;
    /**
     * 
     * @type {number}
     * @memberof GetSampleCollectExtendedDto
     */
    notApprovedProductTypes: number;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    publications: string;
    /**
     * 
     * @type {string}
     * @memberof GetSampleCollectExtendedDto
     */
    products: string;
}

export function GetSampleCollectExtendedDtoFromJSON(json: any): GetSampleCollectExtendedDto {
    return GetSampleCollectExtendedDtoFromJSONTyped(json, false);
}

export function GetSampleCollectExtendedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSampleCollectExtendedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fileNumber': json['fileNumber'],
        'collectBasis': GetDictionaryDtoFromJSON(json['collectBasis']),
        'collectPlace': json['collectPlace'],
        'approved': json['approved'],
        'organization': GetOrganizationDtoFromJSON(json['organization']),
        'cautionText': json['cautionText'],
        'cautionIds': json['cautionIds'],
        'cautionValues': json['cautionValues'],
        'examOrganizationalUnit': GetOrganizationalUnitDtoFromJSON(json['examOrganizationalUnit']),
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
        'placeOfSignature': json['placeOfSignature'],
        'refusalReason': json['refusalReason'],
        'attachments': json['attachments'],
        'protocols': json['protocols'],
        'actionDate': !exists(json, 'actionDate') ? undefined : (new Date(json['actionDate'])),
        'witness': json['witness'],
        'examsCount': json['examsCount'],
        'notApprovedResults': json['notApprovedResults'],
        'notApprovedProductTypes': json['notApprovedProductTypes'],
        'publications': json['publications'],
        'products': json['products'],
    };
}

export function GetSampleCollectExtendedDtoToJSON(value?: GetSampleCollectExtendedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileNumber': value.fileNumber,
        'collectBasis': GetDictionaryDtoToJSON(value.collectBasis),
        'collectPlace': value.collectPlace,
        'approved': value.approved,
        'organization': GetOrganizationDtoToJSON(value.organization),
        'cautionText': value.cautionText,
        'cautionIds': value.cautionIds,
        'cautionValues': value.cautionValues,
        'examOrganizationalUnit': GetOrganizationalUnitDtoToJSON(value.examOrganizationalUnit),
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString()),
        'placeOfSignature': value.placeOfSignature,
        'refusalReason': value.refusalReason,
        'attachments': value.attachments,
        'protocols': value.protocols,
        'actionDate': value.actionDate === undefined ? undefined : (value.actionDate.toISOString()),
        'witness': value.witness,
        'examsCount': value.examsCount,
        'notApprovedResults': value.notApprovedResults,
        'notApprovedProductTypes': value.notApprovedProductTypes,
        'publications': value.publications,
        'products': value.products,
    };
}


