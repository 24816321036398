import React, { useRef, useState } from 'react';
import {
  EditorState,
  Modifier,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
} from 'draft-js';
import {
  createInlineStyleButton,
  createBlockStyleButton,
} from '@draft-js-plugins/buttons';
// eslint-disable-next-line import/no-unresolved
import { DraftJsStyleButtonProps } from '@draft-js-plugins/buttons/lib/index';
import { Button, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { CardImage } from 'react-bootstrap-icons';

import FilePickerModal from '../FilePickerModal';

import { insertImageIntoEditor, ACCEPTED_MIMES_TYPES } from './ImageHelpers';

const stroke: {
  fill: string;
  stroke: string;
  strokeLinecap: 'round';
  strokeLinejoin: 'round';
  strokeWidth: number;
} = {
  fill: 'none',
  stroke: '#444',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: 2,
};

const thin = { strokeWidth: 1 };
const fill = { fill: 'black' };
const even: { fillRule: 'evenodd' } = { fillRule: 'evenodd' };

const delay = { show: 250, hide: 0 };

const BoldButton = (props: DraftJsStyleButtonProps) => {
  const Btn = createInlineStyleButton({
    style: 'BOLD',
    children: (
      <OverlayTrigger
        delay={delay}
        trigger={props.buttonProps?.disabled ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Pogrubienie (ctrl+b)</Tooltip>}
      >
        <svg viewBox="0 0 18 18" height="18" width="18">
          <path
            {...stroke}
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          />
          <path
            {...stroke}
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          />
        </svg>
      </OverlayTrigger>
    ),
  });

  return <Btn {...props} />;
};

const ItalicButton = (props: DraftJsStyleButtonProps) => {
  const Btn = createInlineStyleButton({
    style: 'ITALIC',
    children: (
      <OverlayTrigger
        delay={delay}
        trigger={props.buttonProps?.disabled ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Kursywa (ctrl+i)</Tooltip>}
      >
        <svg viewBox="0 0 18 18" height="18" width="18">
          <line {...stroke} x1="7" x2="13" y1="4" y2="4"></line>
          <line {...stroke} x1="5" x2="11" y1="14" y2="14"></line>
          <line {...stroke} x1="8" x2="10" y1="14" y2="4"></line>
        </svg>
      </OverlayTrigger>
    ),
  });

  return <Btn {...props} />;
};

const UnderlineButton = (props: DraftJsStyleButtonProps) => {
  const Btn = createInlineStyleButton({
    style: 'UNDERLINE',
    children: (
      <OverlayTrigger
        delay={delay}
        trigger={props.buttonProps?.disabled ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Podkreślenie (ctrl+u)</Tooltip>}
      >
        <svg viewBox="0 0 18 18" height="18" width="18">
          <path
            {...stroke}
            d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"
          ></path>
          <rect height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect>
        </svg>
      </OverlayTrigger>
    ),
  });

  return <Btn {...props} />;
};

const StrikethroughButton = (props: DraftJsStyleButtonProps) => {
  const Btn = createInlineStyleButton({
    style: 'STRIKETHROUGH',
    children: (
      <OverlayTrigger
        delay={delay}
        trigger={props.buttonProps?.disabled ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Przekreślenie (ctrl+j)</Tooltip>}
      >
        <svg viewBox="0 0 18 18" height="18" width="18">
          <line
            {...{ ...stroke, ...thin }}
            x1="15.5"
            x2="2.5"
            y1="8.5"
            y2="9.5"
          />
          <path
            {...fill}
            d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"
          ></path>
          <path
            {...fill}
            d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"
          ></path>
        </svg>
      </OverlayTrigger>
    ),
  });

  return <Btn {...props} />;
};

const UnorderedListButton = (props: DraftJsStyleButtonProps) => {
  const Btn = createBlockStyleButton({
    blockType: 'unordered-list-item',
    children: (
      <OverlayTrigger
        delay={delay}
        trigger={props.buttonProps?.disabled ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Lista nienumerowana (ctrl+shift+8)</Tooltip>}
      >
        <svg viewBox="0 0 18 18" height="18" width="18">
          <line {...stroke} x1="6" x2="15" y1="4" y2="4"></line>
          <line {...stroke} x1="6" x2="15" y1="9" y2="9"></line>
          <line {...stroke} x1="6" x2="15" y1="14" y2="14"></line>
          <line {...stroke} x1="3" x2="3" y1="4" y2="4"></line>
          <line {...stroke} x1="3" x2="3" y1="9" y2="9"></line>
          <line {...stroke} x1="3" x2="3" y1="14" y2="14"></line>
        </svg>
      </OverlayTrigger>
    ),
  });

  return <Btn {...props} />;
};

const OrderedListButton = (props: DraftJsStyleButtonProps) => {
  const Btn = createBlockStyleButton({
    blockType: 'ordered-list-item',
    children: (
      <OverlayTrigger
        delay={delay}
        trigger={props.buttonProps?.disabled ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Lista numerowana (ctrl+shift+7)</Tooltip>}
      >
        <svg viewBox="0 0 18 18" height="18" width="18">
          <line {...stroke} x1="7" x2="15" y1="4" y2="4"></line>
          <line {...stroke} x1="7" x2="15" y1="9" y2="9"></line>
          <line {...stroke} x1="7" x2="15" y1="14" y2="14"></line>
          <line
            {...{ ...stroke, ...thin }}
            x1="2.5"
            x2="4.5"
            y1="5.5"
            y2="5.5"
          ></line>
          <path d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path>
          <path
            {...{ ...stroke, ...thin }}
            d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
          ></path>
          <path
            {...{ ...stroke, ...thin }}
            d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
          ></path>
        </svg>
      </OverlayTrigger>
    ),
  });

  return <Btn {...props} />;
};

const HeadersSelect = (props: {
  readonly?: boolean;
  editorState: EditorState;
  setState: (state: EditorState) => void;
  focus: () => void;
}) => {
  const currentType = props.editorState
    .getCurrentContent()
    .getBlockForKey(props.editorState.getSelection().getStartKey())
    .getType();

  return (
    <OverlayTrigger
      delay={delay}
      trigger={props.readonly ? [] : ['focus', 'hover']}
      overlay={<Tooltip>Rozmiar akapitu</Tooltip>}
    >
      <select
        disabled={props.readonly}
        value={currentType?.startsWith('header') ? currentType : 'none'}
        className="rte-headers"
        onChange={event => {
          const blockType = event.target.value;
          props.setState(
            RichUtils.toggleBlockType(props.editorState, blockType),
          );
          setTimeout(() => props.focus());
        }}
        onBlur={undefined}
      >
        <option value="none">Normalny</option>
        <option className="rte-header-1" value="header-one">
          Nagłówek 1
        </option>
        <option className="rte-header-2" value="header-two">
          Nagłówek 2
        </option>
        <option className="rte-header-3" value="header-three">
          Nagłówek 3
        </option>
      </select>
    </OverlayTrigger>
  );
};

const InsertLinkButton = (props: {
  hasLinkSelected: boolean;
  onRemoveLinkAtSelection(): void;
  onAddLinkClick(event: unknown): void;
  theme?: {
    button?: string;
    active?: string;
    buttonWrapper?: string;
  };
}) => {
  const disabled = props.theme?.button === 'disabled'; // hack
  return (
    <OverlayTrigger
      delay={delay}
      trigger={disabled ? [] : ['focus', 'hover']}
      overlay={<Tooltip>Wstaw link dla zaznaczonego tekstu</Tooltip>}
    >
      <button
        className={
          'rte-toolbar-button' +
          (props.hasLinkSelected ? ' rte-toolbar-button-active' : '')
        }
        onClick={
          props.hasLinkSelected
            ? props.onRemoveLinkAtSelection
            : props.onAddLinkClick
        }
        disabled={disabled}
      >
        <svg viewBox="0 0 18 18">
          <line {...stroke} x1="7" x2="11" y1="7" y2="11" />
          <path
            {...{ ...even, ...stroke }}
            d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"
          />
          <path
            {...{ ...even, ...stroke }}
            d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"
          />
        </svg>
      </button>
    </OverlayTrigger>
  );
};

const AddImageButton = (props: {
  editorState: EditorState;
  readonly?: boolean;
  setState: (state: EditorState) => void;
  focus: () => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const show = () => {
    if (props.readonly) {
      return;
    }

    buttonRef.current?.blur();
    setShowModal(true);
  };

  return (
    <>
      <OverlayTrigger
        delay={delay}
        trigger={props.readonly ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Wstaw obraz</Tooltip>}
      >
        <button
          ref={buttonRef}
          type="button"
          className="rte-toolbar-button"
          disabled={props.readonly}
          onClick={show}
          onKeyPress={show}
        >
          <CardImage className="rte-add-image" />
        </button>
      </OverlayTrigger>
      <FilePickerModal
        show={showModal}
        onHide={() => setShowModal(false)}
        accept={ACCEPTED_MIMES_TYPES.join(',')}
        onFile={base64 => {
          setShowModal(false);
          props.setState(insertImageIntoEditor(props.editorState, base64));
          props.focus();
        }}
      />
    </>
  );
};

const SubscriptButton = (
  props: DraftJsStyleButtonProps & { className?: string },
) => {
  const { className, ...baseProps } = props;
  const InlineStyle = createInlineStyleButton({
    style: 'SUBSCRIPT',
    children: (
      <span>
        x<sub>y</sub>
      </span>
    ),
  });

  return (
    <OverlayTrigger delay={delay} overlay={<Tooltip>Indeks dolny</Tooltip>}>
      <div className={className}>
        <InlineStyle {...baseProps} />
      </div>
    </OverlayTrigger>
  );
};

const SubButton = (props: DraftJsStyleButtonProps) => {
  const Btn = createInlineStyleButton({
    style: 'SUBSCRIPT',
    children: (
      <OverlayTrigger
        delay={delay}
        trigger={props.buttonProps?.disabled ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Indeks dolny (ctrl+,)</Tooltip>}
      >
        <svg viewBox="0 0 18 18" height="18" width="18">
          <path
            {...fill}
            d="M15.5,15H13.861a3.858,3.858,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.921,1.921,0,0,0,12.021,11.7a0.50013,0.50013,0,1,0,.957.291h0a0.914,0.914,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.076-1.16971,1.86982-1.93971,2.43082A1.45639,1.45639,0,0,0,12,15.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,15Z"
          />
          <path
            {...fill}
            d="M9.65,5.241a1,1,0,0,0-1.409.108L6,7.964,3.759,5.349A1,1,0,0,0,2.192,6.59178Q2.21541,6.6213,2.241,6.649L4.684,9.5,2.241,12.35A1,1,0,0,0,3.71,13.70722q0.02557-.02768.049-0.05722L6,11.036,8.241,13.65a1,1,0,1,0,1.567-1.24277Q9.78459,12.3777,9.759,12.35L7.316,9.5,9.759,6.651A1,1,0,0,0,9.65,5.241Z"
          />
        </svg>
      </OverlayTrigger>
    ),
  });

  const setStyle = (event: { preventDefault: () => void }) => {
    if (!props.getEditorState) {
      return;
    }
    event.preventDefault();

    let state = RichUtils.toggleInlineStyle(
      props.getEditorState(),
      'SUBSCRIPT',
    );

    if (props.getEditorState().getCurrentInlineStyle().has('SUPERSCRIPT')) {
      state = RichUtils.toggleInlineStyle(state, 'SUPERSCRIPT');
    }
    props.setEditorState(state);
  };

  return (
    <div onClick={setStyle} onKeyPress={setStyle} role="button" tabIndex={0}>
      <Btn {...props} />
    </div>
  );
};

const SuperscriptButton = (
  props: DraftJsStyleButtonProps & { className?: string },
) => {
  const { className, ...baseProps } = props;
  const InlineStyle = createInlineStyleButton({
    style: 'SUPERSCRIPT',
    children: (
      <span>
        x<sup>y</sup>
      </span>
    ),
  });

  return (
    <OverlayTrigger delay={delay} overlay={<Tooltip>Indeks górny</Tooltip>}>
      <div className={className}>
        <InlineStyle {...baseProps} />
      </div>
    </OverlayTrigger>
  );
};

const SupButton = (props: DraftJsStyleButtonProps) => {
  const Btn = createInlineStyleButton({
    style: 'SUPERSCRIPT',
    children: (
      <OverlayTrigger
        delay={delay}
        trigger={props.buttonProps?.disabled ? [] : ['focus', 'hover']}
        overlay={<Tooltip>Indeks górny (ctrl+.)</Tooltip>}
      >
        <svg viewBox="0 0 18 18" height="18" width="18">
          <path
            {...fill}
            d="M15.5,7H13.861a4.015,4.015,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.922,1.922,0,0,0,12.021,3.7a0.5,0.5,0,1,0,.957.291,0.917,0.917,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.077-1.164,1.925-1.934,2.486A1.423,1.423,0,0,0,12,7.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,7Z"
          />
          <path
            {...fill}
            d="M9.651,5.241a1,1,0,0,0-1.41.108L6,7.964,3.759,5.349a1,1,0,1,0-1.519,1.3L4.683,9.5,2.241,12.35a1,1,0,1,0,1.519,1.3L6,11.036,8.241,13.65a1,1,0,0,0,1.519-1.3L7.317,9.5,9.759,6.651A1,1,0,0,0,9.651,5.241Z"
          />
        </svg>
      </OverlayTrigger>
    ),
  });

  const setStyle = (event: { preventDefault: () => void }) => {
    if (!props.getEditorState) {
      return;
    }
    event.preventDefault();

    let state = RichUtils.toggleInlineStyle(
      props.getEditorState(),
      'SUPERSCRIPT',
    );

    if (props.getEditorState().getCurrentInlineStyle().has('SUBSCRIPT')) {
      state = RichUtils.toggleInlineStyle(state, 'SUBSCRIPT');
    }
    props.setEditorState(state);
  };

  return (
    <div onClick={setStyle} onKeyPress={setStyle} role="button" tabIndex={0}>
      <Btn {...props} />
    </div>
  );
};

const LambdaButton = (props: {
  variant: 'black' | 'green';
  className?: string;
  editorState: EditorState;
  setState: (state: EditorState) => void;
  focus: () => void;
}) => {
  const insert = (characterToInsert: string) => {
    const currentContent = props.editorState.getCurrentContent();
    const currentSelection = props.editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      characterToInsert,
    );

    props.setState(
      EditorState.push(props.editorState, newContent, 'insert-characters'),
    );
    setTimeout(props.focus);
  };

  return (
    <div className={props.className}>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        overlay={
          <Popover>
            <Popover.Header as="h3">Symbole specjalne</Popover.Header>
            <Popover.Body>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('λ')}
              >
                λ
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('α')}
              >
                α
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('β')}
              >
                β
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('Ω')}
              >
                Ω
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('φ')}
              >
                φ
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('π')}
              >
                π
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('§')}
              >
                §
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('≥')}
              >
                ≥
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('≤')}
              >
                ≤
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('±')}
              >
                ±
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('–')}
              >
                –
              </Button>
              <Button
                variant="outline-success"
                className="me-1"
                onClick={() => insert('—')}
              >
                —
              </Button>
              <Button variant="outline-success" onClick={() => insert('°')}>
                °
              </Button>
            </Popover.Body>
          </Popover>
        }
      >
        <span>
          <OverlayTrigger
            delay={delay}
            placement="top"
            overlay={<Tooltip>Wstaw symbol specjalny</Tooltip>}
          >
            {props.variant === 'green' ? (
              <Button variant="outline-success">λφ</Button>
            ) : (
              <button className="rte-toolbar-button" type="button">
                <b>λφ</b>
              </button>
            )}
          </OverlayTrigger>
        </span>
      </OverlayTrigger>
    </div>
  );
};

const inlineStyle: Record<string, string> = {
  b: 'BOLD',
  i: 'ITALIC',
  u: 'UNDERLINE',
  j: 'STRIKETHROUGH',
  '.': 'SUPERSCRIPT',
  ',': 'SUBSCRIPT',
};

const blockType: Record<string, string> = {
  '&': 'ordered-list-item',
  '*': 'unordered-list-item',
};

const MAX_LIST_DEPTH = 6;

const keyHandler = (
  event: React.KeyboardEvent<Element>,
  value: EditorState,
  setValue: (state: EditorState) => void,
) => {
  if (KeyBindingUtil.hasCommandModifier(event)) {
    if (inlineStyle[event.key]) {
      let state = RichUtils.toggleInlineStyle(value, inlineStyle[event.key]);
      // subscript OR superscript, never together
      if (
        inlineStyle[event.key] === 'SUBSCRIPT' &&
        state.getCurrentInlineStyle().has('SUPERSCRIPT')
      ) {
        state = RichUtils.toggleInlineStyle(state, 'SUPERSCRIPT');
      } else if (
        inlineStyle[event.key] === 'SUPERSCRIPT' &&
        state.getCurrentInlineStyle().has('SUBSCRIPT')
      ) {
        state = RichUtils.toggleInlineStyle(state, 'SUBSCRIPT');
      }
      setValue(state);
    } else if (blockType[event.key]) {
      setValue(RichUtils.toggleBlockType(value, blockType[event.key]));
    }
  }
  if (event.key === 'Tab') {
    setValue(RichUtils.onTab(event, value, MAX_LIST_DEPTH));
  }
  return getDefaultKeyBinding(event);
};

export {
  HeadersSelect,
  BoldButton,
  ItalicButton,
  UnderlineButton,
  StrikethroughButton,
  UnorderedListButton,
  OrderedListButton,
  InsertLinkButton,
  AddImageButton,
  LambdaButton,
  SubscriptButton,
  SuperscriptButton,
  SubButton,
  SupButton,
  keyHandler,
};
