import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateAttachmentDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import FormRow from '../../../app/components/FormRow';
import { AddAssociateModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { Option } from '../Selects/Selector';

export const AddAttachment = (
  props: AddAssociateModalProps<CreateUpdateAttachmentDto>,
) => {
  const [emptyAttachment] = useState(props.newAssociate());
  const [attachment, setAttachment] = useState(
    AttachmentContext.newAttachment(),
  );
  const [typeName, setTypeName] = useState('');
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const setType = (type: Option) => {
    setAttachment({ ...attachment, typeId: type.value });
    setTypeName(type.label);
  };

  const setDescription = (description: string) => {
    setAttachment({ ...attachment, description: description });
  };

  const setFile = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }
    const f: File = files[0];
    const reader = new FileReader();
    const name = f.name;

    reader.onload = function (e) {
      if (e.target) {
        const target: FileReader = e.target;
        if (target.result) {
          attachment.file = Buffer.from(target.result as ArrayBuffer);
          attachment.name = name;
          attachment.date = new Date();
          setAttachment(attachment);
        }
      }
    };
    reader.readAsArrayBuffer(f);
  };

  const onAdd = () => {
    const template = props.newAssociate();
    attachment.enObjectType = template.enObjectType;
    attachment.objectId = template.objectId;
    props.validate(attachment).then(result => {
      if (result.valid) {
        props.onCreate(attachment, { type: typeName });
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  useEffect(() => {
    setAttachment(emptyAttachment);
    setSaveErrors([]);
    setShowSaveErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const typeReadonly =
    emptyAttachment.typeId !== undefined &&
    attachment.typeId !== undefined &&
    !attachment.typeId.startsWith('?');

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      className="modal-on-modal"
      backdropClassName="modal-on-modal-backdrop"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Nowy załącznik</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="type" label="Typ">
            <DictionarySelector
              readOnly={typeReadonly}
              dictionary="załącznik"
              value={attachment.typeId}
              onChange={option => setType(option)}
            />
          </FormRow>
          <FormRow controlId="file" label="Plik">
            <Form.Control
              type="file"
              onChange={event =>
                setFile((event.currentTarget as HTMLInputElement).files)
              }
            />
          </FormRow>
          <FormRow controlId="description" label="Opis">
            <Form.Control
              type="text"
              onChange={event => setDescription(event.target.value)}
            />
          </FormRow>
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        <Button variant="outline-primary" onClick={onAdd}>
          Dodaj
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AddAttachmentMemoized = (
  props: AddAssociateModalProps<AttachmentContext.MemoizedAttachment>,
) => {
  const newAssociate = () => {
    const memoized = props.newAssociate();
    if (!memoized.newOne) {
      throw new Error('New attachment generator problem!');
    }
    return memoized.newOne;
  };
  const create = (
    cuDto: CreateUpdateAttachmentDto,
    opt?: Record<string, string>,
  ) => {
    props.onCreate({
      id: `${cuDto.date?.getTime()}`,
      type: opt?.type,
      newOne: cuDto,
    });
  };
  const validate = (cuDto: CreateUpdateAttachmentDto) => {
    return props.validate({
      id: `${cuDto.date?.getTime()}`,
      newOne: cuDto,
    });
  };
  return (
    <AddAttachment
      {...props}
      newAssociate={newAssociate}
      onCreate={create}
      validate={validate}
    />
  );
};
