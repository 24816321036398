/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateInspectionPlanDto
 */
export interface CreateUpdateInspectionPlanDto {
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectionPlanDto
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectionPlanDto
     */
    numberOfProducts: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectionPlanDto
     */
    numberOfProductsCpr: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectionPlanDto
     */
    numberOfInspections: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectionPlanDto
     */
    numberOfInspectionsCpr: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectionPlanDto
     */
    numberOfStaffs: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionPlanDto
     */
    comments: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionPlanDto
     */
    status: CreateUpdateInspectionPlanDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionPlanDto
     */
    organizationalUnitId: string;
}

export function CreateUpdateInspectionPlanDtoFromJSON(json: any): CreateUpdateInspectionPlanDto {
    return CreateUpdateInspectionPlanDtoFromJSONTyped(json, false);
}

export function CreateUpdateInspectionPlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInspectionPlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'numberOfProducts': json['numberOfProducts'],
        'numberOfProductsCpr': json['numberOfProductsCpr'],
        'numberOfInspections': json['numberOfInspections'],
        'numberOfInspectionsCpr': json['numberOfInspectionsCpr'],
        'numberOfStaffs': json['numberOfStaffs'],
        'comments': json['comments'],
        'status': json['status'],
        'organizationalUnitId': json['organizationalUnitId'],
    };
}

export function CreateUpdateInspectionPlanDtoToJSON(value?: CreateUpdateInspectionPlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'numberOfProducts': value.numberOfProducts,
        'numberOfProductsCpr': value.numberOfProductsCpr,
        'numberOfInspections': value.numberOfInspections,
        'numberOfInspectionsCpr': value.numberOfInspectionsCpr,
        'numberOfStaffs': value.numberOfStaffs,
        'comments': value.comments,
        'status': value.status,
        'organizationalUnitId': value.organizationalUnitId,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateInspectionPlanDtoStatusEnum {
    Niezatwierdzony = 'niezatwierdzony',
    ZatwierdzonyPrzezWINB = 'zatwierdzony przez WINB',
    ZatwierdzonyPrzezGINB = 'zatwierdzony przez GINB'
}


