import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type FormRowProps = React.PropsWithChildren<{
  controlId: string;
  label: React.ReactNode;
  static?: boolean;
  labelSize?: number;
  valueSize?: number;
  alignRight?: boolean;
  alignControlLeft?: boolean;
  className?: string;
}>;

const FormRowBase = (props: FormRowProps) => {
  return (
    <Form.Group
      as={Row}
      controlId={props.controlId}
      className={props.className}
    >
      <Form.Label column sm={props.labelSize || 3}>
        <div
          className={props.alignRight ? 'd-flex justify-content-end' : 'd-flex'}
        >
          {props.label}
        </div>
      </Form.Label>
      <Col
        sm={props.valueSize || 9}
        className={props.static ? 'col-form-static' : ''}
      >
        {typeof props.children === 'string' ? (
          <div
            className={
              props.alignRight && !props.alignControlLeft
                ? 'd-flex justify-content-end'
                : undefined
            }
            dangerouslySetInnerHTML={{
              __html: props.children,
            }}
          ></div>
        ) : (
          <div
            className={
              props.alignRight && !props.alignControlLeft
                ? 'd-flex justify-content-end'
                : undefined
            }
          >
            {props.children}
          </div>
        )}
      </Col>
    </Form.Group>
  );
};

export const FormRow = (props: FormRowProps) => (
  <FormRowBase {...props} className="overflowx-clip" />
);

export const FormRow48 = (props: FormRowProps) => (
  <FormRowBase {...props} labelSize={4} valueSize={8} />
);

export const FormRow66 = (props: FormRowProps) => (
  <FormRowBase {...props} labelSize={6} valueSize={6} />
);

export const FormRow66R = (props: FormRowProps) => (
  <FormRowBase {...props} labelSize={6} valueSize={6} alignRight={true} />
);

export const FormRow64 = (props: FormRowProps) => (
  <FormRowBase {...props} labelSize={6} valueSize={4} />
);

export const FormRow84R = (props: FormRowProps) => (
  <FormRowBase {...props} labelSize={8} valueSize={4} alignRight={true} />
);

export const FormRow48R = (props: FormRowProps) => (
  <FormRowBase {...props} labelSize={4} valueSize={8} alignRight={true} />
);

export const FormRow57R = (props: FormRowProps) => (
  <FormRowBase {...props} labelSize={5} valueSize={7} alignRight={true} />
);

export default FormRow;
