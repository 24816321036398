import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportBySampleExam } from '../../../components/ReportBySampleExam/ReportBySampleExam';
import { ReportOrderedSampleExamsType } from '../../../../common/types/ReportTypes';

export const ReportSampleExamBView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportOrderedSampleExamsType>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports
      .reportOrderedSampleExams(true, filter)
      .then(dataReports => {
        setData(dataReports);
      });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Badania próbek wyrobów budowlanych CPR"
      />
      <ReportBySampleExam
        data={data}
        reportName="Zlecone badania próbek wyrobów budowlanych CPR - bez badania próbek kontrolnych"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
