/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetInspectionMetricDto,
    GetInspectionMetricDtoFromJSON,
    GetInspectionMetricDtoFromJSONTyped,
    GetInspectionMetricDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetInspectionDto
 */
export interface GetInspectionDto {
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetInspectionDto
     */
    cpr: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    witnesses: string;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    findingsBeginning: string;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    findingsEnd: string;
    /**
     * 
     * @type {Date}
     * @memberof GetInspectionDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    protocols: string;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    attachments: string;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    repairMethod: string;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    placeOfSignature: string;
    /**
     * 
     * @type {Date}
     * @memberof GetInspectionDto
     */
    signatureDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    refusalReason: string;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionDto
     */
    cautionText: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionDto
     */
    cautionIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInspectionDto
     */
    cautionValues: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetInspectionDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetInspectionMetricDto}
     * @memberof GetInspectionDto
     */
    metric: GetInspectionMetricDto;
}

export function GetInspectionDtoFromJSON(json: any): GetInspectionDto {
    return GetInspectionDtoFromJSONTyped(json, false);
}

export function GetInspectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInspectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'cpr': json['cpr'],
        'witnesses': json['witnesses'],
        'findingsBeginning': json['findingsBeginning'],
        'findingsEnd': json['findingsEnd'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'notes': json['notes'],
        'protocols': json['protocols'],
        'attachments': json['attachments'],
        'repairMethod': json['repairMethod'],
        'placeOfSignature': json['placeOfSignature'],
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
        'refusalReason': json['refusalReason'],
        'cautionText': json['cautionText'],
        'cautionIds': json['cautionIds'],
        'cautionValues': json['cautionValues'],
        'approved': json['approved'],
        'metric': GetInspectionMetricDtoFromJSON(json['metric']),
    };
}

export function GetInspectionDtoToJSON(value?: GetInspectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cpr': value.cpr,
        'witnesses': value.witnesses,
        'findingsBeginning': value.findingsBeginning,
        'findingsEnd': value.findingsEnd,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'notes': value.notes,
        'protocols': value.protocols,
        'attachments': value.attachments,
        'repairMethod': value.repairMethod,
        'placeOfSignature': value.placeOfSignature,
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString()),
        'refusalReason': value.refusalReason,
        'cautionText': value.cautionText,
        'cautionIds': value.cautionIds,
        'cautionValues': value.cautionValues,
        'approved': value.approved,
        'metric': GetInspectionMetricDtoToJSON(value.metric),
    };
}


