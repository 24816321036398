import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { Inspection } from '../../components/Inspections/Inspection';
import * as InspectionContext from '../../../common/snrwbCore/contexts/InspectionContext';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import * as MisstatementContext from '../../../common/snrwbCore/contexts/MisstatementContext';
import * as CorrectiveActionContext from '../../../common/snrwbCore/contexts/CorrectiveActionContext';
import * as JudgmentContext from '../../../common/snrwbCore/contexts/JudgmentContext';
import * as ProtocolDuringInspectionContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import * as InspectorContext from '../../../common/snrwbCore/contexts/InspectorContext';
import {
  GetInspectionDto,
  GetInspectionDtoFromJSON,
  GetInspectedProductDto,
  GetAttachmentDto,
  GetMisstatementDto,
  GetCorrectiveActionDto,
  GetJudgmentDto,
  GetInspectorDto,
  CreateUpdateAttachmentDto,
  CreateUpdateMisstatementDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateJudgmentDto,
  CreateUpdateInspectorDto,
  GetProceedingDto,
  GetPakDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { AssociateDocuments } from '../../components/AssociatedDocuments/AssociateDocumentsTypes';
import { useNavigation } from '../../../common/navigation';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useAuth } from '../../../common/hooks/useAuth';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useCookies } from '../../../common/hooks/useCookies';

interface ParamTypes {
  id: string;
  inspectedProductId: string;
  bannerId: string;
}

export const InspectionView: React.FC = () => {
  const auth = useAuth();
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const cookies = useCookies();
  const notifications = useNotifications();
  const { id, inspectedProductId, bannerId } = useParams<ParamTypes>();
  const mountedRef = useRef(false);
  const [inspection, setInspection] = useState<GetInspectionDto>(
    GetInspectionDtoFromJSON({}),
  );
  const [inspectedProducts, setInspectedProducts] =
    useState<GetInspectedProductDto[]>();
  const [proceedings, setProceedings] = useState<GetProceedingDto[]>();
  const [attachments, setAttachments] = useState<
    AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>
  >(AttachmentContext.forAssociatedDocuments(snrwb, notifications, []));
  const [misstatements, setMisstatements] = useState<
    AssociateDocuments<GetMisstatementDto, CreateUpdateMisstatementDto>
  >(MisstatementContext.forAssociatedDocuments(snrwb, notifications, []));
  const [correctiveActions, setCorrectiveActions] = useState<
    AssociateDocuments<GetCorrectiveActionDto, CreateUpdateCorrectiveActionDto>
  >(CorrectiveActionContext.forAssociatedDocuments(snrwb, notifications, []));
  const [judgments, setJudgments] = useState<
    AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>
  >(JudgmentContext.forAssociatedDocuments(snrwb, notifications, []));
  const [protocols, setProtocols] = useState<
    AssociateDocuments<
      ProtocolDuringInspectionContext.ProtocolWithAttachments,
      ProtocolDuringInspectionContext.CreateUpdateProtocolWithAttachments
    >
  >(
    ProtocolDuringInspectionContext.forAssociatedDocuments(
      snrwb,
      notifications,
      [],
    ),
  );
  const [inspectors, setInspectors] = useState<
    AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>
  >(InspectorContext.forAssociatedDocuments(snrwb, notifications, []));
  const [paks, setPaks] = useState<GetPakDto[]>([]);
  const [activeProduct, setActiveProduct] = useState<string | undefined>();
  const [errors, setErrors] = useState<string[]>([]);

  const unitsMatch =
    inspection.metric?.organizationalUnit?.id ===
    cookies.organizationalUnit?.id;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setIfDefined = (obj: any, set: (value: any) => void) => obj && set(obj);

  const refresh = useCallback(
    (modelGenerator?: InspectionContext.ModelGeneratorFunc) => {
      const generator = modelGenerator || InspectionContext.forInspectionView;
      generator(snrwb, id)
        .then(details => {
          if (!mountedRef.current) {
            return;
          }
          const ac = AttachmentContext.forAssociatedDocuments;
          const mi = MisstatementContext.forAssociatedDocuments;
          const ca = CorrectiveActionContext.forAssociatedDocuments;
          const ju = JudgmentContext.forAssociatedDocuments;
          const pd = ProtocolDuringInspectionContext.forAssociatedDocuments;
          const ic = InspectorContext.forAssociatedDocuments;

          setIfDefined(details.inspection, setInspection);
          setIfDefined(details.products, setInspectedProducts);
          setIfDefined(details.proceedings, setProceedings);
          if (details.attachments) {
            setAttachments(
              ac(snrwb, notifications, details.attachments, refresh),
            );
          }
          if (details.misstatements) {
            setMisstatements(
              mi(snrwb, notifications, details.misstatements, refresh),
            );
          }
          if (details.correctiveActions) {
            setCorrectiveActions(
              ca(snrwb, notifications, details.correctiveActions, refresh),
            );
          }
          if (details.judgments) {
            setJudgments(ju(snrwb, notifications, details.judgments, refresh));
          }
          if (details.protocols) {
            setProtocols(pd(snrwb, notifications, details.protocols, refresh));
          }
          if (details.inspectors) {
            const creator = InspectorContext.creatorForInspection(id);
            setInspectors(
              ic(snrwb, notifications, details.inspectors, creator, refresh),
            );
          }
          details.paks && setPaks(details.paks);
        })
        .catch(() => {
          notifications.badLink(
            'Brak kontroli w bazie danych. Mogła zostać przed chwilą usunięta.',
            '/kontrole',
          );
        });
    },
    [snrwb, notifications, id],
  );

  useEffect(() => {
    mountedRef.current = true;
    snrwb.sampleCollects.setDuringInspectionDto().then(() => {
      refresh();
    });
    return () => {
      mountedRef.current = false;
    };
  }, [id, refresh, snrwb.sampleCollects]);

  return (
    <Inspection
      editAuthorized={auth.check(Role.InspectionsEdit) && unitsMatch}
      validOrganizationalUnit={unitsMatch}
      inspection={inspection}
      banner={inspectedProductId ? { inspectedProductId, bannerId } : undefined}
      products={inspectedProducts}
      proceedings={proceedings}
      attachments={attachments}
      misstatements={misstatements}
      correctiveActions={correctiveActions}
      judgments={judgments}
      protocols={protocols}
      inspectors={inspectors}
      paks={paks}
      activeProduct={activeProduct}
      errors={errors}
      api={InspectionContext.handlersForInspectionView(
        snrwb,
        notifications,
        inspection,
        refresh,
        nav.myInspections,
        setActiveProduct,
        nav.sampleExam,
        nav.proceeding,
        nav.pak,
        setErrors,
      )}
    />
  );
};
