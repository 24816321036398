/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetJudgmentDto,
    GetJudgmentDtoFromJSON,
    GetJudgmentDtoFromJSONTyped,
    GetJudgmentDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
    GetPartyToProceedingDto,
    GetPartyToProceedingDtoFromJSON,
    GetPartyToProceedingDtoFromJSONTyped,
    GetPartyToProceedingDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetKwzWithPartiesDto
 */
export interface GetKwzWithPartiesDto {
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetKwzWithPartiesDto
     */
    publicationNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof GetKwzWithPartiesDto
     */
    publicationDate?: Date;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetKwzWithPartiesDto
     */
    decisionSign: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetKwzWithPartiesDto
     */
    decisionIndex: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    productionCountry: string;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    incompatibility: string;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    danger: string;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    buyBackOrder: string;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    destructionOrder: string;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    other: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetKwzWithPartiesDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    tariffCode: string;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetKwzWithPartiesDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {GetJudgmentDto}
     * @memberof GetKwzWithPartiesDto
     */
    judgment: GetJudgmentDto;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    productBatchNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    withdrawalInfo: string;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    removalIncompatibility: string;
    /**
     * 
     * @type {string}
     * @memberof GetKwzWithPartiesDto
     */
    stopActivity: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetKwzWithPartiesDto
     */
    request: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetKwzWithPartiesDto
     */
    requestApproved: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetKwzWithPartiesDto
     */
    requestDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetKwzWithPartiesDto
     */
    removePublishDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetKwzWithPartiesDto
     */
    approvedDate?: Date | null;
    /**
     * 
     * @type {Array<GetPartyToProceedingDto>}
     * @memberof GetKwzWithPartiesDto
     */
    parties: Array<GetPartyToProceedingDto>;
}

export function GetKwzWithPartiesDtoFromJSON(json: any): GetKwzWithPartiesDto {
    return GetKwzWithPartiesDtoFromJSONTyped(json, false);
}

export function GetKwzWithPartiesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetKwzWithPartiesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'publicationNumber': json['publicationNumber'],
        'publicationDate': !exists(json, 'publicationDate') ? undefined : (new Date(json['publicationDate'])),
        'decisionSign': GetDictionaryDtoFromJSON(json['decisionSign']),
        'decisionIndex': GetDictionaryDtoFromJSON(json['decisionIndex']),
        'productionCountry': json['productionCountry'],
        'incompatibility': json['incompatibility'],
        'danger': json['danger'],
        'buyBackOrder': json['buyBackOrder'],
        'destructionOrder': json['destructionOrder'],
        'other': json['other'],
        'approved': json['approved'],
        'tariffCode': json['tariffCode'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'judgment': GetJudgmentDtoFromJSON(json['judgment']),
        'productBatchNumber': json['productBatchNumber'],
        'withdrawalInfo': json['withdrawalInfo'],
        'removalIncompatibility': json['removalIncompatibility'],
        'stopActivity': json['stopActivity'],
        'request': json['request'],
        'requestApproved': json['requestApproved'],
        'requestDate': !exists(json, 'requestDate') ? undefined : (json['requestDate'] === null ? null : new Date(json['requestDate'])),
        'removePublishDate': !exists(json, 'removePublishDate') ? undefined : (json['removePublishDate'] === null ? null : new Date(json['removePublishDate'])),
        'approvedDate': !exists(json, 'approvedDate') ? undefined : (json['approvedDate'] === null ? null : new Date(json['approvedDate'])),
        'parties': ((json['parties'] as Array<any>).map(GetPartyToProceedingDtoFromJSON)),
    };
}

export function GetKwzWithPartiesDtoToJSON(value?: GetKwzWithPartiesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'publicationNumber': value.publicationNumber,
        'publicationDate': value.publicationDate === undefined ? undefined : (value.publicationDate.toISOString()),
        'decisionSign': GetDictionaryDtoToJSON(value.decisionSign),
        'decisionIndex': GetDictionaryDtoToJSON(value.decisionIndex),
        'productionCountry': value.productionCountry,
        'incompatibility': value.incompatibility,
        'danger': value.danger,
        'buyBackOrder': value.buyBackOrder,
        'destructionOrder': value.destructionOrder,
        'other': value.other,
        'approved': value.approved,
        'tariffCode': value.tariffCode,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'judgment': GetJudgmentDtoToJSON(value.judgment),
        'productBatchNumber': value.productBatchNumber,
        'withdrawalInfo': value.withdrawalInfo,
        'removalIncompatibility': value.removalIncompatibility,
        'stopActivity': value.stopActivity,
        'request': value.request,
        'requestApproved': value.requestApproved,
        'requestDate': value.requestDate === undefined ? undefined : (value.requestDate === null ? null : value.requestDate.toISOString()),
        'removePublishDate': value.removePublishDate === undefined ? undefined : (value.removePublishDate === null ? null : value.removePublishDate.toISOString()),
        'approvedDate': value.approvedDate === undefined ? undefined : (value.approvedDate === null ? null : value.approvedDate.toISOString()),
        'parties': ((value.parties as Array<any>).map(GetPartyToProceedingDtoToJSON)),
    };
}


