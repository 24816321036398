import React from 'react';
import { Table } from 'react-bootstrap';

import {
  CountCprDetails,
  ReportJudgmentsCountType,
} from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportJudgmentsCountType;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportJudgmentsCount: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = Object.keys(data.details).length > 0;

  const getDetail = (
    key: string,
    dict: string,
    countCpr: keyof CountCprDetails,
  ) => data.details?.[key]?.[dict]?.[countCpr] || 0;

  const getSumInspectionDetail = (
    key: string,
    countCpr: keyof CountCprDetails,
  ) =>
    (data.details?.[key]?.['22.1']?.[countCpr] || 0) +
    (data.details?.[key]?.['22.3']?.[countCpr] || 0) +
    (data.details?.[key]?.['22c.1']?.[countCpr] || 0) +
    (data.details?.[key]?.['22c.4']?.[countCpr] || 0);

  const getSum = (dict: string, countCpr: keyof CountCprDetails) =>
    data.sum?.[dict]?.[countCpr] || 0;

  const getSumInspection = (countCpr: keyof CountCprDetails) =>
    (data.sum?.['22.1']?.[countCpr] || 0) +
    (data.sum?.['22.3']?.[countCpr] || 0) +
    (data.sum?.['22c.1']?.[countCpr] || 0) +
    (data.sum?.['22c.4']?.[countCpr] || 0);

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={17}>{reportName}</th>
            </tr>
            <tr>
              <th rowSpan={2}>Organ</th>
              <th colSpan={2}>22.1</th>
              <th colSpan={2}>22.3</th>
              <th colSpan={2}>22c.1</th>
              <th colSpan={2}>22c.4</th>
              <th colSpan={2}>Suma postanowień kontrolnych</th>
              <th colSpan={2}>art. 26 ust. 4</th>
            </tr>
            <tr>
              <th>O</th>
              <th>CPR</th>
              <th>O</th>
              <th>CPR</th>
              <th>O</th>
              <th>CPR</th>
              <th>O</th>
              <th>CPR</th>
              <th>Suma O</th>
              <th>Suma CPR</th>
              <th>O</th>
              <th>CPR</th>
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    <td className="numbers-column">
                      {getDetail(key, '22.1', 'all')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, '22.1', 'cpr')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, '22.3', 'all')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, '22.3', 'cpr')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, '22c.1', 'all')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, '22c.1', 'cpr')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, '22c.4', 'all')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, '22c.4', 'cpr')}
                    </td>
                    <td className="numbers-column">
                      {getSumInspectionDetail(key, 'all')}
                    </td>
                    <td className="numbers-column">
                      {getSumInspectionDetail(key, 'cpr')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, 'art. 26 ust. 4', 'all')}
                    </td>
                    <td className="numbers-column">
                      {getDetail(key, 'art. 26 ust. 4', 'cpr')}
                    </td>
                  </tr>
                ))}
              <tr key={'sum'}>
                <td>Suma:</td>
                <td className="numbers-column">{getSum('22.1', 'all')}</td>
                <td className="numbers-column">{getSum('22.1', 'cpr')}</td>
                <td className="numbers-column">{getSum('22.3', 'all')}</td>
                <td className="numbers-column">{getSum('22.3', 'cpr')}</td>
                <td className="numbers-column">{getSum('22c.1', 'all')}</td>
                <td className="numbers-column">{getSum('22c.1', 'cpr')}</td>
                <td className="numbers-column">{getSum('22c.4', 'all')}</td>
                <td className="numbers-column">{getSum('22c.4', 'cpr')}</td>
                <td className="numbers-column">{getSumInspection('all')}</td>
                <td className="numbers-column">{getSumInspection('cpr')}</td>
                <td className="numbers-column">
                  {getSum('art. 26 ust. 4', 'all')}
                </td>
                <td className="numbers-column">
                  {getSum('art. 26 ust. 4', 'cpr')}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};
