/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectedProductDetails
 */
export interface InspectedProductDetails {
    /**
     * 
     * @type {number}
     * @memberof InspectedProductDetails
     */
    o: number;
    /**
     * 
     * @type {number}
     * @memberof InspectedProductDetails
     */
    n: number;
    /**
     * 
     * @type {number}
     * @memberof InspectedProductDetails
     */
    cpr: number;
    /**
     * 
     * @type {number}
     * @memberof InspectedProductDetails
     */
    ncpr: number;
}

export function InspectedProductDetailsFromJSON(json: any): InspectedProductDetails {
    return InspectedProductDetailsFromJSONTyped(json, false);
}

export function InspectedProductDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectedProductDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'o': json['o'],
        'n': json['n'],
        'cpr': json['cpr'],
        'ncpr': json['ncpr'],
    };
}

export function InspectedProductDetailsToJSON(value?: InspectedProductDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'o': value.o,
        'n': value.n,
        'cpr': value.cpr,
        'ncpr': value.ncpr,
    };
}


