import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportProceedingType } from '../../../../common/types/ReportTypes';
import { ReportByProceeding } from '../../../components/ReportByProceeding/ReportByProceeding';

export const ReportProceedingView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportProceedingType>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportProceeding(false, filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Liczba postępowań administracyjnych"
      />
      <ReportByProceeding
        data={data}
        reportName="Prowadzone postępowania administracyjne"
        headerName={['16.1a', '32a', 'KP', 'Suma']}
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
