/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportOrderedSampleExamsType
 */
export interface ReportOrderedSampleExamsType {
    /**
     * 
     * @type {object}
     * @memberof ReportOrderedSampleExamsType
     */
    details: object;
    /**
     * 
     * @type {object}
     * @memberof ReportOrderedSampleExamsType
     */
    sum: object;
}

export function ReportOrderedSampleExamsTypeFromJSON(json: any): ReportOrderedSampleExamsType {
    return ReportOrderedSampleExamsTypeFromJSONTyped(json, false);
}

export function ReportOrderedSampleExamsTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportOrderedSampleExamsType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': json['details'],
        'sum': json['sum'],
    };
}

export function ReportOrderedSampleExamsTypeToJSON(value?: ReportOrderedSampleExamsType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details,
        'sum': value.sum,
    };
}


