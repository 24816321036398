/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectedProductDetails,
    InspectedProductDetailsFromJSON,
    InspectedProductDetailsFromJSONTyped,
    InspectedProductDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrgUnitInspectedProductsRanges
 */
export interface OrgUnitInspectedProductsRanges {
    /**
     * 
     * @type {InspectedProductDetails}
     * @memberof OrgUnitInspectedProductsRanges
     */
    checkMarking: InspectedProductDetails;
    /**
     * 
     * @type {InspectedProductDetails}
     * @memberof OrgUnitInspectedProductsRanges
     */
    checkDeclaration: InspectedProductDetails;
    /**
     * 
     * @type {InspectedProductDetails}
     * @memberof OrgUnitInspectedProductsRanges
     */
    checkDocumentation: InspectedProductDetails;
    /**
     * 
     * @type {InspectedProductDetails}
     * @memberof OrgUnitInspectedProductsRanges
     */
    checkManual: InspectedProductDetails;
    /**
     * 
     * @type {InspectedProductDetails}
     * @memberof OrgUnitInspectedProductsRanges
     */
    checkVolatileOrganicCompounds: InspectedProductDetails;
}

export function OrgUnitInspectedProductsRangesFromJSON(json: any): OrgUnitInspectedProductsRanges {
    return OrgUnitInspectedProductsRangesFromJSONTyped(json, false);
}

export function OrgUnitInspectedProductsRangesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgUnitInspectedProductsRanges {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkMarking': InspectedProductDetailsFromJSON(json['checkMarking']),
        'checkDeclaration': InspectedProductDetailsFromJSON(json['checkDeclaration']),
        'checkDocumentation': InspectedProductDetailsFromJSON(json['checkDocumentation']),
        'checkManual': InspectedProductDetailsFromJSON(json['checkManual']),
        'checkVolatileOrganicCompounds': InspectedProductDetailsFromJSON(json['checkVolatileOrganicCompounds']),
    };
}

export function OrgUnitInspectedProductsRangesToJSON(value?: OrgUnitInspectedProductsRanges | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkMarking': InspectedProductDetailsToJSON(value.checkMarking),
        'checkDeclaration': InspectedProductDetailsToJSON(value.checkDeclaration),
        'checkDocumentation': InspectedProductDetailsToJSON(value.checkDocumentation),
        'checkManual': InspectedProductDetailsToJSON(value.checkManual),
        'checkVolatileOrganicCompounds': InspectedProductDetailsToJSON(value.checkVolatileOrganicCompounds),
    };
}


