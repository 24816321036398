import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useNavigation } from '../../../common/navigation';
import { GetProductTypeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import nkitwDesc from '../../../common/nkitwDesc';

import { ProductTypeType } from './ProductTypeType';
import { MergingProductType } from './MergingProductType';

interface ProductTypeSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  withoutOrganization?: boolean;
  withMerging?: boolean;
  productTypeHeadId?: string;
}

export const productTypeForGrid = (
  productType: GetProductTypeDto & { headId?: string },
) => {
  return {
    key: productType.id,
    values: {
      'productType.cpr': <ProductTypeType productType={productType} />,
      'productType.nkitw': nkitwDesc(productType),
      'group.value': productType.group?.value,
      'sign.value': productType.sign?.value,
      'organization.name': productType.organization?.name,
      'productType.sysnumber': productType.sysnumber,
      merging: (
        <MergingProductType
          productTypeId={productType.id}
          productTypeHeadId={productType.headId}
        />
      ),
    },
    orderByValues: {
      'productType.sysnumber': String(productType.sysnumber).padStart(10, '0'),
    },
  };
};

export const ProductTypeSearch: React.FC<ProductTypeSearchProps> = (
  props: ProductTypeSearchProps,
) => {
  const nav = useNavigation();

  const columns: GridColumn[] = [
    { header: '', property: 'productType.cpr', noSorting: true },
    { header: 'Numer', property: 'productType.sysnumber' },
    { header: 'NKITW/Opis', property: 'productType.nkitw' },
    { header: 'Oznakowanie', property: 'sign.value' },
    { header: 'Grupa wyrobu', property: 'group.value' },
  ];
  if (!props.withoutOrganization) {
    columns.splice(2, 0, { header: 'Podmiot', property: 'organization.name' });
  }
  if (props.withMerging) {
    columns.push({
      header: '',
      property: 'merging',
      className: 'text-center',
      noSorting: true,
    });
  }

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      initialOrderColumn="nkitw"
      showFilter={true}
      availablePageSizes={[10, 20, 50]}
      onRowClick={nav.productType}
      emptyGridMessage={
        !props.withoutOrganization
          ? 'Brak typów wyrobów w systemie'
          : 'Brak typów wyrobów dla tego podmiotu'
      }
      additionalId={props.productTypeHeadId}
    />
  );
};
