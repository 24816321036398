export enum Role {
  Login = 'realm:Logowanie-do-SNRWB-WEB',
  CurrentOrganizationalUnitChange = 'realm:Zmiana-biezacego-organu',
  ConfigurationView = 'realm:Konfiguracja-Przegladanie',
  ConfigurationEdit = 'realm:Konfiguracja-Edycja',
  Reports = 'realm:Raporty',
  InspectionsView = 'realm:Kontrole-Przegladanie',
  InspectionsEdit = 'realm:Kontrole-Edycja',
  InspectionsRevoke = 'realm:Kontrole-Usuwanie-Cofanie',
  ExamsView = 'realm:Badania-Przegladanie',
  ExamsEdit = 'realm:Badania-Edycja',
  ExamsRevoke = 'realm:Badania-Usuwanie-Cofanie',
  ExamsPublishing = 'realm:Badania-Publikacja',
  OrganizationsView = 'realm:Podmioty-Przegladanie',
  OrganizationsEdit = 'realm:Podmioty-Edycja',
  OrganizationsMerging = 'realm:Podmioty-Sklejanie',
  CertificatesView = 'realm:Certyfikaty-Przegladanie',
  CertificatesEdit = 'realm:Certyfikaty-Edycja',
  CertificatesRevoke = 'realm:Certyfikaty-Usuwanie-Cofanie',
  CustomsFeedbacksView = 'realm:Opinie-celne-Przegladanie',
  CustomsFeedbacksEdit = 'realm:Opinie-celne-Edycja',
  CustomsFeedbacksRevoke = 'realm:Opinie-celne-Usuwanie-Cofanie',
  ProceedingsView = 'realm:Postepowania-Przegladanie',
  ProceedingsEdit = 'realm:Postepowania-Edycja',
  ProceedingsRevoke = 'realm:Postepowania-Usuwanie-Cofanie',
  PakView = 'realm:PAK-Przegladanie',
  PakEdit = 'realm:PAK-Edycja',
  PakRevoke = 'realm:PAK-Usuwanie-Cofanie',
  ProductTypesView = 'realm:Typy-wyrobow-Przegladanie',
  ProductTypesRevoke = 'realm:Typy-wyrobow-Cofanie',
  ProductTypesMerging = 'realm:Typy-wyrobow-Sklejanie',
  InspectionsPlansView = 'realm:Plany-kontroli-Przegladanie',
  InspectionsPlansEdit = 'realm:Plany-kontroli-Edycja',
  InspectionsPlansRevoke = 'realm:Plany-kontroli-Usuwanie-Cofanie',
  KwzView = 'realm:KWZ-Przegladanie',
  KwzEdit = 'realm:KWZ-Edycja',
  KwzPublishing = 'realm:KWZ-Publikacja',
  KwzRevoke = 'realm:KWZ-Usuwanie-Cofanie',
}
