import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  GetInspectedProductDto,
  GetPakExtendedDto,
  GetPartyToProceedingDto,
  GetProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { Proceeding } from '../../components/Proceedings/Proceeding';
import * as ProceedingContext from '../../../common/snrwbCore/contexts/ProceedingContext';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import * as CorrectiveActionContext from '../../../common/snrwbCore/contexts/CorrectiveActionContext';
import * as JudgmentContext from '../../../common/snrwbCore/contexts/JudgmentContext';
import FormSkeleton from '../../../app/components/FormSkeleton';
import { useNavigation } from '../../../common/navigation';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useAuth } from '../../../common/hooks/useAuth';
import { useCookies } from '../../../common/hooks/useCookies';

interface ParamTypes {
  id: string;
  bannerId: string;
}

export const ProceedingView: React.FC = () => {
  const { id, bannerId } = useParams<ParamTypes>();
  const snrwb = useSnrwbCore();
  const auth = useAuth();
  const notifications = useNotifications();
  const nav = useNavigation();
  const cookies = useCookies();
  const [proceeding, setProceeding] = useState<GetProceedingDto>();
  const [parties, setParties] = useState<GetPartyToProceedingDto[]>([]);
  const [product, setProduct] = useState<GetInspectedProductDto>();
  const [paks, setPaks] = useState<GetPakExtendedDto[]>([]);
  const [checkingProduct, setCheckingProduct] =
    useState<GetInspectedProductDto>();
  const [attachments, setAttachments] = useState(
    AttachmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [correctiveActions, setCorrectiveActions] = useState(
    CorrectiveActionContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [decisions, setDecisions] = useState(
    JudgmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [repairs, setRepairs] = useState(
    JudgmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [codex, setCodex] = useState(
    JudgmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );

  const mountedRef = useRef(false);

  const refresh = useCallback(() => {
    ProceedingContext.forProceedingView(snrwb, notifications, id, refresh)
      .then(model => {
        if (mountedRef.current) {
          model.proceeding.inspectedProduct = model.inspectedProduct; // eager
          setProceeding(model.proceeding);
          setParties(model.parties);
          setProduct(model.inspectedProduct);
          setCheckingProduct(model.checkingProduct);
          setPaks(model.paks);
          setAttachments(model.attachments);
          setCorrectiveActions(model.correctiveActions);
          setDecisions(model.decisions);
          setRepairs(model.repairs);
          setCodex(model.codex);
        }
      })
      .catch(() =>
        notifications.badLink(
          'Brak postępowania w bazie danych. Mogło zostać przed chwilą usunięte.',
          '/postepowania',
        ),
      );
  }, [id, notifications, snrwb]);

  useEffect(() => {
    mountedRef.current = true;

    refresh();

    return () => {
      mountedRef.current = false;
    };
  }, [refresh, id]);

  const unitsMatch =
    proceeding?.organizationalUnit?.id === cookies.organizationalUnit?.id;

  return proceeding && product ? (
    <Proceeding
      editAuthorized={auth.check(Role.ProceedingsEdit) && unitsMatch}
      validOrganizationalUnit={unitsMatch}
      value={proceeding}
      bannerId={bannerId}
      inspectedProduct={product}
      checkingProduct={checkingProduct}
      paks={paks}
      attachments={attachments}
      correctiveActions={correctiveActions}
      decisions={decisions}
      repairs={repairs}
      codex={codex}
      parties={parties}
      api={ProceedingContext.handlersForProceedingView(
        snrwb,
        notifications,
        proceeding,
        product.productType,
        refresh,
        nav.myProceedings,
        nav.inspection,
        nav.pak,
      )}
    />
  ) : (
    <FormSkeleton />
  );
};
