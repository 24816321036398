import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {
  CreateUpdateEssentialFeatureDto,
  GetEssentialFeatureDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as EssentialFeatureContext from '../../../common/snrwbCore/contexts/EssentialFeatureContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { SampleWithResult } from '../../../common/snrwbCore/contexts/SampleExamContext';

import { EssentialFeature } from './EssentialFeature';

export const EssentialFeatureModal = (props: {
  examApproved: boolean;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  show: boolean;
  value?: GetEssentialFeatureDto;
  samples: SampleWithResult[];
  onHide: () => void;
  onSave: (dto: CreateUpdateEssentialFeatureDto) => void;
  onDelete: () => void;
}) => {
  const [feature, setFeature] = useState<CreateUpdateEssentialFeatureDto>(
    EssentialFeatureContext.toCuDto(
      props.value || ({} as GetEssentialFeatureDto),
    ),
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  const propertyChange = (obj: Partial<CreateUpdateEssentialFeatureDto>) => {
    setFeature({ ...feature, ...obj });
  };

  const save = () => {
    EssentialFeatureContext.validate({
      ...feature,
      sampleExamId: 'would be set later',
    }).then(status => {
      setErrors(status.errors);
      setShowErrors(!status.valid);

      if (status.valid) {
        props.onSave(feature);
      }
    });
  };

  useEffect(() => {
    setFeature(
      EssentialFeatureContext.toCuDto(
        props.value || ({ publish: true } as GetEssentialFeatureDto),
      ),
    );
    setErrors([]);
    setShowErrors(false);
  }, [props.value, props.show]);

  const sampleIsPublished =
    props.samples.filter(
      sr => !sr.sample.controlSample && sr.result && sr.result.publicationDate,
    ).length > 0;
  const controlSampleExists =
    props.samples.filter(sr => sr.sample.controlSample).length > 0;
  const controlSampleIsPublished =
    props.samples.filter(
      sr => sr.sample.controlSample && sr.result && sr.result.publicationDate,
    ).length > 0;
  const finishAndResultExists =
    props.samples.filter(sr => sr.result?.approved && sr.result).length > 0;
  const resultSampleExists =
    props.samples.filter(
      sr => !sr.sample.controlSample && sr.result?.approved && sr.result,
    ).length > 0;
  const resultControlSampleExists =
    props.samples.filter(
      sr => sr.sample.controlSample && sr.result?.approved && sr.result,
    ).length > 0;

  const onlyReadonly = sampleIsPublished && controlSampleIsPublished;

  return (
    <>
      <Modal show={props.show} centered={true} size="lg" backdrop="static">
        <Modal.Header>
          <Modal.Title>
            {props.value
              ? 'Zmiana zasadniczej charakterystyki'
              : 'Nowa zasadnicza charakterystyka'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EssentialFeature
            feature={feature}
            examApproved={props.examApproved}
            sampleIsPublished={sampleIsPublished}
            resultSampleExists={resultSampleExists}
            controlSampleExists={controlSampleExists}
            controlSampleIsPublished={controlSampleIsPublished}
            resultControlSampleExists={resultControlSampleExists}
            editAuthorized={props.editAuthorized}
            publishAuthorized={props.publishAuthorized}
            finishAndResultExists={finishAndResultExists}
            propertyChange={propertyChange}
          />
          <ValidationAlert show={showErrors} errors={errors} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.onHide}>
            Zamknij
          </Button>
          {props.value && !props.examApproved && (
            <ConfirmationButton
              confirmation="Czy na pewno usunąć zasadniczą charakterystykę?"
              roleAnyOf={[Role.ExamsEdit]}
              onOK={props.onDelete}
              variant="outline-danger"
            >
              Usuń
            </ConfirmationButton>
          )}
          {!onlyReadonly && (
            <Button variant="outline-primary" onClick={save}>
              {props.value ? 'Zmień' : 'Dodaj'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
