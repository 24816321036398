import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';

import {
  GetInspectedProductDto,
  GetAttachmentDto,
  GetInspectionDto,
  GetMisstatementDto,
  GetCorrectiveActionDto,
  GetJudgmentDto,
  CreateUpdateAttachmentDto,
  CreateUpdateMisstatementDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateJudgmentDto,
  GetProceedingDto,
  GetPakDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as ProtocolDuringInspectionContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import { ObjectType } from '../../../common/snrwbCore/contexts/AttachmentContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { SelectProductTypeModal } from '../ProductTypes/SelectProductTypeModal';
import { InspectionApi } from '../../../common/snrwbCore/contexts/InspectionContext';
import FormSkeleton from '../../../app/components/FormSkeleton';
import momencik from '../../../common/momencik';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import nkitwDesc from '../../../common/nkitwDesc';

import { InspectedProduct } from './InspectedProduct';

interface InspectedProductsProps {
  editAuthorized: boolean;
  inspection: GetInspectionDto;
  banner?: { inspectedProductId: string; bannerId: string };
  products?: GetInspectedProductDto[];
  proceedings?: GetProceedingDto[];
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  misstatements: AssociateDocuments<
    GetMisstatementDto,
    CreateUpdateMisstatementDto
  >;
  correctiveActions: AssociateDocuments<
    GetCorrectiveActionDto,
    CreateUpdateCorrectiveActionDto
  >;
  judgments: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  protocols: AssociateDocuments<
    ProtocolDuringInspectionContext.ProtocolWithAttachments,
    ProtocolDuringInspectionContext.CreateUpdateProtocolWithAttachments
  >;
  paks: GetPakDto[];
  activeProduct?: string;
  errors: string[];
  api: InspectionApi;
  onBannerClosed: () => void;
}

export const InspectedProducts: React.FC<InspectedProductsProps> = props => {
  const [activeItem, setActiveItem] = useState(props.activeProduct);

  useEffect(() => setActiveItem(props.activeProduct), [props.activeProduct]);
  useEffect(
    () => props.banner && setActiveItem(props.banner.inspectedProductId),
    [props.banner],
  );

  const getProceeding = (productId: string) => {
    if (!props.proceedings) {
      return undefined;
    }
    const match = props.proceedings.filter(
      p =>
        p.inspectedProductId === productId || p.checkingProductId === productId,
    );
    return match.length > 0 ? match[0] : undefined;
  };

  const castAttachments = (productId: string) => {
    return props.attachments.filter(
      attachment => attachment.objectId === productId,
      attachment => {
        attachment.enObjectType = ObjectType.InspectedProduct;
        attachment.objectId = productId;
      },
    );
  };
  const castMisstatements = (productId: string) => {
    return props.misstatements.filter(
      misstatement => misstatement.inspectedProductId === productId,
      misstatement => (misstatement.inspectedProductId = productId),
    );
  };
  const castCorrectiveActions = (productId: string) => {
    return props.correctiveActions.filter(
      action => action.inspectedProductId === productId,
      action => (action.inspectedProductId = productId),
    );
  };
  const castJudgments = (productId: string) => {
    return props.judgments.filter(
      action => action.inspectedProductId === productId,
      action => (action.inspectedProductId = productId),
    );
  };
  const castProtocols = (productId: string) => {
    return props.protocols.filter(
      pwa => pwa.protocol.inspectedProductId === productId,
      cuPwa => (cuPwa.cuDto.inspectedProductId = productId),
    );
  };

  const addProduct = (
    <SelectProductTypeModal
      title={`Nowy wyrób w kontroli z ${momencik(
        props.inspection.metric?.startDate,
      )}`}
      buttonText="Dodaj wyrób"
      add={props.api.addInspectedProduct}
      errors={props.errors}
    />
  );

  if (!props.products) {
    return <FormSkeleton />;
  } else if (props.products.length === 0) {
    return (
      <>
        <Alert variant="warning">
          W kontroli nie ma jeszcze żadnego wyrobu.
        </Alert>
        {addProduct}
      </>
    );
  }

  return (
    <>
      <Accordion className="mb-3" activeKey={activeItem}>
        {props.products.map(product => (
          <Accordion.Item key={product.id} eventKey={product.id}>
            <Accordion.Header
              onClick={() =>
                setActiveItem(
                  product.id === activeItem ? undefined : product.id,
                )
              }
            >
              {nkitwDesc(product.productType)}
              {product.subtype ? ` (${product.subtype})` : ``}
            </Accordion.Header>
            <Accordion.Body>
              <InspectedProduct
                approvedInspection={props.inspection.approved}
                checkingInspection={
                  props.inspection.metric.inspectionType?.shortname === 'R'
                }
                editAuthorized={props.editAuthorized}
                /// hack to rerender on accordion expand
                /// and force DynamicTextarea to calculate height
                product={activeItem === product.id ? product : { ...product }}
                bannerId={props.banner && props.banner.bannerId}
                proceeding={getProceeding(product.id)}
                attachments={castAttachments(product.id)}
                misstatements={castMisstatements(product.id)}
                correctiveActions={castCorrectiveActions(product.id)}
                judgments={castJudgments(product.id)}
                protocols={castProtocols(product.id)}
                pak={props.paks.find(p => p.inspectedProduct.id === product.id)}
                onChange={dto => props.api.editProduct(product.id, dto)}
                changeType={dto =>
                  props.api.editProductType(product.productType.id, dto)
                }
                mayProductTypeBeApproved={() =>
                  props.api.mayProductTypeBeApproved(product.productType.id)
                }
                approveProductType={status =>
                  props.api.approveProductType(product.productType.id, status)
                }
                revertApproveProductType={() =>
                  props.api.revertApproveProductType(product.productType)
                }
                createExam={props.api.createExam}
                addProceeding={() => props.api.createProceeding(product.id)}
                addPak={() => props.api.addPak(product.id)}
                onBannerClosed={props.onBannerClosed}
                refresh={props.api.refresh}
              />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {props.editAuthorized && !props.inspection.approved && (
        <>
          {addProduct}
          {activeItem && (
            <ConfirmationButton
              className="mx-3"
              confirmation="Czy na pewno usunąć wyrób z kontroli?"
              variant="outline-danger"
              roleAnyOf={[Role.InspectionsEdit]}
              onOK={() => {
                props.api.deleteProduct(activeItem || '');
                setActiveItem(undefined);
              }}
            >
              Usuń wyrób
            </ConfirmationButton>
          )}
        </>
      )}
    </>
  );
};
