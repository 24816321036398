import { GetExamResultDto } from '../autogenerated/snrwbApiClient/models/GetExamResultDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateExamResultDto,
  CreateUpdateExamResultDtoFromJSON,
  ValidationStatus,
} from '../autogenerated/snrwbApiClient/models';

export interface ExamResultContextInterface {
  getById: (id: string) => Promise<GetExamResultDto>;
  update: (id: string, dto: CreateUpdateExamResultDto) => Promise<void>;
  create: (dto: CreateUpdateExamResultDto) => Promise<GetExamResultDto>;
  createVoid: (dto: CreateUpdateExamResultDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  getBySample: (sampleId: string) => Promise<GetExamResultDto>;
  approve: (id: string) => Promise<void>;
  revertApprove: (id: string, reason?: string) => Promise<void>;
  mayBeApproved: (id: string) => Promise<ValidationStatus>;
  mayBePublished: (id: string) => Promise<ValidationStatus>;
  publish: (id: string) => Promise<void>;
  revertPublish: (id: string) => Promise<void>;
}

export const ExamResultContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.examResultControllerGet(id),
    update: (id: string, dto: CreateUpdateExamResultDto) =>
      api.examResultControllerUpdate(id, dto),
    create: (dto: CreateUpdateExamResultDto) =>
      api.examResultControllerCreate(dto),
    createVoid: async (dto: CreateUpdateExamResultDto) => {
      await api.examResultControllerCreate(dto);
    },
    delete: (id: string) => api.examResultControllerDelete(id),
    getBySample: (sampleId: string) =>
      api.examResultControllerGetBySampleId(sampleId),
    approve: (id: string) => api.examResultControllerApprove(id),
    revertApprove: (id: string, reason?: string) =>
      api.examResultControllerRevertApprove({
        id,
        reason: reason || '',
      }),
    mayBeApproved: (id: string) => api.examResultControllerMayBeApproved(id),
    mayBePublished: (id: string) => api.examResultControllerMayBePublished(id),
    publish: (id: string) => api.examResultControllerPublish(id),
    revertPublish: (id: string) => api.examResultControllerRevertPublish(id),
  };
};

export const toCuDto = (getDto: GetExamResultDto) => {
  const dto = CreateUpdateExamResultDtoFromJSON(getDto);
  if (getDto.sample) {
    dto.sampleId = getDto.sample.id;
  }
  return dto;
};
