/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterDatesProductTypeReportData
 */
export interface FilterDatesProductTypeReportData {
    /**
     * 
     * @type {string}
     * @memberof FilterDatesProductTypeReportData
     */
    nkitw?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDatesProductTypeReportData
     */
    producerId?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDatesProductTypeReportData
     */
    signId?: string;
    /**
     * 
     * @type {Date}
     * @memberof FilterDatesProductTypeReportData
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FilterDatesProductTypeReportData
     */
    dateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof FilterDatesProductTypeReportData
     */
    groupProductTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDatesProductTypeReportData
     */
    organizationalUnitId?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDatesProductTypeReportData
     */
    documentsType?: FilterDatesProductTypeReportDataDocumentsTypeEnum;
}

export function FilterDatesProductTypeReportDataFromJSON(json: any): FilterDatesProductTypeReportData {
    return FilterDatesProductTypeReportDataFromJSONTyped(json, false);
}

export function FilterDatesProductTypeReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterDatesProductTypeReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nkitw': !exists(json, 'nkitw') ? undefined : json['nkitw'],
        'producerId': !exists(json, 'producerId') ? undefined : json['producerId'],
        'signId': !exists(json, 'signId') ? undefined : json['signId'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : (new Date(json['dateFrom'])),
        'dateTo': !exists(json, 'dateTo') ? undefined : (new Date(json['dateTo'])),
        'groupProductTypeId': !exists(json, 'groupProductTypeId') ? undefined : json['groupProductTypeId'],
        'organizationalUnitId': !exists(json, 'organizationalUnitId') ? undefined : json['organizationalUnitId'],
        'documentsType': !exists(json, 'documentsType') ? undefined : json['documentsType'],
    };
}

export function FilterDatesProductTypeReportDataToJSON(value?: FilterDatesProductTypeReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nkitw': value.nkitw,
        'producerId': value.producerId,
        'signId': value.signId,
        'dateFrom': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString()),
        'dateTo': value.dateTo === undefined ? undefined : (value.dateTo.toISOString()),
        'groupProductTypeId': value.groupProductTypeId,
        'organizationalUnitId': value.organizationalUnitId,
        'documentsType': value.documentsType,
    };
}

/**
* @export
* @enum {string}
*/
export enum FilterDatesProductTypeReportDataDocumentsTypeEnum {
    Inspections = 'inspections',
    Exams = 'exams',
    Proceedings = 'proceedings'
}


