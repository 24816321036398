import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import ItemPresentation from '../../../app/components/ItemPresentation';
import DictionaryMultiSelector from '../Selects/DictionaryMultiSelector';
import UnorderedList from '../../../app/components/UnorderedList';

interface CautionColumns {
  cautionText: string;
  cautionIds: string[];
  cautionValues: string[];
}

export default (props: {
  editAuthorized: boolean;
  value: CautionColumns;
  onChange: (val: CautionColumns) => void;
}) => {
  const [value, setValue] = useState(props.value);
  const [presentedValue, setPresentedValue] = useState(value);

  useEffect(() => setValue(props.value), [props.value]);
  useEffect(() => setPresentedValue(value), [value]);

  const setProperty = (obj: Partial<CautionColumns>) => {
    setPresentedValue({ ...presentedValue, ...obj });
  };
  const raiseChanged = (obj: CautionColumns) => {
    if (_.isEqual(value, obj)) {
      return;
    }
    props.onChange(obj);
  };
  const valueChange = (obj: Partial<CautionColumns>) => {
    const dto = { ...value, ...obj };
    setValue(dto);
    raiseChanged(dto);
  };
  const presentedToValue = () => {
    setValue(presentedValue);
    raiseChanged(presentedValue);
  };

  if (!props.editAuthorized) {
    const values: { name: string; value: React.ReactNode }[] = [];
    if (presentedValue.cautionValues?.length || presentedValue.cautionText) {
      values.push({
        name: 'Pouczenie',
        value: UnorderedList({
          values: presentedValue.cautionValues,
          freeValue: presentedValue.cautionText,
        }),
      });
    }
    return <ItemPresentation item={values} />;
  }

  return (
    <>
      <FormRow controlId="cautionIds" label="Pouczenie">
        <DictionaryMultiSelector
          dictionaryName="pouczenie"
          value={presentedValue.cautionIds}
          onChange={value => valueChange({ cautionIds: value })}
        />
      </FormRow>
      <FormRow controlId="regulations" label="">
        <DynamicTextarea
          value={presentedValue.cautionText || ''}
          onChange={e => setProperty({ cautionText: e.target.value })}
          onBlur={presentedToValue}
        />
      </FormRow>
    </>
  );
};
