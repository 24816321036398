/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetInspectionPlanDto,
    GetInspectionPlanDtoFromJSON,
    GetInspectionPlanDtoFromJSONTyped,
    GetInspectionPlanDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetInspectionPlanGroupDto
 */
export interface GetInspectionPlanGroupDto {
    /**
     * 
     * @type {string}
     * @memberof GetInspectionPlanGroupDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetInspectionPlanGroupDto
     */
    productGroup: GetDictionaryDto;
    /**
     * 
     * @type {number}
     * @memberof GetInspectionPlanGroupDto
     */
    numberOfInspections: number;
    /**
     * 
     * @type {number}
     * @memberof GetInspectionPlanGroupDto
     */
    numberOfInspectionsCpr: number;
    /**
     * 
     * @type {GetInspectionPlanDto}
     * @memberof GetInspectionPlanGroupDto
     */
    inspectionPlan: GetInspectionPlanDto;
}

export function GetInspectionPlanGroupDtoFromJSON(json: any): GetInspectionPlanGroupDto {
    return GetInspectionPlanGroupDtoFromJSONTyped(json, false);
}

export function GetInspectionPlanGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInspectionPlanGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productGroup': GetDictionaryDtoFromJSON(json['productGroup']),
        'numberOfInspections': json['numberOfInspections'],
        'numberOfInspectionsCpr': json['numberOfInspectionsCpr'],
        'inspectionPlan': GetInspectionPlanDtoFromJSON(json['inspectionPlan']),
    };
}

export function GetInspectionPlanGroupDtoToJSON(value?: GetInspectionPlanGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productGroup': GetDictionaryDtoToJSON(value.productGroup),
        'numberOfInspections': value.numberOfInspections,
        'numberOfInspectionsCpr': value.numberOfInspectionsCpr,
        'inspectionPlan': GetInspectionPlanDtoToJSON(value.inspectionPlan),
    };
}


