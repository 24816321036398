/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProtocolDuringInspectionDto
 */
export interface GetProtocolDuringInspectionDto {
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    number: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetProtocolDuringInspectionDto
     */
    type: GetDictionaryDto;
    /**
     * 
     * @type {Date}
     * @memberof GetProtocolDuringInspectionDto
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    witnesses: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    attachments: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    repairMethod: string;
    /**
     * 
     * @type {Date}
     * @memberof GetProtocolDuringInspectionDto
     */
    signatureDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    refusalReason: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    placeOfSignature: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProtocolDuringInspectionDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDuringInspectionDto
     */
    quantity: string;
}

export function GetProtocolDuringInspectionDtoFromJSON(json: any): GetProtocolDuringInspectionDto {
    return GetProtocolDuringInspectionDtoFromJSONTyped(json, false);
}

export function GetProtocolDuringInspectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProtocolDuringInspectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'type': GetDictionaryDtoFromJSON(json['type']),
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'witnesses': json['witnesses'],
        'description': json['description'],
        'attachments': json['attachments'],
        'repairMethod': json['repairMethod'],
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
        'refusalReason': json['refusalReason'],
        'placeOfSignature': json['placeOfSignature'],
        'approved': json['approved'],
        'inspectedProductId': json['inspectedProductId'],
        'quantity': json['quantity'],
    };
}

export function GetProtocolDuringInspectionDtoToJSON(value?: GetProtocolDuringInspectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'type': GetDictionaryDtoToJSON(value.type),
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'witnesses': value.witnesses,
        'description': value.description,
        'attachments': value.attachments,
        'repairMethod': value.repairMethod,
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString()),
        'refusalReason': value.refusalReason,
        'placeOfSignature': value.placeOfSignature,
        'approved': value.approved,
        'inspectedProductId': value.inspectedProductId,
        'quantity': value.quantity,
    };
}


