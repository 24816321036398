import React from 'react';
import { Alert, Col, Form } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import DatePicker from '../../../app/components/DatePicker';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import FormRow from '../../../app/components/FormRow';
import ItemPresentation from '../../../app/components/ItemPresentation';
import momencik from '../../../common/momencik';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import {
  CreateUpdateAttachmentDto,
  CreateUpdateKwzDto,
  GetAttachmentDto,
  GetKwzDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { KwzApi } from '../../../common/snrwbCore/contexts/KwzContext';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';

interface KwzRequestWithdrawalProps {
  api: KwzApi;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  kwz: GetKwzDto;
  kwzCU: CreateUpdateKwzDto;
  attachment: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  activeMainTab: () => void;
  propertyChange: (obj: Partial<CreateUpdateKwzDto>, save?: boolean) => void;
}

export const KwzRequestWithdrawal: React.FC<KwzRequestWithdrawalProps> =
  props => {
    return (
      <>
        {props.kwz.requestApproved || !props.editAuthorized ? (
          <KwzRequestWithdrawalNoEdit {...props} />
        ) : (
          <KwzRequestWithdrawalEdit {...props} />
        )}
      </>
    );
  };

const KwzRequestWithdrawalEdit: React.FC<KwzRequestWithdrawalProps> = props => {
  return (
    <>
      <Alert variant="danger" className="mt-3">
        <Alert.Heading>
          Wniosek o usunięcie wpisu KWZ nie jest zatwierdzony
        </Alert.Heading>
        <hr />
        <div className="d-flex justify-content-end">
          <ConfirmationButton
            roleAnyOf={[Role.KwzRevoke]}
            className="me-1"
            variant="outline-danger"
            onOK={() => {
              props.api.removeRequestForWithdrawal(props.kwz.id, () =>
                props.activeMainTab(),
              );
            }}
            confirmation="Czy na pewno usunąć wniosek o usunięcie wpisu KWZ?"
          >
            Usuń
          </ConfirmationButton>
          <ConfirmationButton
            roleAnyOf={[Role.KwzEdit]}
            variant="outline-primary"
            onOK={() => props.api.approveRequestForWithdrawal(props.kwz.id)}
            confirmation="Czy na pewno zatwierdzić wniosek o usunięcie wpisu KWZ?"
          >
            Zatwierdź
          </ConfirmationButton>
        </div>
      </Alert>
      <Form className="d-grid gap-3">
        <FormRow
          controlId="judgment_stop"
          label="Wniosek WINB do GINB o usunięcie wpisu KWZ"
        >
          <Col md={4}>
            <AssociatedDocuments
              name="Wniosek WINB"
              oneDocumentOnly={true}
              readonly={props.kwz.requestApproved || !props.editAuthorized}
              createModalType={AddAttachment}
              presentModalType={Attachment}
              docs={props.attachment}
            />
          </Col>
        </FormRow>
        <FormRow controlId="description" label="Data wniosku">
          <DatePicker
            value={props.kwzCU.requestDate}
            onChange={date => props.propertyChange({ requestDate: date })}
          />
        </FormRow>
        <FormRow
          controlId="withdrawalInfo "
          label="Informacja o wycofaniu wszystkich egzemplarzy zakwestionowanego wyrobu budowlanego z obrotu"
        >
          <DynamicTextarea
            value={props.kwzCU.withdrawalInfo || ''}
            onChange={e =>
              props.propertyChange({ withdrawalInfo: e.target.value }, false)
            }
            onBlur={e =>
              props.propertyChange({ withdrawalInfo: e.target.value })
            }
          />
        </FormRow>
        <FormRow
          controlId="removalIncompatibility"
          label="Informacja o usunięciu niezgodności zakwestionowanego wyrobu budowlanego z wymaganiami określonymi w ustawie"
        >
          <DynamicTextarea
            value={props.kwzCU.removalIncompatibility || ''}
            onChange={e =>
              props.propertyChange(
                { removalIncompatibility: e.target.value },
                false,
              )
            }
            onBlur={e =>
              props.propertyChange({ removalIncompatibility: e.target.value })
            }
          />
        </FormRow>
        <FormRow
          controlId="stopActivity"
          label="Informacja o zaprzestaniu prowadzenia działalności gospodarczej przez producenta, importera lub sprzedawcę."
        >
          <DynamicTextarea
            value={props.kwzCU.stopActivity || ''}
            onChange={e =>
              props.propertyChange({ stopActivity: e.target.value }, false)
            }
            onBlur={e => props.propertyChange({ stopActivity: e.target.value })}
          />
        </FormRow>
      </Form>
    </>
  );
};

const KwzRequestWithdrawalNoEdit: React.FC<KwzRequestWithdrawalProps> =
  props => {
    const variant = props.kwz.requestApproved ? 'success' : 'danger';
    const heading = props.kwz.requestApproved
      ? 'Wniosek o wycofanie karty wyrobu zakwestionowanego został zatwierdzony'
      : 'Wniosek o wycofanie karty wyrobu zakwestionowanego nie jest zatwierdzony';
    const requestPresentation = props.kwz
      ? [
          {
            name: 'Data wniosku',
            value: momencik(props.kwz.requestDate),
          },
          {
            name: 'Informacja o wycofaniu wszystkich egzemplarzy zakwestionowanego wyrobu budowlanego z obrotu',
            value: props.kwz.withdrawalInfo,
          },
          {
            name: 'Informacja o usunięciu niezgodności zakwestionowanego wyrobu budowlanego z wymaganiami określonymi w ustawie',
            value: props.kwz.removalIncompatibility,
          },
          {
            name: 'Informacja o zaprzestaniu prowadzenia działalności gospodarczej przez producenta, importera lub sprzedawcę.',
            value: props.kwz.stopActivity,
          },
        ]
      : [];

    return (
      <>
        <Alert variant={variant}>
          <Alert.Heading>{heading}</Alert.Heading>
          <hr />
          {(props.editAuthorized || props.publishAuthorized) &&
            props.kwz.requestApproved &&
            !props.kwz.removePublishDate && (
              <div className="d-flex justify-content-end">
                <ConfirmationButton
                  variant="outline-success"
                  roleAnyOf={[Role.KwzRevoke]}
                  onOK={() =>
                    props.api.revertRequestForWithdrawal(props.kwz.id)
                  }
                  confirmation="Czy na pewno cofnąć zatwierdzenie wniosku o wycofanie KWZ?"
                >
                  Cofnij
                </ConfirmationButton>
              </div>
            )}
        </Alert>
        <FormRow
          controlId="judgment_stop"
          label="Wniosek WINB do GINB o usunięcie wpisu KWZ"
        >
          <Col md={4} className="mb-3">
            <AssociatedDocuments
              name="Wniosek WINB"
              oneDocumentOnly={true}
              readonly={true}
              createModalType={AddAttachment}
              presentModalType={Attachment}
              docs={props.attachment}
            />
          </Col>
        </FormRow>
        <ItemPresentation item={requestPresentation} />
      </>
    );
  };
