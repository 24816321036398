import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import { ProductType } from '../../../common/snrwbCore/contexts/ProductTypeContext';
import { OrganizationSelector } from '../Organizations/OrganizationSelector';

import { ProductTypeSelector } from './ProductTypeSelector';

export default (props: {
  organization: Organization;
  showProducerValidation: boolean;
  productType: ProductType;
  showProductTypeValidation: boolean;
  setOrganization: (organization: Organization) => void;
  isProducerValid: (status: boolean) => void;
  clearOrganization: () => void;
  selectProductType: (productType: ProductType) => void;
  isProductTypeValid: (status: boolean) => void;
}) => {
  return (
    <Row>
      <Col className="col-lg-6 col-12 mb-1">
        <Card>
          <Card.Header>Producent</Card.Header>
          <Card.Body>
            <OrganizationSelector
              onSelect={props.setOrganization}
              onValidation={props.isProducerValid}
              value={props.organization}
              showValidationErrors={props.showProducerValidation}
              onClear={props.clearOrganization}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col className="col-lg-6 col-12 mb-1">
        <Card>
          <Card.Header>Typ wyrobu</Card.Header>
          <Card.Body aria-disabled="true">
            <ProductTypeSelector
              onSelect={props.selectProductType}
              onValidation={props.isProductTypeValid}
              producer={props.organization}
              productType={props.productType}
              showValidationErrors={props.showProductTypeValidation}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
