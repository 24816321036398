import React, { useEffect, useState, useRef } from 'react';
import * as rstm from 'react-simple-tree-menu';
import { Button, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetOrganizationalUnitDto as OrganizationalUnit } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetOrganizationalUnitDto';
import { Tree } from '../../../common/components/Tree/Tree';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import { OrganizationalUnitModal } from '../../components/OrganizationalUnit/OrganizationalUnitModal';

const mapTreeItem: (
  key?: string,
  id?: string,
  label?: string,
  shortname?: string,
  editable?: boolean,
  notified?: boolean,
) => rstm.TreeNodeInArray = (
  key = '',
  id = undefined,
  label = '',
  shortname = '',
  editable = false,
  notified = false,
) => ({
  key,
  id,
  label,
  shortname,
  editable,
  nodes: undefined,
  notified,
});

const mapOrgUnitChildren: (
  id: string | undefined,
  data: OrganizationalUnit[],
) => rstm.TreeNodeInArray[] = (id, data) => {
  const orgUnitChildren = data.filter(orgUnit => orgUnit.parentId === id);

  return orgUnitChildren.map(orgUnit => ({
    ...mapTreeItem(
      orgUnit.id,
      orgUnit.id,
      orgUnit.shortname?.length > 0 ? ` - ${orgUnit.name}` : orgUnit.name,
      orgUnit.shortname,
      true,
      orgUnit.notified,
    ),
    nodes: mapOrgUnitChildren(orgUnit.id, data),
  }));
};

export const OrganizationalUnitsTreeView: React.FC = () => {
  const snrwbApi = useSnrwbCore();

  const [organizationalUnits, setOrganizationalUnits] = useState<
    OrganizationalUnit[]
  >([]);
  const [treeData, setTreeData] = useState<rstm.TreeNodeInArray[]>([]);
  const [showNewNotified, setShowNewNotified] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    snrwbApi.organizationalUnits
      .getMany(OrganizationalUnitsGetOptions.all)
      .then(orgUnit => {
        setOrganizationalUnits(orgUnit);
      });
  }, [snrwbApi]);

  useEffect(() => {
    const orgUnitParents = organizationalUnits.filter(
      orgUnit => !orgUnit.parentId,
    );
    setTreeData(
      orgUnitParents.map(orgUnit => ({
        ...mapTreeItem(
          orgUnit.id,
          orgUnit.id,
          orgUnit.shortname?.length > 0 ? ` - ${orgUnit.name}` : orgUnit.name,
          orgUnit.shortname,
          true,
          orgUnit.notified,
        ),
        nodes: mapOrgUnitChildren(orgUnit.id, organizationalUnits),
      })),
    );
  }, [organizationalUnits]);

  const handleRefresh = () => {
    snrwbApi.organizationalUnits
      .getMany(OrganizationalUnitsGetOptions.all)
      .then(orgUnit => {
        setOrganizationalUnits(orgUnit);
      });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="d-flex mb-0">
          <span className="flex-fill">Organy</span>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Nowa jednostka notyfikowana</Tooltip>}
          >
            <Button
              ref={buttonRef}
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setShowNewNotified(true);
                buttonRef.current?.blur();
              }}
            >
              <Icon.PlusLg />
            </Button>
          </OverlayTrigger>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Tree
          treeData={treeData}
          variant="organizationalUnit"
          onUpdate={handleRefresh}
        />
      </Card.Body>
      <OrganizationalUnitModal
        variant="add"
        item={{
          hasNodes: false,
          onClick: () => {
            // not important
          },
          isOpen: false,
          level: 0,
          key: 'new',
          label: '',
          organizationalUnitTypeId: Date.now(),
        }}
        onAction={() => {
          setShowNewNotified(false);
          handleRefresh();
        }}
        onClose={() => setShowNewNotified(false)}
        show={showNewNotified}
      />
    </Card>
  );
};
