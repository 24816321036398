import React from 'react';
import Badge from 'react-bootstrap/Badge';

import { GetMisstatementDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';

export default (props: { misstatement: GetMisstatementDto }) => {
  let shortname = '';
  let n = props.misstatement.variance;
  while (n) {
    shortname = n.shortname + (shortname ? ' ' + shortname : '');
    n = n.parent;
  }

  return (
    <div>
      <Badge bg="warning" text="dark">
        {shortname}
      </Badge>{' '}
      {props.misstatement.variance.value}
    </div>
  );
};
