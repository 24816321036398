import React, { useEffect, useState, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import * as Icons from 'react-bootstrap-icons';

import { GetEssentialFeatureDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as EssentialFeaturesContext from '../../../common/snrwbCore/contexts/EssentialFeatureContext';

const OverlayCheckbox = (props: {
  tooltip: string;
  value: boolean | null | undefined;
}) => {
  let variant = 'secondary';
  let sign = '?';
  let value = 'Nie ustalono';

  if (props.value === true) {
    variant = 'success';
    sign = '✓';
    value = 'Tak';
  }
  if (props.value === false) {
    variant = 'danger';
    sign = 'X';
    value = 'Nie';
  }
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 200, hide: 0 }}
      overlay={
        <Tooltip>
          {props.tooltip}: {value}
        </Tooltip>
      }
    >
      <Badge bg={variant}>{sign}</Badge>
    </OverlayTrigger>
  );
};

export const EssentialFeatureRow = (props: {
  value: GetEssentialFeatureDto;
  readonly: boolean;
  outerUpdate: () => void;
}) => {
  const [cuDto, setCuDto] = useState(
    EssentialFeaturesContext.toCuDto(props.value),
  );
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setCuDto(EssentialFeaturesContext.toCuDto(props.value));
  }, [props.value]);

  return (
    <tr className="align-middle">
      <td>
        <OverlayCheckbox tooltip="Publikować wynik" value={cuDto.publish} />
      </td>
      <td>{cuDto.feature}</td>
      <td>{cuDto.functionalProperty}</td>
      <td>{cuDto.examMethod}</td>
      <td dangerouslySetInnerHTML={{ __html: cuDto.expectedResult }}></td>
      <td dangerouslySetInnerHTML={{ __html: cuDto.result }}></td>
      <td className="text-center align-middle">
        <OverlayCheckbox
          tooltip="Próbka spełnia charakterystykę"
          value={cuDto.fullfilled}
        />
      </td>
      <td dangerouslySetInnerHTML={{ __html: cuDto.controlResult }}></td>
      <td className="text-center align-middle">
        <OverlayCheckbox
          tooltip="Próbka kontrolna spełnia charakterystykę"
          value={cuDto.controlFullfilled}
        />
      </td>
      <td className="text-center align-middle">
        {!props.readonly && (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 200, hide: 0 }}
            overlay={<Tooltip>Edycja zasadniczej charakterystyki</Tooltip>}
            rootClose
          >
            <Button
              ref={buttonRef}
              onClick={() => {
                props.outerUpdate();
                buttonRef.current?.blur();
              }}
              variant="outline-success"
            >
              <Icons.PencilSquare />
            </Button>
          </OverlayTrigger>
        )}
      </td>
    </tr>
  );
};
