import React, { useState, useEffect } from 'react';
import { Alert, Card, Form, Tab, TabPane } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import FormSkeleton from '../../../app/components/FormSkeleton';
import {
  CreateUpdateAttachmentDto,
  CreateUpdateJudgmentDto,
  GetAttachmentDto,
  GetSampleExamDto,
  GetJudgmentDto,
  GetInspectedProductDto,
  GetEssentialFeatureDto,
  GetPakDto,
  GetInspectorDto,
  CreateUpdateInspectorDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { ProductTypeEdit } from '../ProductTypes/ProductTypeEdit';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import {
  SampleExamApi,
  SampleWithResult,
} from '../../../common/snrwbCore/contexts/SampleExamContext';
import { SampleCollectDetails } from '../SampleCollects/SampleCollectDetails';
import { DataChangeScope } from '../../../common/snrwbCore/sockets/socket.datatypes';
import { ConcurrencySignaler } from '../../../app/components/ConcurrencySignaler';
import { Inspectors } from '../Inspectors/Inspectors';
import FormRow from '../../../app/components/FormRow';

import { SampleExamHeader } from './SampleExamHeader';
import { SampleExamDetails } from './SampleExamDetails';
import { SampleExamRegulations } from './SampleExamRegulations';
import { SampleExamSigning } from './SampleExamSigning';
import { SampleExamFeatures } from './SampleExamFeatures';
import { SampleExamButtons } from './SampleExamButtons';
import { Samples } from './Samples';

interface SampleExamProps {
  editAuthorized: boolean;
  publishAuthorized: boolean;
  validOrganizationalUnit: boolean;
  sampleExam: GetSampleExamDto;
  approvedExams: boolean;
  samples: SampleWithResult[];
  openWithSampleId?: string;
  openWithBannerId?: string;
  inspectedProducts: GetInspectedProductDto[];
  inspectors: AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>;
  sampleAttachments: AssociateDocuments<
    GetAttachmentDto,
    CreateUpdateAttachmentDto
  >;
  judgments: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  features: GetEssentialFeatureDto[];
  paks: GetPakDto[];
  api: SampleExamApi;
}

export const SampleExam = (props: SampleExamProps) => {
  const [bannerId, setBannerId] = useState(props.openWithBannerId);
  const [key, setKey] = useState({
    scope: DataChangeScope.sampleExam,
    id: props.sampleExam.id,
  });
  const [description, setDescription] = useState(
    'Badanie z numerem akt ' + props.sampleExam.fileNumber,
  );

  const editAuthorized =
    props.editAuthorized && !props.sampleExam.completionDate;

  const NoAuthorizationForEdit = () => (
    <>
      {props.sampleExam.id && !props.editAuthorized && (
        <Alert variant="warning">
          Brak uprawnień do edycji
          {!props.validOrganizationalUnit && ' (niewłaściwy organ)'}
        </Alert>
      )}
    </>
  );

  useEffect(
    () => setBannerId(props.openWithBannerId),
    [props.openWithBannerId],
  );
  useEffect(() => {
    setKey({ scope: DataChangeScope.sampleExam, id: props.sampleExam.id });
    setDescription('Badanie z numerem akt ' + props.sampleExam.fileNumber);
  }, [props.sampleExam.id, props.sampleExam.fileNumber]);

  return (
    <Card>
      <Card.Body>
        <SampleExamHeader {...props} />
        <Card>
          {!props.sampleExam.id ? (
            <FormSkeleton />
          ) : (
            <Tabs
              defaultActiveKey={props.openWithSampleId ? 'samples' : 'details'}
              id="sampleExam"
            >
              <Tab eventKey="details" title="Szczegóły" className="m-3">
                <NoAuthorizationForEdit />
                <SampleExamDetails
                  value={props.sampleExam}
                  editAuthorized={editAuthorized}
                  onChange={props.api.edit}
                  onNewSupplier={props.api.addSupplier}
                />
                <Form className="d-grid mt-3">
                  <FormRow controlId="inspectors" label="Kontrolerzy">
                    <Inspectors
                      inspectors={props.inspectors}
                      readonly={props.sampleExam.approved || !editAuthorized}
                    />
                  </FormRow>
                </Form>
              </Tab>
              <Tab eventKey="sampleCollect" title="Pobranie" className="m-3">
                <NoAuthorizationForEdit />
                <SampleCollectDetails
                  editAuthorized={editAuthorized}
                  approvedExams={props.approvedExams}
                  value={props.sampleExam.sampleCollect}
                  organizationLabel={
                    props.sampleExam.organizationType?.shortname === 'B'
                      ? 'Inwestor'
                      : 'Podmiot'
                  }
                  onChange={props.api.sampleCollectEdit}
                  onNewOrganization={props.api.addOrganization}
                />
                <SampleExamRegulations
                  editAuthorized={props.editAuthorized}
                  value={props.sampleExam}
                  onChange={props.api.edit}
                />
              </Tab>
              <TabPane
                eventKey="productType"
                title="Typ wyrobu"
                className="m-3"
                mountOnEnter={false}
                unmountOnExit={false}
              >
                <ProductTypeEdit
                  readonly={props.sampleExam.approved || !editAuthorized}
                  productType={props.sampleExam.productType}
                  onChange={props.api.changeProductType}
                  onApprove={props.api.approveProductType}
                  onRevertApprove={props.api.revertApproveProductType}
                  mayBeApproved={() =>
                    props.api.mayProductTypeBeApproved(
                      props.sampleExam.productType.id,
                    )
                  }
                  refresh={props.api.refresh}
                />
              </TabPane>
              <Tab eventKey="samples" title="Próbki" className="m-3">
                {!props.publishAuthorized && <NoAuthorizationForEdit />}
                <Samples
                  loading={props.sampleExam.id === undefined}
                  examApproved={props.sampleExam.approved}
                  editAuthorizedWithoutCompl={props.editAuthorized}
                  editAuthorized={editAuthorized}
                  publishAuthorized={props.publishAuthorized}
                  samples={props.samples}
                  attachments={props.sampleAttachments}
                  judgments={props.judgments}
                  openWithSampleId={props.openWithSampleId}
                  openWithBannerId={bannerId}
                  paks={props.paks}
                  api={props.api}
                  onBannerClosed={() => setBannerId(undefined)}
                  completionDate={props.sampleExam.completionDate}
                />
              </Tab>
              <Tab eventKey="features" title="Charakterystyki" className="m-3">
                {!props.publishAuthorized && <NoAuthorizationForEdit />}
                <SampleExamFeatures
                  examApproved={props.sampleExam.approved}
                  editAuthorized={editAuthorized}
                  publishAuthorized={props.publishAuthorized}
                  features={props.features}
                  samples={props.samples}
                  addFeature={props.api.addFeature}
                  updateFeature={props.api.updateFeature}
                  deleteFeature={props.api.deleteFeature}
                />
              </Tab>
              <Tab eventKey="signing" title="Podpisanie" className="m-3">
                <NoAuthorizationForEdit />
                <SampleExamSigning
                  value={props.sampleExam}
                  editAuthorized={editAuthorized}
                  onChange={props.api.edit}
                  onSign={props.api.sign}
                  onRevertSign={props.api.revertSign}
                  mayRevertSigning={props.api.mayRevertSigning}
                  readyToSign={props.api.readyToSign}
                />
              </Tab>
            </Tabs>
          )}
        </Card>
      </Card.Body>
      <SampleExamButtons {...props} />
      <ConcurrencySignaler
        dataKey={key}
        objectDescription={description}
        editAllowed={props.editAuthorized}
        refreshAction={props.api.refresh}
      />
    </Card>
  );
};
