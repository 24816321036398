/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportPakAmountDetails
 */
export interface ReportPakAmountDetails {
    /**
     * 
     * @type {number}
     * @memberof ReportPakAmountDetails
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ReportPakAmountDetails
     */
    sumImposedCashPenalties: number;
    /**
     * 
     * @type {number}
     * @memberof ReportPakAmountDetails
     */
    countOfCancellations: number;
    /**
     * 
     * @type {number}
     * @memberof ReportPakAmountDetails
     */
    sumPaid: number;
}

export function ReportPakAmountDetailsFromJSON(json: any): ReportPakAmountDetails {
    return ReportPakAmountDetailsFromJSONTyped(json, false);
}

export function ReportPakAmountDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportPakAmountDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'sumImposedCashPenalties': json['sumImposedCashPenalties'],
        'countOfCancellations': json['countOfCancellations'],
        'sumPaid': json['sumPaid'],
    };
}

export function ReportPakAmountDetailsToJSON(value?: ReportPakAmountDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'sumImposedCashPenalties': value.sumImposedCashPenalties,
        'countOfCancellations': value.countOfCancellations,
        'sumPaid': value.sumPaid,
    };
}


