import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetEmployeeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

import { Selector, Option } from './Selector';

export interface EmployeeSelectorProps {
  readOnly?: boolean;
  value?: string;
  placeholder?: string;
  className?: string;
  unrestricted?: boolean;
  onChange?: (option: Option) => void;
}

export default (props: EmployeeSelectorProps) => {
  const snrwb = useSnrwbCore();
  const [employees, setEmployees] = useState<GetEmployeeDto[]>([]);
  const [employeeName, setEmployeeName] = useState<string>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setEmployeeName(props.value);
  }, [props.value]);

  useEffect(() => {
    let mounted = true;
    const employeesPromise = props.unrestricted
      ? snrwb.employees.getActive()
      : snrwb.employees.getForCurrentUser();
    employeesPromise.then(employees => {
      if (mounted) {
        setEmployees(employees);
        setReady(true);
      }
    });

    return () => {
      mounted = false;
    };
  }, [snrwb.employees, props.unrestricted]);

  const provider = async (text: string) => {
    const values = employees.filter(v => {
      const name = v.firstName + ' ' + v.lastName;
      return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });

    return values.map(v => {
      const userNames = v.firstName + ' ' + v.lastName;
      const userInfo = snrwb.employees.getLabelForDuplicates(v);
      return {
        label: userNames + userInfo,
        value: v.id,
      };
    });
  };

  return ready ? (
    <Selector
      uniqueKey={'employees-' + employees.length}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      placeholder={props.placeholder || 'Wskaż...'}
      provider={provider}
      value={employeeName}
      onChange={props.onChange}
    />
  ) : (
    <Skeleton />
  );
};
