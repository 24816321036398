/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateInspectionPlanGroupDto
 */
export interface CreateUpdateInspectionPlanGroupDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionPlanGroupDto
     */
    productGroupId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectionPlanGroupDto
     */
    numberOfInspections: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectionPlanGroupDto
     */
    numberOfInspectionsCpr: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionPlanGroupDto
     */
    inspectionPlanId: string;
}

export function CreateUpdateInspectionPlanGroupDtoFromJSON(json: any): CreateUpdateInspectionPlanGroupDto {
    return CreateUpdateInspectionPlanGroupDtoFromJSONTyped(json, false);
}

export function CreateUpdateInspectionPlanGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInspectionPlanGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productGroupId': json['productGroupId'],
        'numberOfInspections': json['numberOfInspections'],
        'numberOfInspectionsCpr': json['numberOfInspectionsCpr'],
        'inspectionPlanId': json['inspectionPlanId'],
    };
}

export function CreateUpdateInspectionPlanGroupDtoToJSON(value?: CreateUpdateInspectionPlanGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productGroupId': value.productGroupId,
        'numberOfInspections': value.numberOfInspections,
        'numberOfInspectionsCpr': value.numberOfInspectionsCpr,
        'inspectionPlanId': value.inspectionPlanId,
    };
}


