import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import { GetSampleExamExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import nkitwDesc from '../../../common/nkitwDesc';

import { SampleExamLink } from './SampleExamLink';
import { SampleExamType } from './SampleExamType';

export const sampleExamForGrid = (sampleExam: GetSampleExamExtendedDto) => {
  return {
    key: sampleExam.id,
    values: {
      type: <SampleExamType exam={sampleExam} />,
      orderNo: sampleExam.publications,
      fileNumber: sampleExam.fileNumber,
      sysNumber: sampleExam.sysNumber,
      'organizationalUnit.shortname': (
        <OrganizationalUnitBadge
          organizationalUnit={sampleExam.sampleCollect?.examOrganizationalUnit}
        />
      ),
      'organization.name': sampleExam.sampleCollect?.organization?.name,
      'productType.nkitw': `[${
        sampleExam.productType?.organization?.name
      }] ${nkitwDesc(sampleExam.productType)}`,
      collectDate: momencik(sampleExam?.collectDate),
      link: <SampleExamLink sampleExamId={sampleExam.id} />,
      approvedDate: momencik(sampleExam.approvedDate),
    },
    orderByValues: {
      organizationalUnit:
        sampleExam.sampleCollect?.examOrganizationalUnit.shortname,
      sysNumber: String(sampleExam.sysNumber).padStart(10, '0'),
      collectDate: momencik(sampleExam?.collectDate, ''),
    },
  };
};

export const SampleExamSearch = (props: {
  emptyMessage?: string;
  withLp?: boolean;
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onSelect: (sampleExamId: string) => void;
  withApprovedDate?: boolean;
}) => {
  const columns = [
    { header: '', property: 'type', noSorting: true },
    { header: 'Numer akt', property: 'fileNumber' },
    { header: 'Numer', property: 'sysNumber' },
    { header: 'Organ', property: 'organizationalUnit.shortname' },
    { header: 'Podmiot', property: 'organization.name' },
    { header: 'Typ wyrobu', property: 'productType.nkitw' },
    { header: 'Data pobrania', property: 'collectDate' },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  if (props.withApprovedDate) {
    columns.splice(7, 0, {
      header: 'Data przekazania',
      property: 'approvedDate',
    });
  }

  if (props.withLp) {
    columns.splice(1, 0, {
      header: 'Lp',
      property: 'orderNo',
    });
  }

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter
      initialOrderColumn={props.withLp ? 'orderNo' : 'sysNumber'}
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onSelect}
      emptyGridMessage={
        props.emptyMessage || 'Brak badań próbek w bazie danych.'
      }
    />
  );
};
