import React from 'react';
import Nav from 'react-bootstrap/Nav';
import * as Icon from 'react-bootstrap-icons';

import { MenuItem, useNavigation } from '../../../common/navigation';

interface SideNavSectionProps {
  heading: string;
  location: string;
  items?: MenuItem[];
  path: string;
  icon: Icon.Icon;
}

const SideNavSection: React.FC<SideNavSectionProps> = (
  props: SideNavSectionProps,
) => {
  const nav = useNavigation();
  const isActive = (path: string) => props.location.startsWith(path);
  const active = isActive(props.path);
  const firstLevelActive =
    active &&
    (!props.items ||
      props.items.filter(sub => isActive(sub.path)).length === 0);
  const secondLevelActive = active && !firstLevelActive;
  const Icon = props.icon;

  const firstLevelClassName =
    'table-of-contents-link' + (secondLevelActive ? ' active-mainitem' : '');

  return (
    <>
      <Nav.Link
        active={firstLevelActive}
        href={props.path + '#'}
        onClick={() => nav.menuItem(props.path)}
        className={firstLevelClassName}
      >
        <Icon className="toc-link-icon" />
        <span className="toc-link-heading">{props.heading}</span>
      </Nav.Link>

      {props.items && active && (
        <Nav activeKey={props.location} className="d-block leftmargin">
          {props.items.map(item => (
            <Nav.Item key={item.path}>
              <Nav.Link
                active={isActive(item.path)}
                className="table-of-contents-link table-of-contents-sublink"
                href={item.path + '#'}
                onClick={() => nav.menuItem(item.path)}
              >
                {item.heading}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      )}
    </>
  );
};

export default SideNavSection;
