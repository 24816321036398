/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExamResultDto
 */
export interface CreateUpdateExamResultDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamResultDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamResultDto
     */
    negative: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamResultDto
     */
    approved: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamResultDto
     */
    publicationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamResultDto
     */
    sampleId: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamResultDto
     */
    approvedDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamResultDto
     */
    verified: boolean;
}

export function CreateUpdateExamResultDtoFromJSON(json: any): CreateUpdateExamResultDto {
    return CreateUpdateExamResultDtoFromJSONTyped(json, false);
}

export function CreateUpdateExamResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExamResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'negative': json['negative'],
        'approved': json['approved'],
        'publicationDate': !exists(json, 'publicationDate') ? undefined : (json['publicationDate'] === null ? null : new Date(json['publicationDate'])),
        'sampleId': json['sampleId'],
        'approvedDate': !exists(json, 'approvedDate') ? undefined : (new Date(json['approvedDate'])),
        'verified': json['verified'],
    };
}

export function CreateUpdateExamResultDtoToJSON(value?: CreateUpdateExamResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'negative': value.negative,
        'approved': value.approved,
        'publicationDate': value.publicationDate === undefined ? undefined : (value.publicationDate === null ? null : value.publicationDate.toISOString()),
        'sampleId': value.sampleId,
        'approvedDate': value.approvedDate === undefined ? undefined : (value.approvedDate.toISOString()),
        'verified': value.verified,
    };
}


