import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useAuth } from '../../common/hooks/useAuth';
import { useCookies } from '../../common/hooks/useCookies';
import { currentMenuItems, useNavigation } from '../../common/navigation';
import OrganizationalUnitSelector from '../../snrwb/components/Selects/OrganizationalUnitSelector';
import logo from '../../assets/SNRWB_logo_ekran_kolor_negatyw.png';
import { UserMemos } from '../../snrwb/components/Memos/UserMemos';
import { useSnrwbCore } from '../../common/hooks/useSnrwbCore';

interface NavMainProps {
  activePage: string;
  changingUnitForbidden: boolean;
  setContrast: (className: string) => void;
}

const NavMain: React.FC<NavMainProps> = ({
  activePage,
  changingUnitForbidden,
  setContrast,
}) => {
  const auth = useAuth();
  const nav = useNavigation();
  const cookies = useCookies();
  const snrwb = useSnrwbCore();
  const [name, setName] = useState('');
  const currentLinks = currentMenuItems(activePage);

  const changeOrganizationalUnit = (unitId: string) => {
    snrwb.organizationalUnits.getById(unitId).then(organizationalUnit => {
      cookies.setOrganizationalUnit(organizationalUnit);
      snrwb.setOrganizationalUnitId(organizationalUnit.id);
      if (organizationalUnit.notified) {
        nav.certificates();
      }
    });
  };

  useEffect(() => {
    if (auth.currentUser) {
      setName(auth.currentUser.name);
    }
  }, [auth.currentUser]);

  return (
    <Navbar
      expand
      collapseOnSelect
      variant="dark"
      role="banner"
      as="header"
      className="mainbar"
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo} alt="SNRWB" height={60} />
        </Navbar.Brand>

        <Nav role="navigation" id="top" className="d-none d-md-flex">
          {currentLinks.map(({ path, heading, exact }) => (
            <Nav.Link
              className="mainbarlink"
              key={path}
              href={path + '#'}
              onClick={() => nav.menuItem(path)}
              active={exact ? activePage === path : activePage.startsWith(path)}
            >
              {heading}
            </Nav.Link>
          ))}
        </Nav>
        <Nav className="ms-auto col-md-6 col-lg-4 d-flex justify-content-end">
          <NavDropdown title={name} id="nav-dropdown" className="me-3 mt-1">
            <NavDropdown.Item
              eventKey="account"
              onClick={() => window.open(auth.accountUrl)}
            >
              Zarządzanie kontem
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              eventKey="contrastNormal"
              onClick={() => setContrast('')}
              className="d-flex"
            >
              <span className="flex-grow-1 me-1">Kontrast domyślny</span>
              <Icon.EyeFill className="align-self-center contrast-icon contrast-default" />
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="contrastWhiteBlack"
              onClick={() => setContrast('activeContrastWhiteBlack')}
              className="d-flex"
            >
              <span className="flex-grow-1 me-1">Kontrast biało-czarny</span>
              <Icon.EyeFill className="align-self-center contrast-icon contrast-white-black" />
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="contrastBlackYellow"
              onClick={() => setContrast('activeContrastBlackYellow')}
              className="d-flex"
            >
              <span className="flex-grow-1 me-1">Kontrast czarno-żółty</span>
              <Icon.EyeFill className="align-self-center contrast-icon contrast-black-yellow" />
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="contrastYellowBlack"
              onClick={() => setContrast('activeContrastYellowBlack')}
              className="d-flex"
            >
              <span className="flex-grow-1 me-1">Kontrast żółto-czarny</span>
              <Icon.EyeFill className="align-self-center contrast-icon contrast-yellow-black" />
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="logout" onClick={auth.logout}>
              Wyloguj
            </NavDropdown.Item>
          </NavDropdown>
          <div className="me-3 d-flex">
            <UserMemos />
          </div>
          <Col md={6}>
            <OrganizationalUnitSelector
              readOnly={changingUnitForbidden}
              value={cookies.organizationalUnit?.id}
              onChange={option => changeOrganizationalUnit(option.value)}
            />
          </Col>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavMain;
