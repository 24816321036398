/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNarrowedKwzDto
 */
export interface GetNarrowedKwzDto {
    /**
     * 
     * @type {string}
     * @memberof GetNarrowedKwzDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetNarrowedKwzDto
     */
    publicationNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof GetNarrowedKwzDto
     */
    publicationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetNarrowedKwzDto
     */
    productTypeName: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetNarrowedKwzDto
     */
    productTypeApproved: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNarrowedKwzDto
     */
    producerId: string;
    /**
     * 
     * @type {string}
     * @memberof GetNarrowedKwzDto
     */
    producerName: string;
    /**
     * 
     * @type {Date}
     * @memberof GetNarrowedKwzDto
     */
    judgmentPublishDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetNarrowedKwzDto
     */
    judgmentFinalDate?: Date;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetNarrowedKwzDto
     */
    decisionSign: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetNarrowedKwzDto
     */
    decisionIndex: GetDictionaryDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetNarrowedKwzDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetNarrowedKwzDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetNarrowedKwzDto
     */
    request: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetNarrowedKwzDto
     */
    requestApproved: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetNarrowedKwzDto
     */
    requestDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetNarrowedKwzDto
     */
    removePublishDate?: Date | null;
}

export function GetNarrowedKwzDtoFromJSON(json: any): GetNarrowedKwzDto {
    return GetNarrowedKwzDtoFromJSONTyped(json, false);
}

export function GetNarrowedKwzDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNarrowedKwzDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'publicationNumber': json['publicationNumber'],
        'publicationDate': !exists(json, 'publicationDate') ? undefined : (new Date(json['publicationDate'])),
        'productTypeName': json['productTypeName'],
        'productTypeApproved': json['productTypeApproved'],
        'producerId': json['producerId'],
        'producerName': json['producerName'],
        'judgmentPublishDate': !exists(json, 'judgmentPublishDate') ? undefined : (new Date(json['judgmentPublishDate'])),
        'judgmentFinalDate': !exists(json, 'judgmentFinalDate') ? undefined : (new Date(json['judgmentFinalDate'])),
        'decisionSign': GetDictionaryDtoFromJSON(json['decisionSign']),
        'decisionIndex': GetDictionaryDtoFromJSON(json['decisionIndex']),
        'approved': json['approved'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'request': json['request'],
        'requestApproved': json['requestApproved'],
        'requestDate': !exists(json, 'requestDate') ? undefined : (json['requestDate'] === null ? null : new Date(json['requestDate'])),
        'removePublishDate': !exists(json, 'removePublishDate') ? undefined : (json['removePublishDate'] === null ? null : new Date(json['removePublishDate'])),
    };
}

export function GetNarrowedKwzDtoToJSON(value?: GetNarrowedKwzDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'publicationNumber': value.publicationNumber,
        'publicationDate': value.publicationDate === undefined ? undefined : (value.publicationDate.toISOString()),
        'productTypeName': value.productTypeName,
        'productTypeApproved': value.productTypeApproved,
        'producerId': value.producerId,
        'producerName': value.producerName,
        'judgmentPublishDate': value.judgmentPublishDate === undefined ? undefined : (value.judgmentPublishDate.toISOString()),
        'judgmentFinalDate': value.judgmentFinalDate === undefined ? undefined : (value.judgmentFinalDate.toISOString()),
        'decisionSign': GetDictionaryDtoToJSON(value.decisionSign),
        'decisionIndex': GetDictionaryDtoToJSON(value.decisionIndex),
        'approved': value.approved,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'request': value.request,
        'requestApproved': value.requestApproved,
        'requestDate': value.requestDate === undefined ? undefined : (value.requestDate === null ? null : value.requestDate.toISOString()),
        'removePublishDate': value.removePublishDate === undefined ? undefined : (value.removePublishDate === null ? null : value.removePublishDate.toISOString()),
    };
}


