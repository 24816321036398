import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportCostSampleExamsType } from '../../../../common/types/ReportTypes';
import { ReportCostsSampleExams } from '../../../components/ReportCostsSampleExams/ReportCostsSampleExams';

export const ReportCostsSampleExamsView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportCostSampleExamsType>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportCostSampleExams(true, filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <Filter onSearch={onSearch} reportName="Budżet, koszty" />
      <ReportCostsSampleExams
        data={data}
        reportName="Koszty badania próbek zleconych"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
