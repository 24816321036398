import React from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as Icon from 'react-bootstrap-icons';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  CreateUpdateInspectionMetricDto,
  GetInspectionMetricDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { Selector } from '../Selects/Selector';
import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import DictionarySelector from '../Selects/DictionarySelector';
import OrganizationalUnitSelector from '../Selects/OrganizationalUnitSelector';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';

export const InspectionMetricForm = ({
  value,
  metric,
  presentedMetric,
  organizationName,
  inspectionType,
  setMetric,
  propertyChange,
  presentedToSelected,
  getAddress,
}: {
  value: GetInspectionMetricDto | undefined;
  metric: CreateUpdateInspectionMetricDto;
  presentedMetric: CreateUpdateInspectionMetricDto;
  organizationName: string | undefined;
  inspectionType: string | undefined;
  setMetric: (dto: CreateUpdateInspectionMetricDto) => void;
  propertyChange: (obj: Partial<CreateUpdateInspectionMetricDto>) => void;
  presentedToSelected: () => void;
  getAddress: () => string;
}) => {
  const snrwb = useSnrwbCore();

  const copyOrganizationAddress = () =>
    setMetric({
      ...metric,
      inspectionPlace: getAddress(),
    });

  return (
    <Form className="d-grid gap-3">
      {value && (
        <FormRow controlId="organization" label="Podmiot">
          <Selector
            value={organizationName}
            onChange={option => {
              setMetric({ ...metric, organizationId: option.value });
            }}
            provider={snrwb.organizations.getOptionsByName}
          />
        </FormRow>
      )}
      <FormRow controlId="description" label="Typ podmiotu">
        <DictionarySelector
          dictionary="metryka kontroli - podmiot"
          value={presentedMetric.organizationTypeId}
          onChange={option =>
            setMetric({ ...metric, organizationTypeId: option.value })
          }
        />
      </FormRow>
      {value && (
        <FormRow controlId="organizationalUnit" label="Organ">
          <OrganizationalUnitSelector
            option={OrganizationalUnitsGetOptions.notNotified}
            value={metric.organizationalUnitId}
            onChange={option => {
              setMetric({ ...metric, organizationalUnitId: option.value });
            }}
          />
        </FormRow>
      )}
      <FormRow controlId="description" label="Rodzaj">
        <DictionarySelector
          dictionary="metryka kontroli - rodzaj"
          initialShortname={inspectionType}
          value={presentedMetric.inspectionTypeId}
          onChange={option =>
            setMetric({ ...metric, inspectionTypeId: option.value })
          }
        />
      </FormRow>
      <FormRow controlId="description" label="Data rozpoczęcia">
        <DatePicker
          value={presentedMetric.startDate}
          onChange={date => setMetric({ ...metric, startDate: date })}
        />
      </FormRow>
      <FormRow controlId="inspectionPlace" label="Miejsce kontroli">
        <div className="position-relative">
          <DynamicTextarea
            value={presentedMetric.inspectionPlace || ''}
            onChange={e => propertyChange({ inspectionPlace: e.target.value })}
            onBlur={presentedToSelected}
          />
          <div className="position-absolute bottom-0 end-0 p-2">
            <OverlayTrigger
              overlay={<Tooltip>Wstaw adres kontrolowanego podmiotu</Tooltip>}
              placement="auto"
            >
              <Icon.Signpost
                tabIndex={0}
                onClick={copyOrganizationAddress}
                onKeyPress={copyOrganizationAddress}
              />
            </OverlayTrigger>
          </div>
        </div>
      </FormRow>
      <FormRow controlId="fileNumber" label="Numer akt">
        <Form.Control
          type="text"
          value={presentedMetric.fileNumber || ''}
          onChange={e => propertyChange({ fileNumber: e.target.value })}
          onBlur={presentedToSelected}
        />
      </FormRow>
    </Form>
  );
};
