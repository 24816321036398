import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { ReportInspectedOrganizations } from '../../../../common/types/ReportTypes';
import { ReportByOrganizationType } from '../../../components/ReportByOrganizationType/ReportByOrganizationType';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';

export const ReportInspectedOrganizationByProductsView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportInspectedOrganizations>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports
      .reportInspectedOrganizationsByProducts(false, filter)
      .then(dataReports => {
        setData(dataReports);
      });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Wyroby skontrolowane u podmiotów"
      />
      <ReportByOrganizationType
        data={data}
        reportName1="wyroby ogółem (suma poszczególnych)"
        reportName2="liczba wyrobów skontrolowanych u producentów"
        reportName3="liczba wyrobów skontrolowanych u importerów"
        reportName4="liczba wyrobów skontrolowanych u sprzedawców"
        reportName5="liczba wyrobów skontrolowanych u upoważnionych przedstawicieli producentów"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
        reportName="Wyroby skontrolowane u podmiotów"
      />
    </>
  );
};
