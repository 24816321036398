/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetEmployeeDto
 */
export interface GetEmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDto
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDto
     */
    cardNo: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDto
     */
    sub: string;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetEmployeeDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeDto
     */
    login: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeDto
     */
    hasUniqueNames: boolean;
}

export function GetEmployeeDtoFromJSON(json: any): GetEmployeeDto {
    return GetEmployeeDtoFromJSONTyped(json, false);
}

export function GetEmployeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmployeeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'position': json['position'],
        'cardNo': json['cardNo'],
        'sub': json['sub'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'login': json['login'],
        'active': json['active'],
        'hasUniqueNames': json['hasUniqueNames'],
    };
}

export function GetEmployeeDtoToJSON(value?: GetEmployeeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'position': value.position,
        'cardNo': value.cardNo,
        'sub': value.sub,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'login': value.login,
        'active': value.active,
        'hasUniqueNames': value.hasUniqueNames,
    };
}


