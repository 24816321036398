import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportInspectedOrganizations } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportInspectedOrganizations;
  reportName1: string;
  reportName2: string;
  reportName3: string;
  reportName4: string;
  reportName5: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
  reportName: string;
};

export const ReportByOrganizationType: React.FC<Props> = ({
  data,
  reportName1,
  reportName2,
  reportName3,
  reportName4,
  reportName5,
  filterApplied,
  usedFilter,
  reportName,
}) => {
  const inspectionTypes = ['P', 'BR', 'R'];
  const cols = [
    'producent',
    'importer',
    'sprzedawca',
    'upoważniony przedstawiciel producenta',
  ];

  const hasData = Object.keys(data.details).length > 0;

  const getCountType = (orU: string, type: string, it: string) => {
    return data.details[orU]?.[type]?.[it] || 0;
  };

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th rowSpan={3}>Organ</th>
              <th colSpan={5}>{reportName1}</th>
              <th colSpan={4}>{reportName2}</th>
              <th colSpan={4}>{reportName3}</th>
              <th colSpan={4}>{reportName4}</th>
              <th colSpan={4}>{reportName5}</th>
            </tr>
            <tr>
              <th rowSpan={2}>O</th>
              <th rowSpan={2}>P</th>
              <th colSpan={3}>D</th>
              {cols.map((c, idx2) => (
                <React.Fragment key={idx2}>
                  <th rowSpan={2}>O</th>
                  <th rowSpan={2}>P</th>
                  <th colSpan={2}>D</th>
                </React.Fragment>
              ))}
            </tr>
            <tr>
              <th>BR</th>
              <th>R</th>
              <th>ogółem doraźne</th>
              {cols.map((c, idx2) => (
                <React.Fragment key={idx2}>
                  <th>BR</th>
                  <th>R</th>
                </React.Fragment>
              ))}
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    <td key={'O1'} className="numbers-column">
                      {inspectionTypes.reduce(
                        (accIt, it) =>
                          accIt +
                          (cols.reduce(
                            (acc, oCol) =>
                              acc + (getCountType(key, oCol, it) || 0),
                            0,
                          ) || 0),
                        0,
                      )}
                    </td>
                    {inspectionTypes.map((it, idx2) => (
                      <td key={idx2} className="numbers-column">
                        {cols.reduce(
                          (acc, oCol) =>
                            acc + (getCountType(key, oCol, it) || 0),
                          0,
                        )}
                      </td>
                    ))}
                    <td key="reduce" className="numbers-column">
                      {cols.reduce(
                        (acc, oCol) =>
                          acc + (getCountType(key, oCol, 'BR') || 0),
                        0,
                      ) +
                        cols.reduce(
                          (acc, oCol) =>
                            acc + (getCountType(key, oCol, 'R') || 0),
                          0,
                        )}
                    </td>
                    {cols.map((c, idx2) =>
                      SumAll(idx2, inspectionTypes, getCountType, key, c),
                    )}
                  </tr>
                ))}
              <tr>
                <td key="Sum">Suma:</td>
                <td key={'sum0'} className="numbers-column">
                  {inspectionTypes.reduce(
                    (accO, it) =>
                      accO +
                      cols.reduce(
                        (acc, oCol) => acc + (data.sum?.[oCol]?.[it] || 0),
                        0,
                      ),
                    0,
                  )}
                </td>
                {inspectionTypes.map((it, idx3) => (
                  <td key={idx3} className="numbers-column">
                    {cols.reduce(
                      (acc, oCol) => acc + (data.sum?.[oCol]?.[it] || 0),
                      0,
                    )}
                  </td>
                ))}
                <td key="reduce2" className="numbers-column">
                  {cols.reduce(
                    (acc, oCol) =>
                      acc +
                      (data.sum?.[oCol]?.['BR'] || 0) +
                      (data.sum?.[oCol]?.['R'] || 0),
                    0,
                  )}
                </td>
                {cols.map((c, idx2) => OSum(idx2, inspectionTypes, data, c))}
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

function OSum(
  idx2: number,
  inspectionTypes: string[],
  data: ReportInspectedOrganizations,
  c: string,
): JSX.Element {
  return (
    <React.Fragment key={idx2}>
      <td className="numbers-column">
        {inspectionTypes.reduce(
          (acc, it) => acc + (data.sum?.[c]?.[it] || 0),
          0,
        )}
      </td>
      {inspectionTypes.map((it, idx3) => (
        <td key={idx2 + idx3} className="numbers-column">
          {' '}
          {data.sum?.[c]?.[it] || 0}
        </td>
      ))}
    </React.Fragment>
  );
}

function SumAll(
  idx2: number,
  inspectionTypes: string[],
  getCountType: (orU: string, type: string, it: string) => number,
  key: string,
  c: string,
): JSX.Element {
  return (
    <React.Fragment key={idx2}>
      <td className="numbers-column">
        {inspectionTypes.reduce((acc, it) => acc + getCountType(key, c, it), 0)}
      </td>
      {inspectionTypes.map((it, idx3) => (
        <td key={idx3} className="numbers-column">
          {' '}
          {getCountType(key, c, it)}
        </td>
      ))}
    </React.Fragment>
  );
}
