/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBasedOnProceedingParams
 */
export interface CreateBasedOnProceedingParams {
    /**
     * 
     * @type {string}
     * @memberof CreateBasedOnProceedingParams
     */
    proceedingId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBasedOnProceedingParams
     */
    organizationId: string;
}

export function CreateBasedOnProceedingParamsFromJSON(json: any): CreateBasedOnProceedingParams {
    return CreateBasedOnProceedingParamsFromJSONTyped(json, false);
}

export function CreateBasedOnProceedingParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBasedOnProceedingParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proceedingId': json['proceedingId'],
        'organizationId': json['organizationId'],
    };
}

export function CreateBasedOnProceedingParamsToJSON(value?: CreateBasedOnProceedingParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proceedingId': value.proceedingId,
        'organizationId': value.organizationId,
    };
}


