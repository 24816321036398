/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCustomsFeedbackDto
 */
export interface GetCustomsFeedbackDto {
    /**
     * 
     * @type {string}
     * @memberof GetCustomsFeedbackDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomsFeedbackDto
     */
    number: string;
    /**
     * 
     * @type {Date}
     * @memberof GetCustomsFeedbackDto
     */
    receiptDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetCustomsFeedbackDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomsFeedbackDto
     */
    usage: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomsFeedbackDto
     */
    constructionProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomsFeedbackDto
     */
    positive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetCustomsFeedbackDto
     */
    releaseDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetCustomsFeedbackDto
     */
    customsAuthority: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomsFeedbackDto
     */
    importer: string;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetCustomsFeedbackDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {GetOrganizationDto}
     * @memberof GetCustomsFeedbackDto
     */
    producer: GetOrganizationDto;
}

export function GetCustomsFeedbackDtoFromJSON(json: any): GetCustomsFeedbackDto {
    return GetCustomsFeedbackDtoFromJSONTyped(json, false);
}

export function GetCustomsFeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCustomsFeedbackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'receiptDate': !exists(json, 'receiptDate') ? undefined : (new Date(json['receiptDate'])),
        'name': json['name'],
        'usage': json['usage'],
        'constructionProduct': !exists(json, 'constructionProduct') ? undefined : json['constructionProduct'],
        'positive': !exists(json, 'positive') ? undefined : json['positive'],
        'releaseDate': !exists(json, 'releaseDate') ? undefined : (new Date(json['releaseDate'])),
        'customsAuthority': json['customsAuthority'],
        'importer': json['importer'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'producer': GetOrganizationDtoFromJSON(json['producer']),
    };
}

export function GetCustomsFeedbackDtoToJSON(value?: GetCustomsFeedbackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'receiptDate': value.receiptDate === undefined ? undefined : (value.receiptDate.toISOString()),
        'name': value.name,
        'usage': value.usage,
        'constructionProduct': value.constructionProduct,
        'positive': value.positive,
        'releaseDate': value.releaseDate === undefined ? undefined : (value.releaseDate.toISOString()),
        'customsAuthority': value.customsAuthority,
        'importer': value.importer,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'producer': GetOrganizationDtoToJSON(value.producer),
    };
}


