/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportCorrectiveActionsDetails,
    ReportCorrectiveActionsDetailsFromJSON,
    ReportCorrectiveActionsDetailsFromJSONTyped,
    ReportCorrectiveActionsDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportCorrectiveActionsType
 */
export interface ReportCorrectiveActionsType {
    /**
     * 
     * @type {object}
     * @memberof ReportCorrectiveActionsType
     */
    details: object;
    /**
     * 
     * @type {ReportCorrectiveActionsDetails}
     * @memberof ReportCorrectiveActionsType
     */
    sum: ReportCorrectiveActionsDetails;
}

export function ReportCorrectiveActionsTypeFromJSON(json: any): ReportCorrectiveActionsType {
    return ReportCorrectiveActionsTypeFromJSONTyped(json, false);
}

export function ReportCorrectiveActionsTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCorrectiveActionsType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': json['details'],
        'sum': ReportCorrectiveActionsDetailsFromJSON(json['sum']),
    };
}

export function ReportCorrectiveActionsTypeToJSON(value?: ReportCorrectiveActionsType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details,
        'sum': ReportCorrectiveActionsDetailsToJSON(value.sum),
    };
}


